import { Box, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useMatchBreakpoints from "../../hooks/useMatchBreakpoints";
import { useTranslation } from "react-i18next";
import ImgInfoModel from "./ImgInfoModel";
import { useBox, useOwenerList } from "./hooks/useBox";
import { useJigsawPuzzle } from "../../hooks/useContract";
import useWeb3 from "../../hooks/useWeb3";
import tranId from '../../utils/tran'

const Center = styled(Box)<any>`
  background-image: url(/image/新建项目.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
  border-radius: 10px;
`;



const StyleTotalText=styled(Box)`
     font-size:18px;
     color:#fff;
`
const StyeleTable=styled(Box)`
     @media (max-width: 750px) {
      max-height:6.4rem;
     }
     @media (min-width:750px){
      max-height:560px;
     }
     overflow-y:auto;
     word-break: break-all;
     ::-webkit-scrollbar {
    width: 2px;
    height: 10px;
    /* scrollbar-arrow-color:#f0f0f0; */
  }
  /*滚动条里面小方块*/
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    box-shadow: inset 0 0 2px #f0f0f0;
    background: rgba(0,0,0,0.2);
    /* scrollbar-arrow-color:#898989; */
  }
  /*滚动条里面轨道*/
  ::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2); */
    border-radius: 0;
    background: rgba(0,0,0,0.1);
  }
`

const StyleTh=styled(Box)`
  font-weight:bold;
  width:33%;
  color: #fff;
  text-align:left;
  background-color:#00265C ;
  @media (max-width: 750px) {
      padding:0.15rem 0px 0.15rem  0px;
  }
  @media (min-width:750px){
      padding:14px 0px 14px 0px;
  }
`
const StyleTr=styled(Box)`
  @media (max-width: 750px) {
      padding:0.15rem 0px 0.15rem  0px;
  }
  @media (min-width:750px){
    padding:10px 0px 10px 0px;
  }
  color: #fff;
  background-color:#011532;
  text-align:left;
  display:flex;
  align-items:center ;
  overflow: auto;
  word-break: normal;

  ::-webkit-scrollbar {
    width: 2px;
    height: 5px;
    /* scrollbar-arrow-color:#f0f0f0; */
  }
  /*滚动条里面小方块*/
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    box-shadow: inset 0 0 2px #f0f0f0;
    background: rgba(0,0,0,0.2);
    /* scrollbar-arrow-color:#898989; */
  }
  /*滚动条里面轨道*/
  ::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2); */
    border-radius: 0;
    background: rgba(0,0,0,0.1);
  }

`


const StyleTable = styled.table`
   border:none;
   border-spacing: inherit;
   width:100% ;
   color:#fff ;
   thead>th{
    padding:14px 0px 14px 0px;
    text-align:left;
    background-color:#00265C ;
   };
   tbody>tr>td{
    padding:10px 0px 10px 0px;
    text-align:left;
   };
   .pl{
    padding-left:40px ;
   };

   .color_1{
    background-color:#011532;
   }
   .color_2{
    background-color:#2C3645;
   }
`;

const StyleImg = styled.img`
   
    @media (max-width: 750px) {
      width: 0.41rem;
      height: 0.41rem;
    }
    @media (min-width:750px){
      width: 41px;
      height: 41px;
    }
    cursor: pointer;
`;

const ListTableBox=styled(Box)`
    @media (max-width: 750px) {
       font-size: 0.18rem;
    }
    @media (min-width:750px){
       /* padding:0px 8px 0px 8px;
       height: 32px; */
    }
`


const ListTable: React.FC = () => {
  const { isDesktop, isMobile } = useMatchBreakpoints();
  const { t } = useTranslation();
  const PuzzleContract=useJigsawPuzzle();
  const web3 = useWeb3();
  
  const [open,setOpen]=useState(false);
  // 获取boxlist
  const {ownerList}=useOwenerList();
  const [lotTimeHistory,setlotTimeHistory]=useState<any>([]);
  const [lotIdAndIdx,setLotIdAndIdx]=useState<any>([]);

  const showModal=async (t:any,address:string)=>{
    setLotIdAndIdx({address:address,lotId:t.lotId,spId:t.spId,dueTime:t.dueTime});
    setOpen(!open)
  }

 
  
  return (
    <ListTableBox  display="flex" flex={1}  flexDirection='column'>
    
           <Box display='flex' flexDirection='column' mt={3}>

                   <Box display='flex'>
                        <StyleTh flex={1} pl={!isDesktop?1:5}>{t("address")}</StyleTh>
                        <StyleTh flex={1}>{t("Number")}</StyleTh>
                        <StyleTh flex={1}>{t("Detail")}</StyleTh>
                    </Box>
                    <StyeleTable  display='flex' flexDirection="column">
                        {ownerList.map((m)=>(
                            <Box key={m.address} display='flex' flex={1}>
                                    <StyleTr flex={1} pl={!isDesktop?1:5} style={{'width':'0'}}>{m.address}</StyleTr>
                                    <StyleTr flex={1} pl={1}>{m?.lots?.length}</StyleTr>
                                    <StyleTr flex={1} style={{'width':'0'}}>
                                    {m?.lots?.map((t: any,idx: number)=>{
                                     
                                      
                                      return <>
                                       {
                                        t.dueTime>new Date().getTime()?
                                        <StyleImg key={idx} onClick={()=>{showModal(t,m.address)}}
                                        style={{"marginLeft":idx==0?'0px':isDesktop?"55px":"0.55rem"}}  
                                        src={`./image/unkomn.png`} alt=""/>
                                        :<StyleImg key={idx} onClick={()=>{showModal(t,m.address)}}
                                        style={{"marginLeft":idx==0?'0px':isDesktop?"55px":"0.55rem"}}  
                                        // https://blindbox.cash/jigsaw/<lotid>/piece001.png
                                        // t.lotId,spId:t.spId
                                        src={`https://blindbox.cash/jigsaw/${t.lotId}/piece${tranId(t.spId)}.jpg`} alt=""/>
                                       }
                                       {/* dueTime:{t.dueTime} */}
                                      
                                      </>
                                        })}
                                    </StyleTr>
                           </Box>
                        ))}
                    </StyeleTable>
                  
                {/* <StyleTable width={0}>
                   

                    <thead>
                        <th className="pl">ADDRESS</th>
                        <th>Number of the blocks</th>
                        <th>Detail</th>
                    </thead>
                    <tbody>
                        {ownerList.map((t:any)=>(
                          <tr className="color_1">
                             <td className="pl">{t.address}</td>
                             <td>{t?.lots?.length}</td>
                             <td>
                                 {t?.lots?.map((t: any,idx: number)=>{
                                     return <StyleImg onClick={()=>setOpen(!open)} style={{"marginLeft":idx==0?'0px':"55px"}}  src="./image/myFuzzle/suipian.png" alt=""/>
                                 })}
                             </td>
                         </tr>
                        ))}
                       
                    </tbody>
                </StyleTable> */}
           </Box>
               <ImgInfoModel 
                        lotIdAndIdx={lotIdAndIdx}
                        lotTimeHistory={lotTimeHistory} 
                        isopen={open} 
                        setOpens={setOpen}>
               </ImgInfoModel>
      
    
    </ListTableBox>
    
  );
};

export default ListTable;
