import { useWallet } from '@binance-chain/bsc-use-wallet';
import { BottomNavigation, BottomNavigationAction, Box, Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils'
import nftAbi from './../../config/abi/nft.json';
import addresses from './../../config/contract/nft';

const ImgCenter = styled.img`
    width:130px;
    height:130px;
`
const ImgMint = styled.img`
    padding:15px;
`
const LoginBtn = styled(Box)`
    /* background: #64c832; */
    background: #d30052;
    padding:0 18px;
    height: 38px;
    border-radius: 20px;
    font-weight: bold;
    cursor: pointer;
`

const LoginButton: React.FC =()=>{

    const wallet = useWallet()
    // const nftAddress = addresses.nftAddress
    // const web3 = new Web3(new Web3.providers.HttpProvider('https://moeing.tech:9545'));
    const [addr,setAddres] = useState('')
    // useEffect(()=>{
    //     web3.eth.net.isListening().
    //      then(async (res)=>{
    //       const myContract=new web3.eth.Contract((nftAbi as unknown) as AbiItem,nftAddress)
    //       console.log('myContract',myContract) 
    //       const nftName=await myContract.methods.name().call()
    //       console.log('nftName:',nftName) 
    //      })
    //     console.log('web3============',web3)
    //   },[])
    useEffect(()=>{
        if(wallet.status === 'connected'){
            wallet.reset()
         }else{
            wallet.connect('injected')
         }
        return ()=>{wallet.reset()}
    },[])
    const connWall=()=>{
            if(wallet.status === 'connected'){
               wallet.reset()
            }else{
               wallet.connect('injected')
            }
    }
    const addrTran= (str:string|null):string=>{
            return `${str?.slice(0,6)}****${str?.slice(str.length-4,str.length)}`
    }

  return <LoginBtn display='flex' alignItems='center' onClick={connWall}>
           {wallet.status === 'connected'?addrTran(wallet.account):'Connection wallet'}
         </LoginBtn>


}

export default LoginButton;