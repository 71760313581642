import { Box, Button } from "@material-ui/core";
import React, { useEffect, useLayoutEffect, useState } from "react";
import styled from "styled-components";
import useMatchBreakpoints from "../../hooks/useMatchBreakpoints";
import { useTranslation } from "react-i18next";


const Flex=styled(Box)`
     display: flex;
`

const BnnerPanel=styled(Flex)<{windowSize:number}>`
      *{
        margin: 0;
        padding:0;
      }
      @media (max-width: 750px) {
            ul{
            margin: 0;
            padding:0;
            display: flex;
            flex:1;
            height: 0.7rem;
          }

          li{
              list-style: none;
              width: 2.4rem;
              height: 0.7rem;
              float: left;
              display:flex ;
          }
          .content{
              overflow-x: auto;
              max-width: ${(props) => (`${props.windowSize}px`)};
              display: flex;
          }
          .app-content::-webkit-scrollbar {
            height: 5px;
            display: none;
          }
      }

      @media (min-width:750px){
          ul{
          margin: 0;
          padding:0;
          display: flex;
          flex:1;
          height: 70px;
        }

        li{
            list-style: none;
            width: 240px;
            height: 70px;
            float: left;
        }
        .content{
            overflow-x: auto;
            max-width: ${(props) => (`${props.windowSize}px`)};
            /* flex:1; */
            display: flex;
        }
        .app-content::-webkit-scrollbar {
        height: 5px;
        display: none;
        }
      }


    


`

const ShowImg = styled.img<{isMobile:boolean}>`
  /* height:${(props) => (props.isMobile?'3.08rem':'320px')} ; */
`;



const ConentFlex = styled(Flex)`

    ::before{
      background: linear-gradient(to right,rgb(6 30 62) 0%,rgba(35,44,49,0) 100%);
      content: "";
      height: 72px;
      position: absolute;
      width: 200px;
      z-index: 2;
      margin-left: -1px;
    }
   ::after{
      background: linear-gradient(to left,rgb(6 30 62) 0%,rgba(35,44,49,0) 100%);
      content: "";
      height: 72px;
      position: absolute;
      width: 200px;
      right:0px;
      z-index: 2;
   }

`;
const Banpanl = styled(Flex)`
 
    /* height: 72px; */
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 100%;

`;

const Banner: React.FC = () => {
  const { isDesktop, isMobile } = useMatchBreakpoints();
  const { t } = useTranslation();
  const imgId=[0,1,2,3,4,5,6,7,8];
  const imgIdTwo=[9,10,11,12,13,14,15,16,17];
  //获取当前窗口大小
  const [windowSize, setWindowSize] = useState(0);

  const [initState,setInitState]=useState(false);
  const [initState2,setInitState2]=useState(false);
  // const handleResize = () => {
  //   setWindowSize(getWindowSize());
  // };


  useEffect(() => {
    if(windowSize==0 || initState){return}
    let timer;
    let dome:any = document.getElementById("scroll")
    let dome1:any  = document.getElementById("scroll-1")
    let dome2:any = document.getElementById("scroll-2")
    dome2.innerHTML = dome1.innerHTML//复制dome1 为dome2
    let AniTimerScroll = () => {
        timer = setTimeout(function () {
            if (dome.scrollLeft == dome1.clientWidth) {
                dome.scrollLeft = 0;
            } else {
                dome.scrollLeft++;
            }
            AniTimerScroll()
        }, 20);
    }
    AniTimerScroll();
    setInitState(true)
    
  }, [windowSize])

  useEffect(() => {
    if(windowSize==0 || initState2){return}
    
    let timers;
    let dome:any = document.getElementById("scroll_2")
    let dome1:any  = document.getElementById("scroll-1_2")
    let dome2:any = document.getElementById("scroll-2_2")
    dome2.innerHTML = dome1.innerHTML//复制dome1 为dome2
    let AniTimerScrolls = () => {
       timers = setTimeout(function () {
 
            if (dome.scrollLeft == 0) {
                dome.scrollLeft = dome1.clientWidth;
            } else {
                dome.scrollLeft--;
            }
            AniTimerScrolls()
        }, 20);
    }
    AniTimerScrolls();
    setInitState2(true)
  }, [windowSize])




 
  const changeDataList=()=>{
    setWindowSize(0);
    setTimeout(()=>{
      let dome:any = document.getElementById("panel")
      console.log("dome",dome.offsetWidth )
      setWindowSize(dome.offsetWidth)
    },1000)
  }
  useLayoutEffect(() => {
    changeDataList();
    // 监听
    // window.addEventListener("resize", changeDataList);
    // // 销毁
    // return () => window.removeEventListener("resize", ()=>{});
  },[]);



  return (
    <BnnerPanel windowSize={windowSize}  flex={1} flexDirection="column" id="panel">

       
       {windowSize!=0?

        <>
            <Banpanl >
              <ConentFlex className='app-content content'  id="scroll">
                  <div id="scroll-1">
                      <ul >
                        {imgId.map((t,idx)=>(
                        <li key={idx+"s"} style={{"marginRight":"8px"}}>
                          <ShowImg src={`./image/banner/0 (${t+1}).png`} isMobile={!isDesktop} width="100%" alt=""  />
                        </li>

                        ))}

                      </ul>
                  </div>
                  <div id="scroll-2"></div>
              </ConentFlex>
            </Banpanl>
            
            <Banpanl mt={isDesktop?2:"0.16rem"}>
              <ConentFlex className='app-content content'  id="scroll_2">
                  <div id="scroll-1_2">
                      <ul >
                        {imgIdTwo.map((t,idx)=>(
                        <li key={idx} style={{"marginRight":"8px"}}>
                          <ShowImg src={`./image/banner/0 (${t+1}).png`} isMobile={!isDesktop} width="100%" alt=""  />
                        </li>

                        ))}


                      </ul>
                  </div>
                  <div id="scroll-2_2"></div>
              </ConentFlex>
            </Banpanl>
    
        
        </>:""
       }
  
    </BnnerPanel>
      
  
    
  );
};

export default Banner;
