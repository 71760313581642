import { Box, Button, unsupportedProp } from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import useMatchBreakpoints from "../../hooks/useMatchBreakpoints";
import { useTranslation } from "react-i18next";
import { useBoxList, useHandFuc, useIceApprove, useOwnerExists, useWithdraw } from "./hooks/useIce";
import { useWallet } from "@binance-chain/bsc-use-wallet";
import BigNumber from "bignumber.js";
import TipModael from "../../component/TipModael";
import { getBeeTokenAddress, getIceOrWaterAddress } from "../../utils/addressHelpers";
import useTokenApprove from "../../hooks/useApprove";
import { useBeeContract } from "../../hooks/useContract";
import { useIceHandlers, useIceState } from "../../state/Ice/hooks";
import ConnectWallt from "../home/ConnectWall";
import { tranPrice, tranTotalPrice } from "../../utils/tran";
import Select from "../../component/SelectInput";
import { OptionProps } from "../../component/SelectInput/SelectInput";
import { useParams } from "react-router-dom";


const StylePanel=styled(Box)<{state: boolean }>`
     align-items: center;
     @media (max-width: 750px) {
       font-size: 0.16rem;
     }
     opacity: ${(props) => (props.state?1:0.2)};
     pointer-events: ${(props) => (props.state ? "initial" : "none")};
`


const Flex=styled(Box)`
     display: flex;
`
const IceTop=styled(Flex)`
      background-image: url('/image/Ice/back1.png');
      background-size: 100% 100%;
      @media (max-width: 750px) {
        /* height: 2.5rem; */
      }
      @media (min-width:750px){
        /* height: 560px; */
      }
`
const IceCenter=styled(Flex)`
        /* width: 1600px; */
      /* height: 406px; */
      background: linear-gradient(143deg,rgba(53,248,254,0.48) 5%, rgba(63,164,255,0.34) 99%);
      border-radius: 8px;
      @media (max-width: 750px) {
        margin-top: 0.22rem;
        padding-bottom:0.16rem;
        border: 1px dashed #ffffff;
      }
      @media (min-width:750px){
        margin-top: 30px;
        padding-bottom:20px;
        border: 2px dashed #ffffff;
      }
`


const StyleIcon=styled.img`
      @media (max-width: 750px) {
        width: 0.25rem;
        height: 0.25rem;
      }
      @media (min-width:750px){
        width: 40px;
        height: 40px;
      }
`
const BoxImg=styled.img`
      @media (max-width: 750px) {
        width: 2.85rem;
        height: 2.5rem;
        margin-top: 0.48rem;
      }
      @media (min-width:750px){
        width: 417px;
        height: 350px;
        margin-top:48px;
      }
`

const DesPanel=styled(Flex)`
      @media (max-width: 750px) {
        padding:0.18rem 0px 0px 0.18rem;
        font-size: 0.25rem;
      }
      @media (min-width:750px){
        padding:40px 0px 0px 40px;
        font-size: 20px;
      }
`
const DesVale=styled(Flex)`
      color: #74DD54;
      @media (max-width: 750px) {
        font-size: 0.3rem;
        font-weight: bold;
      }
      @media (min-width:750px){
        font-size: 40px;
      }
`
const StyleTextField=styled.input`
        border: 1px solid #b1b1b1;
        /* border-radius: 10px; */
        margin-top: 20px;
        :focus-visible{
            border: 0;
            outline:0;
        }
        width: 100%;
        @media (max-width: 750px) {
          padding: 0.12rem;
        }
        @media (min-width:750px){
          padding: 18px;
        }
`





const STyleTitle=styled(Flex)`
      @media (max-width: 750px) {
         padding: 0px 0.12rem 0.05rem 0.12rem;
         font-size: 0.24rem;
         /* height: 0.24rem; */
         margin-top:-0.15rem;
         border-radius: 3px;
         border: 1px dashed #ffffff;
      }
      @media (min-width:750px){
         padding: 0px 24px 0px 24px;
         height: 48px;
         font-size: 32px;
         margin-top:-15px;
         border: 2px dashed #ffffff;
         border-radius: 8px;
      }
      white-space: nowrap;
      color:#fff;
      background: #2688a5;
      /* border: 2px dashed #ffffff; */
      /* border-radius: 8px; */
      justify-content: 'center';
      align-items: center;
      
`

const StyleBtnEnaring=styled(Flex)`
      @media (max-width: 750px) {
        padding:0.05rem 0.2rem 0.05rem 0.2rem;
        font-size: 0.18rem;
      }
      @media (min-width:750px){
        padding:5px 20px 5px 20px ;
        font-size: 20px;
      }
      color:#fff;
      border-radius: 4px;
      background: linear-gradient(358deg,#FF720A 5%,#FFD040 99%);  
      cursor: pointer;
      :hover{
        background: linear-gradient(358deg,#FFD040 5%,#FF720A 99%);
      }
`
const StyleBtn=styled(Flex)`
      @media (max-width: 750px) {
        padding:0.1rem 0.46rem 0.1rem 0.46rem;
        font-size: 0.3rem;
      }
      @media (min-width:750px){
        padding:10px 65px 10px 65px ;
        font-size: 20px;
      }
      color:#fff;
      border-radius: 4px;
      background: linear-gradient(358deg,#FF720A 5%,#FFD040 99%);  
      cursor: pointer;
      :hover{
        background: linear-gradient(358deg,#FFD040 5%,#FF720A 99%);
      }
`

const TimeBox=styled(Box)<{isMobile:boolean}>`
        @media (max-width: 750px) {
            padding:0.05rem 0.06rem 0.05rem 0.06rem;
            font-size: 0.3rem;
        }
        @media (min-width:750px){
           padding:6px 12px 6px 12px;
        }
    background-color:black;
    /* padding: ${(props) => (props.isMobile?'0.05rem 0.06rem 0.05rem 0.06rem':'6px 12px 6px 12px')} ; */
    font-size:${(props) => (props.isMobile?'0.18rem':'32px')};
    color: #6FBA0F;
    border-radius: 5px;
    /* border:1px dashed #fff; */
`


const SelectConent=styled(Flex)`
   @media (max-width: 750px) {
       .FlexStyle{
        font-size: 0.24rem;
        font-weight: bold;
       }
       .selectStyle{
          border-radius: 4px;
          border:0.04rem solid #00D4FF;
         
        }
        .switchStyle{
          border-radius: 4px;
          border:0.04rem solid rgb(255 255 255 / 0%);
        }
      }
      @media (min-width:750px){
        .selectStyle{
          border-radius: 4px;
          border:5px solid #00D4FF;
        }
        .switchStyle{
          border-radius: 4px;
          border:5px solid rgb(255 255 255 / 0%);
        }
      }

  
`


const SelectBox=styled(Flex)`
      @media (max-width: 750px) {
        img{
          width:1.54rem;
          height:1.16rem;
        }
        padding:0.04rem 0.05rem 0.04rem 0.05rem;
      }
      @media (min-width:750px){
        img{
          width:140px;
          height:100px;
        }
        padding:10px 10px 10px 10px;
      }
      cursor: pointer;
`
const StyleResSpan=styled(Flex)`
      @media (max-width: 750px) {
        font-size: 0.2rem;
        color: #05ff05;
      }
      @media (min-width:750px){
        font-size: 18px;
      }
     
`



const IceWater: React.FC = () => {
  const {account} = useWallet();
  const {id} = useParams<any>();
  const { isDesktop } = useMatchBreakpoints();
  const { t} = useTranslation();
  const [timeData,setTimeData]=useState("");
  // const [dateSplit,setDateSplit]=useState<any>({hour:[],min:[],second:[]}); 
  const dateSplit=useRef<any>({hour:[],min:[],second:[]}); 
  const inter=useRef<any>(-1);
  const [interState,setInterState]=useState(false);
  const {onIsRefresh}=useIceHandlers();
  // 授权
  const tokenContract=useBeeContract();
  const {isApprove}=useIceApprove();
  const onApprove=useTokenApprove(tokenContract,getIceOrWaterAddress());
  //数据获取
  const {IceBoxlist}=useBoxList();
  // box选择框默认选择值
  const selectBoxIndex=useMemo(()=>{
    if(id!=undefined && IceBoxlist.length>0){
      return IceBoxlist.findIndex((t:any)=>t.id==id);
    }
    return 0;
  },[id,IceBoxlist])
  const [selectBox,setSelectBox]=useState<any>(null);
  useEffect(()=>{
    if(IceBoxlist.length>0){
      setSelectBox(id!=undefined?parseInt(id):IceBoxlist[0].id)
    }
  },[IceBoxlist])

  const [selectWater,setSelect]=useState(true);
  const {ownerState,boxId,boxInfo,totalAddr}=useOwnerExists(selectBox);
  const {enaring}=useWithdraw(totalAddr,boxId,ownerState?.waterOwner?.idx,ownerState?.iceOwner?.idx);
  //提交操作
  const {handlePour,handleRecaption}=useHandFuc();
  const [amount,setAmount]=useState("");
  //hand state
  const [shopOpen,setShopOpen]=useState(false);
  const [shopErro,setShopErro]=useState<any>("");
  const [pedding,setPedding]=useState<any>(false);
  const [successDes,setSuccessDes]=useState<any>("");

  //定时器
  const format=useCallback((second:string)=>{
    let second_Time=(parseInt(second)*1000) - (new Date().getTime());
    if(second_Time<0){
      second_Time=0;
    }
    let seconds:number=Number((second_Time/1000).toFixed(0));
    let hour:any= Math.floor(seconds / 3600) >= 10 ? Math.floor(seconds / 3600) : '0' + Math.floor(seconds / 3600);
    seconds -= 3600 * hour;
    let min:any = Math.floor(seconds / 60) >= 10 ? Math.floor(seconds / 60) : '0' + Math.floor(seconds / 60);
    seconds -= 60 * min;
    let sec = seconds >= 10 ? seconds : '0' + seconds;
    return hour + ':' + min + ':' + sec;
  },[])

  useEffect(()=>{
        if(boxInfo?.dueTime){
          if(inter.current!=-1){clearInterval(inter.current)};
          setInterState(true)
          // console.log("截止日期变化")
          inter.current=setInterval(()=>{
              // console.log("ssssss")
              const dueTime=boxInfo?.dueTime?boxInfo?.dueTime:'0';
              const formatTime=format(dueTime)
              setTimeData(formatTime)
              const times=formatTime.split(":");
              // setDateSplit({
              // hour:times[0].split(""),
              // min:times[1].split(""),
              // second:times[2].split("")
              // })
              dateSplit.current={
                hour:times[0].split(""),
                min:times[1].split(""),
                second:times[2].split("")
              }
              if(times[0]=="00"&&times[1]=="00"&&times[2]=="00"){
                console.log("clearInterval")
                clearInterval(inter.current)
              }
          })
        }
        return ()=>{clearInterval(inter.current)}
  },[format,boxInfo?.dueTime])
  useEffect(()=>{ 
     return ()=>{clearInterval(inter.current)}
  },[])


  const roladList=()=>{
    onIsRefresh(true)
    setTimeout(()=>{onIsRefresh(false)},1000)
  }
  const handBeton=useCallback(async ()=>{
    setSuccessDes("");
    let Ifadditional:string="0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF";
    if(selectWater){
      if(ownerState?.waterOwner?.idx!=undefined){Ifadditional=`${ownerState.waterOwner.idx}`}
    }else{
      if(ownerState?.iceOwner?.idx!=undefined){Ifadditional=`${ownerState.iceOwner.idx}`}
    }
    const resAmount=(new BigNumber(amount).times(new BigNumber(10).pow(18))).toFixed()
      
      setPedding(true);
      setShopErro("")
      const respon=await handlePour(boxId,Ifadditional,selectWater,resAmount);
      if(respon.state){
        console.log("success:",respon.res)
        setShopOpen(true);
        roladList();
      }else{
        console.log("erro:",respon.res)
        setShopErro(respon.res.message?respon.res.message:respon.res.toString())
        setShopOpen(true);
      }
      setPedding(false);
  },[ownerState,amount,boxId,selectWater])



  const handRecaptionFuc=useCallback(async (isWater:boolean)=>{

      setSuccessDes("Extraction of success");
      let ownerIdx:number=-1;
      if(isWater){
        ownerIdx=ownerState.waterOwner.idx;
      }else{
        ownerIdx=ownerState.iceOwner.idx;
      }
      setShopErro("");
     
      const respon=await handleRecaption(boxId,ownerIdx,isWater);
      if(respon.state){
        console.log("success:",respon.res)
        setShopOpen(true);
        roladList();
      }else{
        console.log("erro:",respon.res)
        setShopErro(respon.res.message?respon.res.message:respon.res.toString())
        setShopOpen(true);
      }
  },[ownerState,amount,boxId])
  
  // 授权
  const handleApprove = useCallback(async () => {
      try {
          const txHash = await onApprove();
          roladList();
      } catch (e) {
          console.error(e)
      }
  }, [onApprove])



 const handleOptionChangeStrategies = (option: OptionProps): void => {
    // console.log("to do",option.value)
    const s=option.value;
    setSelectBox(s)
 }

  const getBtn=()=>{
    if(parseInt(boxInfo?.dueTime)*1000<new Date().getTime()){
       return  <StyleBtn style={{background:"rgb(197 188 188 / 67%)"}} >{t("Has as")}</StyleBtn>
    }
    if(pedding){
       return  <StyleBtn style={{background:"rgb(197 188 188 / 67%)"}} >{t("during purchase")}</StyleBtn>
    }
    if(isApprove){
      return  <StyleBtn onClick={handBeton}>{t("Confirm Betting")}</StyleBtn>
    }else{
      return  <StyleBtn onClick={handleApprove}>{t("Approve")}</StyleBtn>
    }
  }


  const getResWater=()=>{
    return <>
                 <StyleResSpan mt={"0.2rem"} component='span'>{tranPrice(ownerState?.waterOwner?.amount)}</StyleResSpan>
                 <Flex>{t("Have bet")}</Flex>
                 <StyleResSpan component='span'>{tranPrice(boxInfo?.waterAmount)}</StyleResSpan>
                 <Flex>{t("Total bet")}</Flex>
                 {
                   new BigNumber(enaring?.water).gt(0)?
                  <>
                    <StyleResSpan component='span'>{tranPrice(enaring?.water)}</StyleResSpan>
                    <Flex>{t("Results earnings")}</Flex>
                    <StyleBtnEnaring mt={1} onClick={()=>{handRecaptionFuc(true)}}>{t("recaption")}</StyleBtnEnaring>  
                  </>
                  :""
                 }
     </>
  }
  const getResIce=()=>{
    return <>
                 <StyleResSpan mt={"0.2rem"} component='span'>{tranPrice(ownerState?.iceOwner?.amount)}</StyleResSpan>
                 <Flex>{t("Have bet")}</Flex>
                 <StyleResSpan component='span'>{tranPrice(boxInfo?.iceAmount)}</StyleResSpan>
                 <Flex>{t("Total bet")}</Flex>
                 {
                   new BigNumber(enaring?.ice).gt(0)?
                  <>
                    <StyleResSpan component='span'>{tranPrice(enaring?.ice)}</StyleResSpan>
                    <Flex>{t("Results earnings")}</Flex>
                    <StyleBtnEnaring mt={1} onClick={()=>{handRecaptionFuc(false)}}>{t("recaption")}</StyleBtnEnaring>  
                  </>
                  :""
                 }
     </>
  }

  return (<>
  
  {!account?<Box display="flex"  flex={1}>
          <Flex flex={1} alignItems="center" justifyContent="center">
            <Flex >
                <ConnectWallt></ConnectWallt>
            </Flex>
          </Flex>
         </Box>
      :
      <StylePanel state={IceBoxlist.length>0&&totalAddr!=-1?true:false}  display="flex" flex={1}  alignItems="center">
    
      <Flex display="flex" flexDirection="column" flex={1} >
          <IceTop flex={1} flexDirection="column">

               <Flex  justifyContent="space-between">
                   <DesPanel  
                       flexDirection="column"
                       className="color_whilte">
                          {/* {ownerState.waterOwner.idx}------pppppppppppp */}
                       <Flex  alignItems="center">
                                   <StyleIcon src="./image/Ice/金币.png" alt=""/>
                                   <Flex ml={1} component="span">{t("Total Bets")} :</Flex>
                                   <DesVale ml={1} component="span">{tranTotalPrice(boxInfo?.waterAmount,boxInfo?.iceAmount)}</DesVale>
 
                       </Flex>
                       <Flex alignItems="center">
                               <StyleIcon src="./image/Ice/团队.png" alt=""/>
                               <Flex ml={1} component="span">{t("Participants")} :</Flex>
                               <DesVale ml={1} component="span">{totalAddr}</DesVale>
                       </Flex>
                   </DesPanel>
                   <Flex >
                        <Flex  flexDirection="column">
                           <Box className="color_whilte" 
                            mt={isDesktop?"24px":"0.12rem"}
                            fontSize={!isDesktop?"0.3rem":""} 
                            component='span'>{t("Close the shop")}</Box>
                                       {timeData?
                                       
                                       <Flex alignItems="center" color={"#fff"} mt={isDesktop?"11px":"0.06rem"}>
                                           { dateSplit?.current?.hour?.map((t:any,idx:number)=>(
                                           <TimeBox key={"hour"+idx} mr={"0.1rem"} isMobile={!isDesktop}>{t}</TimeBox>
                                           ))}：
                                           {dateSplit?.current?.min?.map((t:any,idx:number)=>(
                                           <TimeBox key={"min"+idx} mr={"0.1rem"} isMobile={!isDesktop}>{t}</TimeBox>
                                           ))}：
                                           {dateSplit?.current?.second?.map((t:any,idx:number)=>(
                                           <TimeBox key={"second"+idx} mr={"0.1rem"} isMobile={!isDesktop}>{t}</TimeBox>
                                           ))}
                                       </Flex>:""
                                       }
                               
                        </Flex>
                   </Flex>
               </Flex>
               <Flex flex={1} alignItems="end" justifyContent="center">
                   <BoxImg src="./image/Ice/box.png" alt=""/>
               </Flex>
          </IceTop>

          <IceCenter alignItems="center" flexDirection="column">
           <Flex >
               <STyleTitle>{t("Betting options")}</STyleTitle>
               {/* <StyleTextField placeholder={"Enter bet amount..."}/> */}
           </Flex>

          
           <Flex width={isDesktop?"50%":"60%"} flexDirection="column">
                <Flex mt={'20px'} width={"100%"} flex={1}>

                    {selectBox!=undefined && selectBox!=null?
                      <Select   selectIndex={selectBoxIndex}
                                options={(IceBoxlist||[]).map((item)=>{
                                  return {
                                  img:{url:item.type=='owner'?"./image/Ice/ownerBox.png":"./image/Ice/elseBox.png",name:""},
                                  label:`${item['zhTitle']||t("Box")}(${item.id})`,value: item.id}
                                })} 
                                onOptionChange={handleOptionChangeStrategies}
                      />
                    :""
                    }
                </Flex>

              <Flex>
                  <StyleTextField value={amount} onChange={(e)=>{setAmount(e.target.value)}} placeholder={t("Enter bet amount...")}/>
              </Flex>
           </Flex>
           <Flex fontSize={isDesktop?'20px':"0.08rem"} mt={"14px"} color={"#fff"} component="span">{t("What’s in the box? Please choose")}</Flex>

           <SelectConent mt='10px'> 
              <Flex color="#fff" className="FlexStyle"  alignItems="center" flexDirection="column">
                 <SelectBox 
                   className={selectWater?"selectStyle":"switchStyle"}
                   onClick={()=>{setSelect(true)}}
                   >
                     <img src="./image/Ice/水.png" alt=""/>
                 </SelectBox>
                 {t("Water")}
                 {
                  isDesktop?
                  <>
                    <Flex>{t("Have bet")}：{tranPrice(ownerState?.waterOwner?.amount)}</Flex>
                    <Flex>{t("Total bet")}：{tranPrice(boxInfo?.waterAmount)}</Flex>
                    {
                      new BigNumber(enaring?.water).gt(0)?
                      <>
                        <Flex>{t("Results earnings")}：{tranPrice(enaring?.water)}</Flex>
                        <StyleBtnEnaring mt={1} onClick={()=>{handRecaptionFuc(true)}}>{t("recaption")}</StyleBtnEnaring>  
                      </>
                      :""
                    }
                  </>
                  :getResWater()
                 }

                 
              </Flex>
             
              <Flex color="#fff"  className="FlexStyle"   ml={isDesktop?'51px':"0.11rem"} alignItems="center" flexDirection="column">
              <SelectBox  
              onClick={()=>{setSelect(false)}}
              className={!selectWater?"selectStyle":"switchStyle"}>
                 <img src="./image/Ice/冰.png" alt=""/>
              </SelectBox>
                 {t("ice")} 
                 {isDesktop?<>
                  <Flex>{t("Have bet")}：{tranPrice(ownerState?.iceOwner?.amount)}</Flex>
                  <Flex>{t("Total bet")}：{tranPrice(boxInfo?.iceAmount)}</Flex>
                  {
                      new BigNumber(enaring?.ice).gt(0)?<>
                      <Flex>{t("Results earnings")}：{tranPrice(enaring?.ice)}</Flex>
                      <StyleBtnEnaring mt={1} onClick={()=>{handRecaptionFuc(false)}}>{t("recaption")}</StyleBtnEnaring>
                    </>:""}
                 </>:getResIce()}
                 

              </Flex>

           </SelectConent>
           <Flex mt={2}>
            {getBtn()}
           </Flex>

          </IceCenter>

           <Flex mt={isDesktop?"64px":"0.54rem"}>
            {
              IceBoxlist.length>0&&selectBox!=null&&(IceBoxlist.find(p=>p.id==selectBox))["zhConent"]?
              <Box display='flex' color='#fff' flexDirection="column">
                   <Box component='h2'>{t("Ice Water Blind Box")}</Box>
                   <Box component='span'>{(IceBoxlist.find(p=>p.id==selectBox))["zhConent"]}</Box>
               </Box>
               :
               <Box display='flex' color='#fff' flexDirection="column">
                   <Box component='h2'>{t("Ice Water Blind Box")}</Box>
                   <Box component='span'>{t("Icedes_1")}</Box>
                   <Box mt={1} component='span'>{t("Icedes_2")}</Box>
                   <Box mt={1} component='span'>{t("Icedes_3")}</Box>
               </Box>
            }
               
          </Flex>
      </Flex>

        {/* //modal */}
      <TipModael
        successDes={successDes} 
        erro={shopErro} 
        isopen={shopOpen}
        setOpens={setShopOpen}>
        </TipModael>
      
    </StylePanel>


      }
    
  </>
          
    
  );
};

export default IceWater;


