import addresses from '../config/contract/contracts'

// const chainId = process.env.REACT_APP_CHAIN_ID
export const getDrawAddress = () => {
  return addresses.drawAddress[10000]
}
export const getIceOrWaterAddress = () => {
  return addresses.IceOrWater[10000]
}

export const getJigsawPuzzleAbiAddress = () => {
  return addresses.JigsawPuzzle[10000]
}

export const getBeeTokenAddress = () => {
  return addresses.beeToken[10000]
}

export const getimptAddress = () => {
  return addresses.imptAddress[10000]
}
export const getfactorytAddress = () => {
  return addresses.factoryAddress[10000]
}
export const getBchAddress = () => {
  return addresses.bchAddress[10000]
}