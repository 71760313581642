import BigNumber from "bignumber.js"

export default function toPrecision(amount:BigNumber,num:number): string {
    const BN = BigNumber.clone()
    // 重新制定小数位数，不使用科学计数法
    BN.config({EXPONENTIAL_AT:[-30,30]})
    return (new BN(amount.toString()).sd(num,BN.ROUND_HALF_UP)).toString()
}

export  function toPrecisionDown(amount:BigNumber,num:number): string {
    const BN = BigNumber.clone()
    // 重新制定小数位数，不使用科学计数法
    BN.config({EXPONENTIAL_AT:[-30,30]})
    return (new BN(amount.toString()).sd(num,BN.ROUND_DOWN)).toString()
}

export  function toFormatPrice(amount:BigNumber,validnum:number,fixNum?:number): string {
    if(amount.gt(1)){
       return amount.toFixed(fixNum||2)  
    }
    const BN = BigNumber.clone()
    // 重新制定小数位数，不使用科学计数法
    BN.config({EXPONENTIAL_AT:[-30,30]})
    return (new BN(amount.toString()).sd(validnum,BN.ROUND_DOWN)).toString()
}