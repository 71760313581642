import { useWallet } from '@binance-chain/bsc-use-wallet';
import { BottomNavigation, BottomNavigationAction, Box, Button, CircularProgress, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils'
import nftAbi from '../../../config/abi/nft.json';
import addresses from '../../../config/contract/nft';
import Header from '../header';
import DoneAllSharpIcon from '@material-ui/icons/DoneAllSharp';
import BeenDeploy from './BeenDeploy';
import MintPanel from './MintPanel';
import Contractcertification from './Contractcertification';
import CertificationHistory from './CertificationHistory';

const FlexPanel = styled(Box)`
  display:flex;
`
const Container = styled(Box)`
  background: black;
  padding: 20px;
  color: #fff;
`;




interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`wrapped-tabpanel-${index}`}
        aria-labelledby={`wrapped-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3} display='flex'>
            {children}
          </Box>
        )}
      </div>
    );
  }

const BeenManagement: React.FC =()=>{
    const [value,setValue] = useState(0)
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
            setValue(newValue);
    };

  return <Box display="flex" flex={1} flexDirection="column">
        <Paper square>
              <Tabs
                value={value}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChange}
                aria-label="disabled tabs example"
            >
                <Tab label="部署BeenToken" />
                <Tab label="铸造TOKEN" />
                <Tab label="合约认证" />
                <Tab label="历史记录" />
              </Tabs>
        </Paper>
      
            <TabPanel value={value} index={0}>
                <BeenDeploy></BeenDeploy>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <MintPanel></MintPanel>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Contractcertification></Contractcertification>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <CertificationHistory></CertificationHistory>
            </TabPanel>

            


            {/* <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                 This is a success message!
                </Alert>
            </Snackbar> */}
          </Box>

}

export default BeenManagement;