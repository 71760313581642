import { ethers } from "ethers"

export function packPrice(price:any) {
    var effBits = 1
    while(!price.mask(effBits).eq(price)) {
        effBits += 1
    }
    var twoPow24 = ethers.BigNumber.from(2).pow(24)
    if(effBits <= 25) {
        return price
    }
    var shift = effBits-25
    var shiftBN = ethers.BigNumber.from(2).pow(shift)
    var low24 = price.div(shiftBN).sub(twoPow24)
    var high8 = ethers.BigNumber.from(shift).add(1).mul(twoPow24)
    return high8.add(low24)
}

export function unpackPrice(packed:any) {
    var twoPow24 = ethers.BigNumber.from(2).pow(24)
    var low24 = packed.mod(twoPow24)
    var shift = packed.div(twoPow24)
    if(shift.isZero()) {
        return low24
    }
    var shiftBN = ethers.BigNumber.from(2).pow(shift.sub(1))
    return low24.add(twoPow24).mul(shiftBN)
}