import { Box, Button, createStyles, makeStyles, Modal, Theme } from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import useMatchBreakpoints from "../../hooks/useMatchBreakpoints";
import { useTranslation } from "react-i18next";
import ListTable from "./ListTable";
import { useBox, useOwnerApprove } from "./hooks/useBox";
import BigNumber from "bignumber.js";
import { useWallet } from '@binance-chain/bsc-use-wallet';
import TipModael from "../../component/TipModael";
import { useBeeContract, useJigsawPuzzle } from "../../hooks/useContract";
import { usePuzzleHandlers } from "../../state/Puzzles/hooks";
import Pagination from '@material-ui/lab/Pagination';
import ConnectWallt from "../home/ConnectWall";
import { getBeeTokenAddress,getJigsawPuzzleAbiAddress } from "../../utils/addressHelpers";
import useTokenApprove from "../../hooks/useApprove";


const StylePanel=styled(Box)<{state:boolean}>`
     height: 80px;
     /* background-color: #001634; */
     position:relative ;
     .colorwhile{
      color:#fff;
     }
     .green{
      color:#00CD63;
     }
     .blue{
      color:#00B3FF;
     }
     .orange{
      color:#FFB700;
     }
     opacity: ${(props) => (props.state?1:0.2)};
     pointer-events: ${(props) => (props.state ? "initial" : "none")};

      @media (min-width: 100px) {
        .testAfter{
        ::after {
          content: '';
          padding:${(props) => (props.state?'20px 20px 20px 20px':'36px 20px 20px 20px')};
          width:100%;   /* 或者flex: 1 */
         }
        } 
      }
      @media (min-width: 750px) {
        .testAfter{
        ::after {
          content: '';
          padding:${(props) => (props.state?'20px 20px 20px 20px':'36px 20px 20px 20px')};
          width:288px;   /* 或者flex: 1 */
         }
        } 
      }
      @media (min-width: 960px) {
        .testAfter{
        ::after {
          padding:${(props) => (props.state?'20px 20px 20px 20px':'36px 20px 20px 20px')};
          content: '';
          width:318px;   /* 或者flex: 1 */
         }
        } 
      }

      @media (max-width: 750px) {
       font-size: 0.18rem;
      }

   
`

const StylePuzzleList=styled(Box)`
      /* @media (max-width: 750px) {
        height:6.88rem;
      }
      @media (min-width: 750px) {
        height: 560px;
      }
     overflow-y:auto ; */
`
const StyleShopImg=styled.img`
      @media (max-width: 750px) {
        width:0.73rem;
        height:0.66rem;
      }
      @media (min-width: 750px) {
        width:73px;
        height:66px;
      }

`

const StyleRightList=styled(Box)<{isMobile:boolean}>`
     position: ${(props) => (props.isMobile?'none':"absolute")};
     right:0px;
     justify-content: ${(props) => (props.isMobile?'center':"none")};
     margin-top:${(props) => (props.isMobile?'23px':"0")} ;
`
const Flex=styled(Box)`
     display:flex;
`
const StyleImg=styled(Flex)<{isMobile:boolean}>`
      background-image: url('/image/myFuzzle/backBox.png');
      background-size:100% 100% ;
      padding:${(props) => (props.isMobile?'20px 20px 20px 20px':'32px 16px 20px 16px')};
      align-items:center;
      height:auto;
      /* width:${(props) => (props.isMobile?"100%":"310px")}; */
      

      @media (max-width: 750px) {
        width: 2.7rem;
      }
     
      @media (min-width: 750px) {
         width:380px;
      }
      @media (max-width: 600px) {
        width: 100%;
      }
      /* @media (min-width: 960px) {
        width:318px;
      } */
`
const BoxPanel=styled(Box)`
    
      
   
`

const BoxTitel=styled(Box)`
    font-family: SourceHanSansCN, SourceHanSansCN-Heavy;
    font-weight: 800;
    text-align: left;
    color: #ffb700;
    line-height: 48px;
`
const ShopBtn=styled(Box)<{isMobile:boolean}>`
    width:${(props) => (props.isMobile?'112px':'160px')};
    height: ${(props) => (props.isMobile?'33px':'48px')};
    background-color: #0058CD;
    font-size:18px;
    font-weight: bold;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    :hover{
      background-color: #033980;
    }
`

const ShopHasBtn=styled(Box)<{isMobile:boolean}>`
    width:${(props) => (props.isMobile?'112px':'160px')};
    height: ${(props) => (props.isMobile?'33px':'48px')};
    background-color: #8c8c8d;
    font-size:18px;
    font-weight: bold;
    justify-content: center;
    border-radius: 5px;
`

const TimeBox=styled(Box)<{isMobile:boolean}>`
    background-color:black;
    padding: ${(props) => (props.isMobile?'0.05rem 0.12rem 0.05rem 0.12rem':'6px 12px 6px 12px')} ;
    font-size:${(props) => (props.isMobile?'0.32rem':'32px')};
    color: #6FBA0F;
    /* border:1px dashed #fff; */
`
const StylePagination=styled(Pagination)`
  ul>li>button{
    color:#fff;
    font-size: 24px;
  }
  ul>li>div{
    color:#fff;
    font-size: 24px;
  }
`

const BoxImgStyle=styled.img`
     @media (max-width: 750px) {
       /* width: 40%;
       height:40%; */
       width:  1.15rem;
       height:1.05rem;
     }

     @media (min-width:750px){
       width: 164px;
       height:150px;
     }

     @media (max-width: 600px) {
       width: 40%;
       height:40%;
     }
`
const BeeImg=styled.img`
     @media (max-width: 750px) {
       width:0.24rem;
       height:0.24rem;
     }

     @media (min-width:750px){
      width: 27px;
      height: 27px;
     }
`

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
);

const PuzzleGame: React.FC = () => {

  const { isDesktop, isMobile } = useMatchBreakpoints();
  const { t } = useTranslation();
  const {account} = useWallet();
  const PuzzleContract=useJigsawPuzzle();

  const [page,setPage]=useState(1);
  const [shopOpen,setShopOpen]=useState(false);
  const [shopErro,setShopErro]=useState<any>("");
  const [timeData,setTimeData]=useState("");
  const inter=useRef<any>(-1);
  const [dateSplit,setDateSplit]=useState<any>({hour:[],min:[],second:[]}); 
  
  const {onIsRefresh}=usePuzzleHandlers();

  const tokenContract=useBeeContract();
  const {isApprove}=useOwnerApprove();
  const onTokenApprove = useTokenApprove(tokenContract, getJigsawPuzzleAbiAddress());
  





  const roladList=()=>{
    onIsRefresh(true)
    setTimeout(()=>{
        onIsRefresh(false)
    },1000)
  }
  // 授权
  const handleApprove = useCallback(async () => {
      try {
          const txHash = await onTokenApprove();
          roladList();
      } catch (e) {
          console.error(e)
      }
  }, [onTokenApprove])

  // 获取boxlist 列表计算
  const {boxList}=useBox();
  const totalBox=useMemo(()=>{
    let num=0;
    let shenyu=0;
    for(let p of boxList){
      num+=parseInt(p.totalBoxCount)
      shenyu+=parseInt(p.residue)
    }
    return [num,shenyu];
  },[boxList])

  const showBox=useMemo(()=>{
    const start=(page-1)*6;
    const end=start+6;
    return boxList.slice(start,end);
  },[boxList,page])

 
  // 购买
  const handShop=async(lotId:number)=>{
    setShopErro("")
    try{
      const res=await PuzzleContract.methods.buyBlindbox(lotId).send({ from: account });
      console.log("success:",res)
      setShopOpen(true);
      roladList();
    }catch(e:any){
      console.log("success:",e)
      setShopErro(e.message?e.message:e.toString())
      setShopOpen(true);
    }
  }

  


  const getBtn=(index:number,dueTime:any)=>{
    if(parseInt(dueTime)*1000<new Date().getTime()){
        return <ShopHasBtn isMobile={!isDesktop} display='flex' alignItems='center'>
        <Box color="#fff" component='span'>{t("Has as")}</Box>
      </ShopHasBtn>
    }

    if(isApprove){
       return <ShopBtn isMobile={!isDesktop} onClick={()=>{handShop(index)}} display='flex' alignItems='center'>
       <Box color="#fff" component='span'>{t("BUY IT")}！</Box>
     </ShopBtn>
    }else{
      return <ShopBtn isMobile={!isDesktop} onClick={handleApprove} display='flex' alignItems='center'>
        <Box color="#fff" component='span'>{t("approve")}</Box>
      </ShopBtn>
    }
   
  }
  //list
  const tranPrice=(priceOfBox:string)=>{
    return (new BigNumber(priceOfBox).div(new BigNumber(10).pow(18))).toFixed();
  }
  const mysteryBox=(item:any,index:number)=>{
     return   <StyleImg 
              display="felx"
          
              // ml={!isDesktop?0:4}
              isMobile={!isDesktop}
              className={"list"}
              mt={5}>
    
     <Flex flexDirection="column">
        <Flex>
          <BoxImgStyle src="./image/myFuzzle/box.png" alt=""/>
          <Flex ml={'20px'} flexDirection="column">
            <BoxTitel fontWeight='bold' className="orange"  fontSize={!isDesktop?'0.24rem':'32px'}  component="span">BlindBox {index+1}</BoxTitel>
            <Flex alignItems='center' className="colorwhile" fontSize={!isDesktop?'0.18rem':'18px'} component="span">
                {t("total quantity")}：
                <Box className="green"  fontSize={!isDesktop?'14px':'32px'}  component="span">{item.residue}</Box>
                <Box className="colorwhile"  fontSize={!isDesktop?'14px':'18px'}  component="span">&nbsp;/{item.totalBoxCount}</Box>
            </Flex>

            <Flex alignItems='center' className="colorwhile" fontSize={!isDesktop?'0.18rem':'18px'} component="span">
                {t("Pieces of each box")}：
                <Box className="green"  fontSize={!isDesktop?'14px':'32px'}  component="span">{item.jigsawPieceCountInEachBox}</Box>
            </Flex>

            <Flex  flexDirection="column" alignItems='start' mt={!isDesktop?1:3} >
                <Flex alignItems='center' >
                  <BeeImg src="./image/myFuzzle/beetoken.png" alt=""/>
                  <Box ml={1} className="orange"   fontSize={!isDesktop?"0.32rem":'32px'} component="span">{tranPrice(item.priceOfBox)}</Box>
                
                </Flex>
               
                <Flex justifyContent="end" mt={3}>
                    {getBtn(index,item.dueTime)};
                    {/* {isApprove?
                    <ShopBtn isMobile={!isDesktop} onClick={()=>{handShop(index)}} display='flex' alignItems='center'>
                      <Box color="#fff" component='span'>{t("BUY IT")}！</Box>
                    </ShopBtn>
                    :
                    <ShopBtn isMobile={!isDesktop} onClick={handleApprove} display='flex' alignItems='center'>
                      <Box color="#fff" component='span'>{t("approve")}</Box>
                    </ShopBtn>
                    } */}



                </Flex>
            </Flex>
          </Flex>
        </Flex>
     
     </Flex>
     </StyleImg>
  }
  

  const format=useCallback((second:string)=>{
    let second_Time=(parseInt(second)*1000) - (new Date().getTime());
    if(second_Time<0){
      second_Time=0;
    }
    let seconds:number=Number((second_Time/1000).toFixed(0));
    let hour:any= Math.floor(seconds / 3600) >= 10 ? Math.floor(seconds / 3600) : '0' + Math.floor(seconds / 3600);
    seconds -= 3600 * hour;
    let min:any = Math.floor(seconds / 60) >= 10 ? Math.floor(seconds / 60) : '0' + Math.floor(seconds / 60);
    seconds -= 60 * min;
    let sec = seconds >= 10 ? seconds : '0' + seconds;
    return hour + ':' + min + ':' + sec;
  },[])

  useEffect(()=>{
     if(boxList.length>0 && inter.current==-1){
        inter.current=setInterval(()=>{
          const dueTime=boxList[boxList.length-1]?.dueTime;
          const formatTime=format(dueTime)
          setTimeData(formatTime)
          const times=formatTime.split(":");
          setDateSplit({
            hour:times[0].split(""),
            min:times[1].split(""),
            second:times[2].split("")
          })
          if(times[0]=="00"&&times[1]=="00"&&times[2]=="00"){
            console.log("清除")
            clearInterval(inter.current)
          }
        })
     }
     
     return ()=>{clearInterval(inter.current)}
  },[format,boxList])
  


  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
       setPage(value);
  };

  return (<>
     {!account?<Box display="flex"  flex={1}>
                   <Flex flex={1} alignItems="center" justifyContent="center">
                      <Flex >
                         <ConnectWallt></ConnectWallt>
                      </Flex>
                   </Flex>
                 
              </Box>
           :
         <StylePanel state={boxList.length>0?true:false}  display="flex"  flexDirection="column"   flex={1}  justifyContent="center" >
         <StylePuzzleList display="flex" flex={1}>
           <ListTable></ListTable>
         </StylePuzzleList>
  
         <Box mt={isDesktop?12:"0.74rem"} display="flex" flexDirection={!isDesktop?"column":"row"} justifyContent='center'>
            <Box 
            flexDirection="column" 
            display="flex" 
            alignItems='center'>
              <StyleShopImg src="./image/myFuzzle/shop.png" alt="" />
              <Box  mt={2} className="colorwhile">- {t("BlindBox Shop")} -</Box>

              <Flex mt={!isDesktop?2:0} justifyContent="center">
               <Box display="flex" fontSize={isDesktop?"24px":"0.24rem"} fontWeight='800' alignItems='center'  className="colorwhile" component="span">
               {t("ALL THE BOX")}:
                <Box ml='14px' className="green"  fontSize={isDesktop?"40px":"0.4rem"}  component="span">{totalBox[0]}</Box>
                </Box>
                <Box display="flex" fontSize={isDesktop?"24px":"0.24rem"}  fontWeight='800' alignItems='center'  ml='64px' 
                className="colorwhile" component="span">
                 {t("SURPLUS BOX")} :
                  <Box ml='14px' fontSize={isDesktop?"40px":"0.4rem"}  className="blue"  component="span">{totalBox[1]}</Box>
                </Box>
              </Flex>
  
            </Box>
            <StyleRightList isMobile={!isDesktop} alignItems='center' display='flex' className="colorwhile">

              <Box mr={1} component='span'>{t("Close the shop")}</Box>
              {timeData?
              
              <>
                 {dateSplit?.hour?.map((t:any)=>(
                  <TimeBox mr={"0.1rem"} isMobile={!isDesktop}>{t}</TimeBox>
                ))}：
                {dateSplit?.min?.map((t:any)=>(
                  <TimeBox mr={"0.1rem"} isMobile={!isDesktop}>{t}</TimeBox>
                ))}：
                {dateSplit?.second?.map((t:any)=>(
                  <TimeBox mr={"0.1rem"} isMobile={!isDesktop}>{t}</TimeBox>
                ))}
              </>:""
              }
            
              
  
              {/* {format(boxList.length>0?boxList[boxList.length-1]?.dueTime:"0")} */}
            </StyleRightList>
         </Box>
         
         <Box mt={2}  flexDirection="column" display="flex" >

  
  
           <BoxPanel 
            className={(showBox.length%2==0)?"testAfter":'testAfter'} 
            justifyContent="space-between"
            // justifyContent={isDesktop?"left":"center"} 
            display="flex" 
            flexWrap="wrap">
            {
              showBox.map((t,idx)=>(
                <>{mysteryBox(t,idx)}</>
              ))
            }
  
           
            

           </BoxPanel>
            <Box mt={5} display='flex' alignItems='center' justifyContent="center">
              {
                Math.ceil(boxList.length/6)>1?
                <StylePagination   onChange={handleChange} count={Math.ceil(boxList.length/6)} size="large" hidePrevButton hideNextButton/>:""
              }
            </Box>


            <Box display='flex' color='#fff' flexDirection="column">
              <Box component='h3'>{t("Jigsaw Blind Box")}</Box>
              <Box component='span'>{t("Jigsaw Blind Box des")}</Box>
           </Box>
         </Box>
  
         
  
        {/* //modal */}
        <TipModael 
         erro={shopErro} 
         isopen={shopOpen}
         setOpens={setShopOpen}></TipModael>
         </StylePanel>
    }
  </>
    
  );
};

export default PuzzleGame;
