import { useWallet } from "@binance-chain/bsc-use-wallet";
import BigNumber from "bignumber.js";
import { useState,useEffect } from "react";
import { useBeeContract } from "../../../../hooks/useContract";
import { useDrawBoxState } from "../../../../state/DrawBox/hooks";
import { getDrawAddress } from "../../../../utils/addressHelpers";

export  function useDrawApprove():{isApprove:boolean}{
    const [isApprove, setAllowanceState] = useState<boolean>(false);
    const beeContract=useBeeContract();
    const {account} = useWallet();
    const {pageRefreshState}=useDrawBoxState();
    useEffect(() => {
      const fetch = async () => {
        try {
            const allowance= await beeContract.methods.allowance(account,getDrawAddress()).call();
            const tokenAllowedStatus = allowance && !new BigNumber(allowance).isLessThan(new BigNumber(10).pow(77))
            setAllowanceState(tokenAllowedStatus)
        } catch (error) {}
      }
  
      if(account||pageRefreshState){
         fetch()
      } 
    }, [setAllowanceState,account,pageRefreshState])

    return {isApprove}
}