import { createReducer } from '@reduxjs/toolkit'
import { roladPage } from './actions'
 
export interface puzzleState {
  readonly pageRefreshState: boolean
}
const initialState: puzzleState = {
      pageRefreshState:false
}
// dexs:['MISTSWAP','TANGOSWAP','LAWSWAP','COWSWAP','ONEBCH','TROPICALFINANCE','VERSEDEX','EMBERSWAP']

export default createReducer<puzzleState>(initialState, (builder) =>
  builder
    .addCase(roladPage, (state, { payload: { isRefresh } }) => {
      return {...state,pageRefreshState:isRefresh}
    })
)
