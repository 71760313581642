import { useWallet } from '@binance-chain/bsc-use-wallet';
import { BottomNavigation, BottomNavigationAction, Box, Button, CircularProgress, Paper, Tab, Tabs, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils'
import JigsawPuzzleAbi from '../../../config/abi/JigsawPuzzle.json';

import DoneAllSharpIcon from '@material-ui/icons/DoneAllSharp';
import useWeb3 from '../../../hooks/useWeb3';
import {  getJigsawPuzzleAbiAddress } from '../../../utils/addressHelpers';
import { useJigsawPuzzle } from '../../../hooks/useContract';
import BigNumber from 'bignumber.js';
import tranId from '../../../utils/tran';



const FlexPanel = styled(Box)`
  display:flex;
`
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}


const ResValid: React.FC =()=>{
    const {account} = useWallet();
    const [pedding,setPedding] = useState(false);
    const PuzzleContract=useJigsawPuzzle();

    const [lotId,setlotId] = useState("");
    const [imgId,setImgId] = useState("");
    const [data,setData] = useState<any>({});
 
  
    const createHand=async ()=>{
        setData({})
        PuzzleContract.getPastEvents('Transfer', {
            fromBlock: 0,
             toBlock: 'latest'
        }).then(function(events){
            if(events.length>0){
                console.log(events)
                const indexId=parseInt(lotId)<<64|parseInt(imgId)
                const res= events.find((t,idx)=>indexId==idx);
                setData(res)
                console.log("ressss",res)
            }
            console.log("events",events) // same results as the optional callback above
        });

        //   setPedding(true)
        //   try {
        //     const res=await PuzzleContract.methods.createLot(data,intro).send({from:account||""});
        //     console.log("结果",res)
        //     setHash(res.blockHash)
        //     setPedding(false)
        //   }catch(e){}
          
    }



   return <Box display="flex" flex={1} flexDirection="column">
                 <FlexPanel flexDirection="column" style={{width:'50%'}}>
                    <TextField 
                    id="standard-basic1" 
                    label="批次ID"
                    onChange={(event)=>{setlotId(event.target.value)}}
                    />

                   <TextField 
                    id="standard-basic1" 
                    label="碎片ID"
                    onChange={(event)=>{setImgId(event.target.value)}}
                    />



                    <FlexPanel mt={2} alignItems="center">
                        <Button variant="contained" color="primary" onClick={createHand}>
                                查询
                        </Button>
                        <Box ml={2} mt={2}></Box>
                        {/* {pedding?<CircularProgress />:""}
                        {hash!==""?<DoneAllSharpIcon  style={{color:'#14b714'}}></DoneAllSharpIcon>:""} */}
                    </FlexPanel>

                     <Box>
                        <Box>持有地址：{data?.returnValues?.newOwer}</Box>
                        <Box>最后一次区块：{data?.blockNumber}</Box>
                        <Box>图片：
                             {
                                data?.returnValues?.newOwer?
                                <img src={`https://blindbox.cash/jigsaw/${lotId}/piece${tranId(imgId)}.jpg`} width='100px' height="100px"/>
                                :""

                             }
                        </Box>
                     </Box>

                    
                    {/* <FlexPanel flexDirection="column">
                        <h3 style={{"marginBlockEnd":0}}>交易hash:</h3>
                        <h4 style={{"marginBlockStart":0}}>{hash}</h4>
                    </FlexPanel> */}
                  </FlexPanel>
          </Box>

}

export default ResValid;