import { Box, Button } from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import useMatchBreakpoints from "../../hooks/useMatchBreakpoints";
import { useTranslation } from "react-i18next";
import { useDrawAndBuyersHeights, useDrawApprove, useDrawId, useGetBetaAndPI, useHandFuc, useWithdraw } from "./hooks/useDraw";
import { useWallet } from "@binance-chain/bsc-use-wallet";
import BigNumber from "bignumber.js";
import TipModael from "../../component/TipModael";
import {  getDrawAddress, getIceOrWaterAddress } from "../../utils/addressHelpers";
import useTokenApprove from "../../hooks/useApprove";
import { useBeeContract, useDrawContract } from "../../hooks/useContract";
import { useIceHandlers } from "../../state/Ice/hooks";
import ConnectWallt from "../home/ConnectWall";
// import { tranPrice, tranTotalPrice } from "../../utils/tran";
// import Web3 from "web3";
// import useWeb3 from "../../hooks/useWeb3";
// import { ethers } from "ethers";
import { useLocation, useParams } from "react-router-dom";
import useWeb3 from "../../hooks/useWeb3";
import { tranIdDraw, tranPrice } from "../../utils/tran";
import { useDrawBoxHandlers } from "../../state/DrawBox/hooks";
import { isPending } from "@reduxjs/toolkit";


const StylePanel=styled(Box)<{state: number }>`
     align-items: center;

     opacity: ${(props) => (props.state?1:0.2)};
     pointer-events: ${(props) => (props.state ? "initial" : "none")};

     @media (max-width: 750px) {
        font-size: 0.16rem;
        .font18{
          font-size: 0.25rem;
        }
        .font24{
          font-size: 0.24rem;
          font-weight: bold;
        }
        .font40{
          font-size: 0.4rem;
          font-weight: bold;
        }
      }
      @media (min-width:750px){
        .font18{
          font-size: 18px;
        }
        .font24{
          font-size: 24px;
          font-weight: bold;
        }
        .font40{
          font-size: 40px;
          font-weight: bold;
        }
      }
`


const Flex=styled(Box)`
     display: flex;
`
const IceTop=styled(Flex)`

      border: 5px solid #5073A1;
      background-color: black;
      @media (max-width: 750px) {
        margin-top:0.7rem;
      }
      @media (min-width:750px){
        margin-top:80px;
      }
`

const StyletotoalDes=styled(Flex)`
      @media (max-width: 750px) {
        font-size: 0.25rem;
      }
      @media (min-width:750px){
        font-size: 18px;
      }
`


const StyleIcon=styled.img`
      @media (max-width: 750px) {
        width: 0.4rem;
        height: 0.4rem;
      }
      @media (min-width:750px){
        width: 40px;
        height: 40px;
      }
`

const StyleTop=styled.img`
      @media (max-width: 750px) {
        width: 4rem;
        height: 1.2rem;
        margin-top: -0.85rem;
      }
      @media (min-width:750px){
        width: 480px;
        height: 123px;
        margin-top: -120px;
      }
`
const BoxImg=styled.img`
      @media (max-width: 750px) {
        width: 1.85rem;
        height: 1.6rem;
        margin-top:0.28rem;
      }
      @media (min-width:750px){
        width: 417px;
        height: 350px;
        margin-top:48px;
      }
`

const DesPanel=styled(Flex)`
      @media (max-width: 750px) {
        padding:0.18rem 0.18rem 0px 0.18rem;
        font-size: 0.18rem;
      }
      @media (min-width:750px){
        padding:10px 15px 0px 15px;
        font-size: 20px;
      }
`
const DesVale=styled(Flex)`
      color: #74DD54;
      @media (max-width: 750px) {
        font-size: 0.25rem;
      }
      @media (min-width:750px){
        font-size: 40px;
      }
`

const StyleBtn=styled(Flex)`
      @media (max-width: 750px) {
        padding:0.1rem 0.46rem 0.1rem 0.46rem;
        font-size: 0.18rem;
      }
      @media (min-width:750px){
        padding:10px 65px 10px 65px ;
        font-size: 20px;
      }
      color:#fff;
      border-radius: 4px;
      background: linear-gradient(358deg,#FF720A 5%,#FFD040 99%);  
      cursor: pointer;
`

const StyleNUmber=styled(Flex)`
      color:#fff;
      @media (max-width: 750px) {
        .font18{
          font-size: 0.18rem;
        }
        .font24{
          font-size: 0.24rem;
          font-weight: bold;
        }
        .font40{
          font-size: 0.4rem;
          font-weight: bold;
        }
      }
      @media (min-width:750px){
        .font18{
          font-size: 18px;
        }
        .font24{
          font-size: 24px;
          font-weight: bold;
        }
        .font40{
          font-size: 40px;
          font-weight: bold;
        }
      }
`
const StyleCenterBox=styled(Flex)`
      
      @media (max-width: 750px) {
         padding:0.27rem 0.1rem 0.27rem 0.1rem;
        .boxStyle{
          /* width: 0.77rem;
          height:0.65rem; */
          width: 1.3rem;
          height: 1rem;
        }
        .boxButtom{
          /* width: 0.77rem; */
        }
        .segmentation{
          height: 0.1rem;
          background-color: #5F6A72;
          border:1px solid #979797;
        }
        .tokenStyle{
          width:0.2rem;
          height:0.2rem;
          padding: 0px 0.03rem 0px 0.1rem;
        }
        .font32{
          font-size: 0.25rem;
        }
        .font24{
          font-size: 0.25rem;
        }
      }
      @media (max-width: 500px) {
        padding:0.27rem 0.1rem 0.4rem 0.1rem;
      }
      @media (min-width:750px){
        padding:60px 100px 60px 60px;
        .boxStyle{
          width: 160px;
          height:133px;
        }

        .boxButtom{
          width: 160px;

        }
        .segmentation{
          height: 21px;
          background-color: #5F6A72;
          border:1px solid #979797;
        }
        .tokenStyle{
          width:18px;
          height:18px;
          padding: 0px 8px 0px 8px;
        }
        .font32{
          font-size: 32px;
        }
        .font24{
          font-size: 24px;
        }
      }
`
const ShopBtn=styled(Box)<{isMobile:boolean}>`
    @media (max-width: 750px) {
       width:auto;
       font-size: 16px;
       padding:0.2rem 0.2rem 0.2rem 0.2rem;
    }
    @media (min-width: 750px) {
       width:auto;
       padding:3px 20px 3px 20px;
    }

    white-space: nowrap;
    /* width:${(props) => (props.isMobile?'0.54rem':'112px')}; */
    height: ${(props) => (props.isMobile?'0.19rem':'40px')};
    background-color: #0058CD;
    font-size:${(props) => (props.isMobile?'0.09rem':'18px')};
    font-weight: bold;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    :hover{
      background-color: #033980;
    };
    margin-top:${(props) => (props.isMobile?'0.08rem':'18px')};
`
const PeddingBtn=styled(Box)<{isMobile:boolean}>`
    @media (max-width: 750px) {
       width:auto;
       padding:0.2rem 0.2rem 0.2rem 0.2rem;
    }
    @media (min-width: 750px) {
       width:auto;
       padding:3px 20px 3px 20px;
    }

    white-space: nowrap;
    height: ${(props) => (props.isMobile?'0.19rem':'40px')};
    background-color: #737576;
    font-size:${(props) => (props.isMobile?'0.09rem':'18px')};
    font-weight: bold;
    justify-content: center;
    border-radius: 5px;
    /* cursor: pointer;
    :hover{
      background-color: #033980;
    }; */
    margin-top:${(props) => (props.isMobile?'0.08rem':'18px')};
`

const TimeBox=styled(Box)<{isMobile:boolean}>`
        @media (max-width: 750px) {
            font-size: 0.3rem;
            padding:0.05rem 0.06rem 0.05rem 0.06rem;
        }
        @media (min-width:750px){
           padding:6px 12px 6px 12px;
        }
    background-color:#262626;
    font-size:${(props) => (props.isMobile?'0.18rem':'32px')};
    color: #6FBA0F;
    border-radius: 5px;
  
`


const DrawLots: React.FC = () => {
  // const location = useLocation();
  const {id} = useParams<any>();
  const {lotId,deadline}=useDrawId(id||"");
  // const [lotId, setLotId] = useState<any>(id || drawId);
  const DrawContract=useDrawContract();
  const {drawData,buyerMap,listMoney}=useDrawAndBuyersHeights(lotId);
  const {handleBuy,handleRecaption}=useHandFuc();
  const [successDes,setSuccessDes]=useState<any>("");
  const [initState,setInitState]=useState<boolean>(false);

  useEffect(()=>{
    const listMoneys=listMoney.filter(t=>t!=0);
    if(listMoneys.length>0 && !initState){
      setInitState(true)
      setSuccessDes("rewardTips");
      setShopErro("")
      setShopOpen(true);
    }
  },[listMoney,initState])
  
  
  const {account} = useWallet();
  const { isDesktop, isMobile } = useMatchBreakpoints();
  const { t } = useTranslation();
  const [testData,settestData]=useState([0,1,2,3,4,5,6,7,8,9]);
  // const [testData,settestData]=useState<any>([]);
  useEffect(()=>{
    if(parseInt(drawData.totalCount)>0){
      const drawDatas=(new Array(parseInt(drawData.totalCount))).fill("");
      settestData(drawDatas);
    }
  },[drawData])


  // drawData
  // 授权
  const {isApprove}=useDrawApprove();
  const tokenContract=useBeeContract();
  const onApprove=useTokenApprove(tokenContract,getDrawAddress());

  //hand state
  const [shopOpen,setShopOpen]=useState(false);
  const [shopErro,setShopErro]=useState<any>("");

  
  const {onIsRefresh}=useDrawBoxHandlers();
  const roladList=()=>{
    onIsRefresh(true)
    setTimeout(()=>{onIsRefresh(false)},1000)
  }


  // 授权
  const handleApprove = useCallback(async () => {
      try {
          const txHash = await onApprove();
          roladList();
      } catch (e) {
          console.error(e)
      }
  }, [onApprove])


  

  
  const web3=useWeb3();
  const {getBetaAndPI}=useGetBetaAndPI();
  const [pedding,setPedding]=useState<any>(false);
  const handBuy=async()=>{
      setPedding(true);
      setSuccessDes("");
      setShopErro("")
      const respon=await handleBuy(lotId);
      if(respon.state){
        console.log("success:",respon.res)
        setShopOpen(true);
        roladList();
      }else{
        console.log("erro:",respon.res)
        setShopErro(respon.res.message?respon.res.message:respon.res.toString())
        setShopOpen(true);
      }
      setPedding(false);
      // console.log(new BigNumber(8).times(new BigNumber(2).pow(10)).plus(new BigNumber(8)).toFixed())
  }
  const handRecaption=async(data:any)=>{
    
        setSuccessDes("Extraction of success");
        setShopErro("")
        
        const dataRes= await web3.eth.getBlock(data.block);
        const result= await getBetaAndPI(dataRes.hash.split("0x")[1]);

        const respon=await handleRecaption(lotId,data.idx,"0x"+result.Beta,"0x"+result.PI);
        if(respon.state){
          console.log("success:",respon.res)
          setShopOpen(true);
          roladList();
        }else{
          console.log("erro:",respon.res)
          setShopErro(respon.res.message?respon.res.message:respon.res.toString())
          setShopOpen(true);
        }
        
  }
  const getBtn=()=>{
    if(pedding){
      return<PeddingBtn isMobile={!isDesktop}  display='flex' alignItems='center'>
           <Box color="#fff" component='span' onClick={handBuy}>{t("during purchase")}</Box>
      </PeddingBtn>
    }
    if(isApprove){
          return <ShopBtn isMobile={!isDesktop}  display='flex' alignItems='center'>
             <Box color="#fff" component='span' onClick={handBuy}>{t("BUY IT")}</Box>
          </ShopBtn>
    }else{
      return <ShopBtn isMobile={!isDesktop}  display='flex' alignItems='center'>
          <Box color="#fff" component='span' onClick={handleApprove}>{t("approve")}</Box>
      </ShopBtn>
    }
  }

  // 盒子布局
  const [page,setPage]=useState(isDesktop?5:3);
  useEffect(()=>{setPage(isDesktop?5:3)},[isDesktop])
  const dataLen=useMemo(()=>Math.ceil(testData.length/page),[testData,page]);
  const showMoney=(idx:number,page:number,index:number):any=>{
    const item=listMoney.find(item=>item.idx==(idx*page)+index)
    if(item && item.money!=0){
     return item;
    }
    return 0;
  }
  // 查询是否存在奖励未领取
  const isAward=useMemo(()=>{
    const res:any=listMoney.filter(t=>{
      if(new BigNumber(t.money).gt(0)){
         return t;
      }
    })
    return res.length>0
  },[listMoney]);



  //定时器
  const [timeData,setTimeData]=useState("");
  const inter=useRef<any>(-1);
  // const [dateSplit,setDateSplit]=useState<any>({hour:[],min:[],second:[]}); 
  const dateSplit=useRef<any>({hour:[],min:[],second:[]}); 
  const [dateEnd,setDateEnd]=useState(false);
  const format=useCallback((second:string)=>{
    let second_Time=(parseInt(second)*1000) - (new Date().getTime());
    if(second_Time<0){
      second_Time=0;
    }
    let seconds:number=Number((second_Time/1000).toFixed(0));
    let hour:any= Math.floor(seconds / 3600) >= 10 ? Math.floor(seconds / 3600) : '0' + Math.floor(seconds / 3600);
    seconds -= 3600 * hour;
    let min:any = Math.floor(seconds / 60) >= 10 ? Math.floor(seconds / 60) : '0' + Math.floor(seconds / 60);
    seconds -= 60 * min;
    let sec = seconds >= 10 ? seconds : '0' + seconds;
    return hour + ':' + min + ':' + sec;
  },[])

  useEffect(()=>{
        if(deadline && inter.current==-1){
  
          inter.current=setInterval(()=>{
              const dueTime=deadline?deadline:'0';
              const formatTime=format(dueTime)
              setTimeData(formatTime)
              const times=formatTime.split(":");
              dateSplit.current={
                hour:times[0].split(""),
                min:times[1].split(""),
                second:times[2].split("")
              }
              // setDateSplit({
              // hour:times[0].split(""),
              // min:times[1].split(""),
              // second:times[2].split("")
              // })
              if(times[0]=="00"&&times[1]=="00"&&times[2]=="00"){
                console.log("clearInterval");
                setDateEnd(true)
                clearInterval(inter.current)
              }
          })
        }
        // return ()=>{clearInterval(inter.current)}
  },[format,deadline])

  useEffect(()=>{ 
     return ()=>{clearInterval(inter.current)}
  },[])



  return (<>
  
  {!account?<Box display="flex"  flex={1}>
          <Flex flex={1} alignItems="center" justifyContent="center">
            <Flex >
                <ConnectWallt></ConnectWallt>
            </Flex>
          </Flex>
         </Box>
      :
      <StylePanel state={Object.keys(drawData).length>0?1:0}  display="flex" flex={1}  alignItems="center">
      <Flex display="flex" flexDirection="column" flex={1} >
          <IceTop flex={1} flexDirection="column">

               <Flex  justifyContent="space-between" flexDirection="column">
                   <DesPanel flex={1}  className="color_whilte">
                       
                       <Flex  flex={1}  alignItems="center">
                          {isDesktop?<>
                                   <StyleIcon src="./image/Ice/团队.png" alt=""/>
                                   <StyletotoalDes ml={1} component="span">{t("Participants")}:</StyletotoalDes>
                                   <DesVale ml={1} component="span">{(buyerMap||[]).length}</DesVale>

                          </>:""}
                       </Flex>

                       <Flex flex={1} alignItems="center" justifyContent="center">
                           <StyleTop src="./image/Draw/top.png" alt=""/>
                       </Flex>

                 
                       <Flex flex={1} justifyContent="end">
                          {isDesktop?
                           <Flex  flexDirection="column">
                           <Flex className="color_whilte" 
                            justifyContent="center"
                            fontWeight='bold'
                            fontSize={isDesktop?"":"16px"}
                            // mt={isDesktop?"24px":"0.12rem"} 
                            component='span'>{t("SHOP CLOSE TIME")}</Flex>
                                       {timeData?
                                       
                                       <Flex alignItems="center" color={"#fff"} mt={isDesktop?"11px":"0.06rem"}>
                                           {dateSplit?.current?.hour?.map((t:any,idx:number)=>(
                                           <TimeBox key={"hour"+idx} mr={"0.1rem"} isMobile={!isDesktop}>{t}</TimeBox>
                                           ))}：
                                           {dateSplit?.current?.min?.map((t:any,idx:number)=>(
                                           <TimeBox key={"min"+idx} mr={"0.1rem"} isMobile={!isDesktop}>{t}</TimeBox>
                                           ))}：
                                           {dateSplit?.current?.second?.map((t:any,idx:number)=>(
                                           <TimeBox key={"second"+idx} mr={"0.1rem"} isMobile={!isDesktop}>{t}</TimeBox>
                                           ))}
                                       </Flex>:""
                                       }
                               
                           </Flex>
                           :""
                          }
                       </Flex>
                       
                   </DesPanel>

                  {/* 移动端显示 */}
                   {!isDesktop?
                   <Flex flexDirection="column" mt="0.3rem">
                       <Flex  justifyContent='center' color="#fff" alignItems="center">
                          <StyleIcon src="./image/Ice/团队.png" alt=""/>
                          <StyletotoalDes ml={1} component="span">{t("Total Bets")} :</StyletotoalDes>
                          <DesVale ml={1} component="span">{(buyerMap||[]).length}</DesVale>
                       </Flex>
                       <Flex mt={"0.2rem"} justifyContent='center'>
                      
                          <Flex  >
                              <Flex  flexDirection="column">
                              <Flex className="color_whilte" 
                                justifyContent="center"
                                fontWeight='bold'
                                // mt={isDesktop?"24px":"0.12rem"} 
                                component='span'>{t("SHOP CLOSE TIME")}</Flex>
                                          {timeData?
                                          
                                          <Flex alignItems="center" color={"#fff"} mt={isDesktop?"11px":"0.06rem"}>
                                              {dateSplit?.current?.hour?.map((t:any,idx:number)=>(
                                              <TimeBox key={"hour"+idx} mr={"0.1rem"} isMobile={!isDesktop}>{t}</TimeBox>
                                              ))}：
                                              {dateSplit?.current?.min?.map((t:any,idx:number)=>(
                                              <TimeBox key={"min"+idx} mr={"0.1rem"} isMobile={!isDesktop}>{t}</TimeBox>
                                              ))}：
                                              {dateSplit?.current?.second?.map((t:any,idx:number)=>(
                                              <TimeBox key={"second"+idx} mr={"0.1rem"} isMobile={!isDesktop}>{t}</TimeBox>
                                              ))}
                                          </Flex>:""
                                          }
                                  
                              </Flex>
                          </Flex>

                       </Flex>
                   </Flex>:""
                   }
                   <StyleNUmber mt={"12px"} justifyContent="center" flex={1}>
                         <Flex>
                          <Flex alignItems="center" className="font24" component="span">{t("ALL THE BOX")}：</Flex>
                          <Flex alignItems="center" color="#00CD63" className="font40" component="span">{drawData?.totalCount}</Flex>
                         </Flex>
                         <Flex ml={8}>
                          <Flex alignItems="center" className="font24" component="span">{t("SURPLUS BOX")}：</Flex>
                          <Flex alignItems="center" color="#00B3FF" className="font40" component="span">{parseInt(drawData?.totalCount)-parseInt(drawData?.soldCount)}</Flex>
                         </Flex>
                    </StyleNUmber>
                 
               </Flex>
               <StyleCenterBox flex={1}  flexDirection="column">
                
                  {
                 
                    (new Array(dataLen)).fill("").map((value,idx)=>(
                      <Flex key={idx} flexDirection="column" mt={idx!=0?5:0}>
                         <Flex flex={1} justifyContent="space-around">
                            {(testData.slice(idx*page,idx*page+page)).map((item,index)=>(
                                <>
                                {index%2===0?
                                 <img className="boxStyle" src="./image/Draw/box1.png" alt=""/>
                                :
                                <img className="boxStyle" src="./image/Draw/box2.png" alt=""/>
                                 }
                                </>

                            ))}

                             {idx==(dataLen-1) && new Array(dataLen*page-testData.length).fill("").map(value=>(
                                  <Flex color="#fff" className="boxStyle" flexDirection="column" alignItems="center">
                                  </Flex>
                              ))}
                             
                        </Flex>
                        <Flex className="segmentation"></Flex>
                        <Flex color="#fff" flex={1} justifyContent="space-around">
                          {(testData.slice(idx*page,idx*page+page)).map((item,index)=>(
                              <Flex className="boxButtom" flexDirection="column" alignItems="center">
                                <Flex className="font24" mt={isDesktop?'8px':'8px'}>{t("BlindBox")} {tranIdDraw((idx*page)+index+1)}</Flex>
                                <Flex className="font18" alignItems="center" mt={isDesktop?'19px':'0.09rem'}>
                                  {/* <Flex>{showMoney(idx,page,index)!=0?"Reward:":"Price:"} */}
                                  
                                  {showMoney(idx,page,index)!=0?<Flex color="#FFA800">{t("Reward")}:</Flex>:<Flex>{t("Price")}:</Flex>}

                                  
                                  <Flex><img className="tokenStyle" src="./image/myFuzzle/beetoken.png" alt=""/></Flex>
                                  <Flex className="font32" color="#FFA800" component='span' >{showMoney(idx,page,index)!=0?tranPrice(showMoney(idx,page,index)?.money):drawData?.price}</Flex>
                                </Flex>
                                <Flex >
                                  {/* {showMoney(idx,page,index)}================= */}
                                  {
                                    // 1,2 
                                    // 5 6 7 8 9
                                    // 0,1,2,3,4
                                    // 存在奖励不能购买
                                    drawData?.soldCount&&
                                    (idx*page)+index==drawData.soldCount&&
                                    !isAward&&
                                    !dateEnd?
                                    getBtn():""  
                                  }
                               
                                 {showMoney(idx,page,index)!=0?
                                 <Flex flexDirection='column'>
                                  {/* <Flex>开出金额</Flex> */}
                                  
                                  <ShopBtn isMobile={!isDesktop}  display='flex' alignItems='center'>
                                     <Box color="#fff" component='span' onClick={()=>{handRecaption(showMoney(idx,page,index))}}>{t("RETRIEVE")}</Box>
                                  </ShopBtn>
                                  {/* <Flex justifyContent="center">{tranPrice(showMoney(idx,page,index).money)}</Flex> */}
                                 </Flex>
                                 :
                                  ""
                                 }
                                
                                </Flex>
                              </Flex>
                          ))}

                        {idx==(dataLen-1) && new Array(dataLen*page-testData.length).fill("").map(value=>(
                          <Flex className="boxStyle" flexDirection="column" alignItems="center">
                          </Flex>
                        ))}
                          {/* {new Array(Math.ceil(testData.length/6)*6-testData.length).fill("").map(value=>(
                              <Flex className="boxButtom" flexDirection="column" alignItems="center">
                               </Flex>
                          )} */}
                          
                          

                         </Flex>
                      </Flex>
                    ))
                  }
             

               </StyleCenterBox>
          </IceTop>

   

           <Flex mt={isDesktop?"64px":"0.54rem"}>
               <Box display='flex' color='#fff' flexDirection="column">
                   <Box component='h2'>{t("Draw Blind Boxes")}</Box>
                   <Box component='span'>{t("DrawDes_1")}</Box>
                   <Box mt={1} component='span'>{t("DrawDes_2")}</Box>
               </Box>
          </Flex>
      </Flex>

        {/* //modal */}
       <TipModael 
        successDes={successDes}
        erro={shopErro} 
        isopen={shopOpen}
        setOpens={setShopOpen}>
       </TipModael>
      
    </StylePanel>


      }
    
  </>
          
    
  );
};

export default DrawLots;




