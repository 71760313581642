import { useWallet } from '@binance-chain/bsc-use-wallet';
import { Box, Button, CircularProgress, Paper, Tab, Tabs, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils'
import IceOrWaterAbi from '../../../config/abi/IceOrWater.json';
import DoneAllSharpIcon from '@material-ui/icons/DoneAllSharp';
import useWeb3 from '../../../hooks/useWeb3';
import { getIceOrWaterAddress } from '../../../utils/addressHelpers';
import moment from "moment";


const FlexPanel = styled(Box)`
  display:flex;
`
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}


const NewBox: React.FC =()=>{
    const {account} = useWallet();
    const web3 = useWeb3();
    const [greenwayTime,setGreenwayTime] = useState("");
    const [pedding,setPedding] = useState(false);
    const [introObj,setIntroObj] = useState({
      zhTitle:"",
      zhConent:""
    });

    const [times,setTimes] = useState("");
    const [hash,setHash] = useState("");
    const [urlId,setUrlId] = useState("");
  
    const createHand=async ()=>{
          const item=[introObj.zhTitle,introObj.zhConent];
          // setIntro(item.join("||"));
          const introStr=item.join("||");
          
          setPedding(true)
          try {
            const myContract=new web3.eth.Contract((IceOrWaterAbi as unknown) as AbiItem,getIceOrWaterAddress());
            const res=await myContract.methods.createBox(times,introStr).send({from:account||""});
            console.log("结果",res);
            setHash(res.blockHash);
            setPedding(false);
            const resLen=await myContract.methods.getBoxCount().call();
            setUrlId(`${parseInt(resLen)-1}`)
            
          }catch(e){}
          
    }
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        var stime = new Date(event.target.value).getTime()
        const second=stime/1000;
        setTimes(`${second}`)
        // 1. 获取北京时间
        const currentDate = new Date(stime)
        // 2.获取格林威治时间
        // 先获取当前所在国家和格林威治时间之间的差值，默认是分钟数
        // 使用Date对象的getTimezoneOffset() 就可以获取到
        const difference = currentDate.getTimezoneOffset() * 60 * 1000 //将差异值转成毫秒值
        // 获取格林威治时间的毫秒值,用+ 的原因是因为如果是比格林威治时间快的地区，这个方法返回值
        //是一个负数，而慢的地区返回值是一个正数
        const GreenwichMillminutes = currentDate.getTime() + difference 
        // 利用这个毫秒值获取格林威治地区的日期对象实例 
        const GreenwichDate = new Date(GreenwichMillminutes)
        // console.log("格林威时间",moment(GreenwichDate).format('yyyy-MM-DD HH:mm:ss'));
        setGreenwayTime(moment(GreenwichDate).format('yyyy-MM-DD HH:mm:ss'));
    };
  return <Box display="flex" flex={1} flexDirection="column">
                 <FlexPanel flexDirection="column" style={{width:'50%'}}>

                     <TextField
                        id="datetime-local"
                        label="截至时间/By the end of time(beijing time)"
                        type="datetime-local"
                        defaultValue=""
                        onChange={handleChange}
                        InputLabelProps={{
                        shrink: true,
                        }}
                    />
                     <TextField
                        id="date"
                        label="格林威时间/Greenway time"
                        type="text"
                        value={greenwayTime}
                        disabled
                        InputLabelProps={{
                        shrink: true,
                        }}
                    />

                   <TextField 
                        id="standard-basic" 
                        label="盲盒名称/Name of Blind Box"
                        rows={4}
                        multiline
                        onChange={(e)=>{setIntroObj(pre=>({...pre,zhTitle:e.target.value}))}}
                   />
                   <TextField 
                        id="standard-basic" 
                        label="盲盒介绍/Blind box is introduced"
                        rows={4}
                        multiline
                        onChange={(e)=>{setIntroObj(pre=>({...pre,zhConent:e.target.value}))}}
                   />

          

          

                    <FlexPanel mt={2} alignItems="center">
                        <Button variant="contained" color="primary" onClick={createHand}>
                                创建/Create
                        </Button>
                        <Box ml={2} mt={2}></Box>
                        {pedding?<CircularProgress />:""}
                        {hash!==""?<DoneAllSharpIcon  style={{color:'#14b714'}}></DoneAllSharpIcon>:""}
                    </FlexPanel>
                    
                    <FlexPanel flexDirection="column">
                        <h3 style={{"marginBlockEnd":0}}>transaction hash:</h3>
                        <h4 style={{"marginBlockStart":0}}>{hash}</h4>
                    </FlexPanel>

                    {
                        hash!==""?
                        <FlexPanel flexDirection="column">
                            <h3 style={{"marginBlockEnd":0}}>盲盒链接/Blind box of link:</h3>
                            <h4 style={{"marginBlockStart":0,color:"#21ae21"}}>http://blindbox.cash/#/IceWater/{urlId}</h4>
                            {/* <h4 style={{"marginBlockStart":0}}>http://localhost:3000/#/DrawLots/{urlId}</h4> */}
                        </FlexPanel>
                        :""
                    }
                  </FlexPanel>
          </Box>

}

export default NewBox;