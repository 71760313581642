import { useWallet } from '@binance-chain/bsc-use-wallet';
import { BottomNavigation, BottomNavigationAction, Box, Button, CircularProgress, Paper, Tab, Tabs, TextField, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils'
import LucyDrawAbi from '../../../config/abi/LucyDraw.json';
import BeeTokenByteCode from '../../../config/bytecode/BeeTokenByteCode.json';

import nftAbi from '../../../config/abi/nft.json';
import addresses from '../../../config/contract/nft';
import Header from '../header';
import DoneAllSharpIcon from '@material-ui/icons/DoneAllSharp';
import useWeb3 from '../../../hooks/useWeb3';
import BigNumber from 'bignumber.js';
import { getDrawAddress, getIceOrWaterAddress } from '../../../utils/addressHelpers';
import { ethers } from 'ethers';
import { useDrawApprove } from './hooks/useDraw';
import { useBeeContract } from '../../../hooks/useContract';
import useTokenApprove from '../../../hooks/useApprove';
import { useDrawBoxHandlers } from '../../../state/DrawBox/hooks';
import moment from "moment";


const FlexPanel = styled(Box)`
  display:flex;
`
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}


const CreateBox: React.FC =()=>{
    const {account} = useWallet();
    const web3 = useWeb3();
    const [pedding,setPedding] = useState(false);
    const [contractAddr,setContractAddr] = useState("");
    const [LuckyDraw,setLuckyDraw] = useState({
        prizes:"1",
        decimals:18,
        price:"",
        totalCount:"",
        soldCount:0
    });

    const [times,setTimes] = useState("");
    const [hash,setHash] = useState("");
    const [urlId,setUrlId] = useState("");
    const [greenwayTime,setGreenwayTime] = useState("");

    const createHand=async ()=>{
          if(parseInt(LuckyDraw.totalCount)>50){
             alert("总数必须小于50个/The total must be less than 50")
             return;
          }
         
          try {
            // console.log(new BigNumber("1268890959302052602856582680581").div(new BigNumber(0)))
            let ret:any=LuckyDraw.prizes.split("|");
            let prizesRes:any="";
            if(ret.length<2){
                alert("签数必须大于2个/The number of signatures must be greater than two")
                return;
            }

            setPedding(true);
            ret=ret.filter((t:any)=>t!="");
            prizesRes=new BigNumber(0);
            for (var i = 0; i<ret.length; i++) {
                let item=ret[i].split(",")
                item=item.filter((t:any)=>t!="");
                if(item.length==2){
                    item[1]=`${parseInt(item[1])*10}` ;
                    console.log("概率",item[1]);
                    prizesRes = ((prizesRes.times(new BigNumber(1024))).plus(new BigNumber(item[0]))).times(new BigNumber(1024)).plus(new BigNumber(item[1]))
                }
            }

            console.log(prizesRes.toFixed())
            const LuckyDraws={...LuckyDraw,prizes:prizesRes.toFixed()};
            // 1,100|1,100|1,100|1,100|1,100|1,100|1,100|1,100|1,100|1,100

            
            const myContract=new web3.eth.Contract((LucyDrawAbi as unknown) as AbiItem,getDrawAddress());
            const res=await myContract.methods.startLuckyDraw([LuckyDraws.prizes,LuckyDraws.decimals,parseInt(LuckyDraws.price),LuckyDraws.totalCount,LuckyDraws.soldCount],times)
            .send({from:account||""});

            console.log("结果",res)
            setHash(res.blockHash)
            setPedding(false)

            const accountStr=Web3.utils.hexToNumberString(account||"");
            const urlId=new BigNumber(accountStr).times(new BigNumber(2).pow(96)).plus(times);
            setUrlId(urlId.toFixed());
          }catch(e){}
    }
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            let res:any=event.target.value.split("|");
            setLuckyDraw(pre=>({...pre,prizes:event.target.value}));
            // setLuckyDraw(pre=>({...pre,prizes:prizesRes.toFixed()}));
    };
    const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        var stime = new Date(event.target.value).getTime()
        const second=stime/1000;
        setTimes(`${second}`)
        
        // 1. 获取北京时间
        const currentDate = new Date(stime)
        // 2.获取格林威治时间
        // 先获取当前所在国家和格林威治时间之间的差值，默认是分钟数
        // 使用Date对象的getTimezoneOffset() 就可以获取到
        const difference = currentDate.getTimezoneOffset() * 60 * 1000 //将差异值转成毫秒值
        // 获取格林威治时间的毫秒值,用+ 的原因是因为如果是比格林威治时间快的地区，这个方法返回值
        //是一个负数，而慢的地区返回值是一个正数
        const GreenwichMillminutes = currentDate.getTime() + difference 
        // 利用这个毫秒值获取格林威治地区的日期对象实例 
        const GreenwichDate = new Date(GreenwichMillminutes)
        // console.log("格林威时间",moment(GreenwichDate).format('yyyy-MM-DD HH:mm:ss'));
        setGreenwayTime(moment(GreenwichDate).format('yyyy-MM-DD HH:mm:ss'));
    };
    
    // 授权
    const {isApprove}=useDrawApprove();
    const tokenContract=useBeeContract();
    const onApprove=useTokenApprove(tokenContract,getDrawAddress());
    const {onIsRefresh}=useDrawBoxHandlers();
    const roladList=()=>{
      onIsRefresh(true)
      setTimeout(()=>{onIsRefresh(false)},1000)
    }
    const handleApprove = useCallback(async () => {
        try {
            const txHash = await onApprove();
            roladList();
        } catch (e) {
            console.error(e)
        }
    }, [onApprove])



    return <Box display="flex" flex={1} flexDirection="column">
                 <FlexPanel flexDirection="column" style={{width:'50%'}}>
                  
                   <TextField
                        id="prizes"
                        label="金额1,概率2(100)|金额2,概率2(100)/amount 1,probability 100| amount 1, probability 100"
                        type="prizes"
                        rows={4}
                        multiline
                        defaultValue=""
                        onChange={handleChange}
                    />
                    
                    <TextField
                        id="price"
                        label="盲盒价格/Blind box price"
                        type="price"
                        defaultValue=""
                        onChange={(e)=>{setLuckyDraw(pre=>({...pre,price:e.target.value}))}}
                        InputLabelProps={{
                        shrink: true,
                        }}
                    />

                    <TextField
                        id="price"
                        label="总量/total quantity"
                        type="number"
                        placeholder='1-50'
                        defaultValue=""
                        onChange={(e)=>{setLuckyDraw(pre=>({...pre,totalCount:e.target.value}))}}
                        InputLabelProps={{
                        shrink: true,
                        }}
                    />


                    <TextField
                        id="datetime-local"
                        label="截至时间/By the end of time(Beijing time)"
                        type="datetime-local"
                        defaultValue=""
                        onChange={handleTimeChange}
                        InputLabelProps={{
                        shrink: true,
                        }}
                    />

                    <TextField
                        id="date"
                        label="格林威时间/Greenway time"
                        type="text"
                        value={greenwayTime}
                        disabled
                        InputLabelProps={{
                        shrink: true,
                        }}
                    />
                    

          

                    <FlexPanel mt={2} alignItems="center">
                        {
                            !isApprove?
                            <Button variant="contained" color="primary" onClick={handleApprove}>
                                授权/approve
                            </Button>
                            : 
                            <Button variant="contained" color="primary" onClick={createHand}>
                               创建/create
                            </Button>
                        }
                        
                       
                        <Box ml={2} mt={2}></Box>
                        {pedding?<CircularProgress />:""}
                        {hash!==""?<DoneAllSharpIcon  style={{color:'#14b714'}}></DoneAllSharpIcon>:""}
                    </FlexPanel>
                    
                    <FlexPanel flexDirection="column">
                        <h3 style={{"marginBlockEnd":0}}>transaction hash:</h3>
                        <h4 style={{"marginBlockStart":0}}>{hash}</h4>
                    </FlexPanel>
                    {
                        hash!==""?
                        <FlexPanel flexDirection="column">
                            <h3 style={{"marginBlockEnd":0}}>盲盒链接/Blind box of link:</h3>
                            <h4 style={{"marginBlockStart":0,color:"#21ae21"}}>http://blindbox.cash/#/DrawLots/{urlId}</h4>
                            {/* <h4 style={{"marginBlockStart":0}}>http://localhost:3000/#/DrawLots/{urlId}</h4> */}
                        </FlexPanel>
                        :""
                    }
                   

                    </FlexPanel>
          </Box>

}

export default CreateBox;