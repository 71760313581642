const add0 = (m:number) => {
    return m < 10 ? '0' + m : m;
}
// 将时间戳转换成日期
const formatDate = (timeStamp:number) => {
    let time = new Date(timeStamp),
        y = time.getFullYear(),
        m = time.getMonth() + 1,
        d = time.getDate(),
        h = time.getHours(),
        mm = time.getMinutes(),
        s = time.getSeconds();

    return y + '-' + add0(m) + '-' + add0(d) + ' ' + add0(h) + ':' + add0(mm) + ':' + add0(s);
}
export default formatDate