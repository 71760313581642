// import random from 'lodash/random'

// Array of available nodes to connect to
const nodes = [process.env.REACT_APP_NODE_1, process.env.REACT_APP_NODE_2, process.env.REACT_APP_NODE_3]

const backups = {
  '1': process.env.REACT_APP_NODE_BACKUP1,
  '2': process.env.REACT_APP_NODE_BACKUP2,
  '3': process.env.REACT_APP_NODE_BACKUP3,
  '4': process.env.REACT_APP_NODE_BACKUP4
}

const getNodeUrl = () => {
  // const q = getOverride();
  // if (q && backups[q]) return backups[q];

  // const randomIndex = random(0, nodes.length - 1);
  return nodes[0];
}

const getOverride = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('rpcnode');
}

export default getNodeUrl
