import React, { lazy } from "react";
// import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Layout from "./views/home/Layouts";
import "./style/flex.css";

import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
} from "@material-ui/core";
import RestoreIcon from "@material-ui/icons/Restore";
import FavoriteIcon from "@material-ui/icons/Favorite";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import styled from "styled-components";
import ButtonAppBar from "./views/home/ButtonAppBar";
import MyInfo from "./views/myInfo/myInfo";
import RounterHome from "./views/home/RouterHome";
import MintPage from "./views/mint/mintPage";
// import Test from './views/myInfo/test';
import './i18n';
import OwerPage from "./views/OwerPage/OwerPage";
import BeenManagement from "./views/OwerPage/BeenManagement/BeenManagement";
import BlindBoxManagement from "./views/OwerPage/BlindBoxManagement/BlindBoxManagement";
import Puzzle from "./views/OwerPage/Puzzle/Puzzle";
import Home from "./views/home/homes";
import PuzzleGame from "./views/puzzle/PuzzleGame";
import PuzzleMangent from "./views/OwerPage/Puzzle/PuzzleMangent";
import IceWater from "./views/IceWater/IceWater";
import DrawLots from "./views/DrawLots/DrawLots";
import Liquidity from "./views/OwerPage/Liquidity/Liquidity";
import Swap from "./views/Swap/swap";
import DrawMangement from "./views/OwerPage/DrawManagement/DrawMangement";
import BigNumber from "bignumber.js";


const Center = styled.html`
  @media (max-width: 900px) {
    font-size:12px;
  }
  @media (min-width: 900px) {
    font-size:16px;
  }
`;

const Font = styled(Box)`
  display: flex;
  flex: 1;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
`;
// BigNumber.config({
//   EXPONENTIAL_AT: 1000,
//   DECIMAL_PLACES: 80,
// })

const App: React.FC = () => {
  return (
   
    <Font>
        <Routes>
          {/* <Route path="/" element={<RounterHome />}>
            <Route path="/" element={<Homes />} />
            <Route path="home" element={<Homes />} />
            <Route path="mint" element={<MintPage />}></Route>
            <Route path="myInfo" element={<MyInfo />}></Route>
          </Route> */}
          <Route path="/" element={<Layout />} >
            <Route path="/" element={<Home />} />
            <Route path="/puzzle" element={<PuzzleGame />} />
            <Route path="/IceWater/:id" element={<IceWater />} />
            <Route path="/IceWater" element={<IceWater />} />
            <Route path="/DrawLots/:id" element={<DrawLots />} />
            <Route path="/DrawLots" element={<DrawLots />} />
            <Route path="/Swap" element={<Swap />} />
            
          </Route>

          <Route path="/owner" element={<OwerPage />}>
            <Route path="/owner/" element={<BeenManagement />} />
            <Route path="/owner/BlindBoxManagement" element={<BlindBoxManagement />} />
            <Route path="/owner/PuzzleMangent" element={<PuzzleMangent />} />
            <Route path="/owner/Liquidity" element={<Liquidity />} />
            <Route path="/owner/DrawMangement" element={<DrawMangement />} />
            
          </Route>

        </Routes>
      </Font>
   
 
  );
};

export default App;
