import { Box, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useMatchBreakpoints from "../../hooks/useMatchBreakpoints";
import { useTranslation } from "react-i18next";
import ListTable from "./ListTable";
import Carousel from "nuka-carousel";
import AwesomeSlider from "react-awesome-slider";
import 'react-awesome-slider/dist/styles.css';
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import Banner from "./Banners";
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const BoxPointer = styled(Box)<any>`
 cursor: pointer;
`;

const StylePanel=styled(Box)`
      height: 80px;
      @media (max-width: 750px) {
      .page{
        svg{
          margin-left: 0.05rem;
          width:0.1rem;
          height:0.1rem;
        }
       }
      } 

      @media (min-width:750px){
        .page{
            svg{
              margin-left: 5px;
              width:10px;
              height:10px;
            }
        }
      }

   


`

const ShowImg = styled.img<{isMobile:boolean}>`
  height:${(props) => (props.isMobile?'3.08rem':'320px')} ;
`;


const FontPanel = styled(Box)<any>`
      @media (max-width: 750px) {
          font-size: 0.18rem;
      }

      /* @media (min-width:750px){
          height: 0.36rem;
          width: 1.08rem;
      } */
`;

const MoreBtn = styled(Box)<any>`
      cursor: pointer;

      /* @media (max-width: 500px) {
        .iconSize{
           font-size: 12px;
        }
      } */
      @media (max-width: 750px) {
        .iconSize{
           font-size: 14px;
        }
      }

     
`;


const Home: React.FC = () => {
  const { isDesktop, isMobile } = useMatchBreakpoints();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showDes,setShowDes] = useState(false);
  return (
    <StylePanel mb={5}  display="flex" flex={1} flexDirection='column'>
   

    





        <Box    style={{height:isDesktop?"20rem":"3.08rem"}} flex={1}>
          {
            isDesktop?<Carousel 
            style={{height:isDesktop?"20rem":"3.08rem"}}
            autoplay
            wrapAround
            adaptiveHeight
            defaultControlsConfig={{
              pagingDotsContainerClassName:"page",
              pagingDotsClassName:"page",
              nextButtonText:<></>,
              prevButtonText:<></>,
              prevButtonStyle:{
                display: 'none'
              },
              nextButtonStyle: {
                display: 'none'
              }

            }}>
                <ShowImg src="./image/lubo1.png" isMobile={!isDesktop} width="100%" alt=""  />
                <ShowImg src="./image/lubo2.png" isMobile={!isDesktop} width="100%" alt=""  />
                <ShowImg src="./image/lubo3.png" isMobile={!isDesktop} width="100%" alt=""  />
                <ShowImg src="./image/lubo4.png" isMobile={!isDesktop} width="100%" alt=""  />
            </Carousel>
            :
            <Carousel 
            style={{height:isDesktop?"20rem":"3.08rem"}}
            autoplay
            wrapAround
            adaptiveHeight
            defaultControlsConfig={{
              pagingDotsContainerClassName:"page",
              pagingDotsClassName:"page",
              nextButtonText:<></>,
              prevButtonText:<></>,
              prevButtonStyle:{
                display: 'none'
              },
              nextButtonStyle: {
                display: 'none'
              }

            }}>

                      <ShowImg src="./image/750/lubo1.png" isMobile={!isDesktop} width="100%" alt=""  />
                      <ShowImg src="./image/750/lubo2.png" isMobile={!isDesktop} width="100%" alt=""  />
                      <ShowImg src="./image/750/lubo3.png" isMobile={!isDesktop} width="100%" alt=""  />
                      <ShowImg src="./image/750/lubo4.png" isMobile={!isDesktop} width="100%" alt=""  />
            </Carousel>
          }
             
             


        </Box>
        <Box 
         display="flex"
         flexDirection='row' 
         style={{height:isDesktop?"300px":"1.8rem"}}>
            <BoxPointer display="flex" flex={1} onClick={()=>{
              navigate("/puzzle")
            }}>
                <img src={`./image/${!isDesktop?"750/":""}pintu.png`} alt="" width="100%" height="100%"  />
             </BoxPointer>
             <BoxPointer display="flex" flex={1} ml={isDesktop?6:3} onClick={()=>{
              navigate("/IceWater")
             }}>
               <img src={`./image/${!isDesktop?"750/":""}binshui.png`}  alt="" width="100%" height="100%" />
            </BoxPointer>
            
        </Box>
        <Box 
          mt={isDesktop?3:'0.2rem'}
          display="flex"
          flexDirection='row' 
          style={{height:isDesktop?"300px":"1.8rem"}}>
          <BoxPointer display="flex" flex={1} onClick={()=>{
              navigate("/DrawLots")
             }}>
               <img src={`./image/${!isDesktop?"750/":""}Draw.png`}  alt="" width="100%" height="100%" />
           </BoxPointer>
           <BoxPointer display="flex" flex={1} ml={isDesktop?6:3}>
           </BoxPointer>
         </Box>
        <Box mt={isDesktop?5:2}>
          <ListTable></ListTable>
        </Box>

        <Box mt={isDesktop?"100px":"0.5rem"}>
          <Banner></Banner>
        </Box>
       {/* 11111111111111111111
        <MainBody></MainBody> */}

        <FontPanel display='flex' mt={isDesktop?10:5} flexDirection="column">
          <Box display='flex' color='#fff' flexDirection="column">
              <Box component='h3'>{t("We are not just a game site, acommunity,but also a social experiment")}</Box>
              <Box component='span'>{t("Wepage_des")}</Box>
             
           </Box>
            
            {
              showDes?<>
            <Box mt={1} display='flex' color='#fff' flexDirection="column">
              <Box component='h3'>{t("What is BEE")}?</Box>
              <Box component='span'>{t("BEE des")}</Box>
            </Box>
         
           <Box mt={1} display='flex' color='#fff' flexDirection="column">
              <Box component='h3'>{t("Jigsaw Blind Box")}</Box>
              <Box component='span'>{t("Jigsaw Blind Box des")}</Box>
           </Box>

           <Box mt={1} display='flex' color='#fff' flexDirection="column">
              <Box component='h3'>{t("Ice Water Blind Box")}</Box>
              <Box component='span'>{t("Ice Water Blind Box des")}</Box>
           </Box>
              </>
              :""
            }
            <MoreBtn color={"#fff"}  pt={1} display={'flex'} alignItems="center" onClick={()=>{setShowDes(!showDes)}} component='span'>
                {t("show more")}
                <Box display={'flex'}   component='span' >
                  {showDes?<ArrowDropUpIcon className="iconSize"></ArrowDropUpIcon >:<ArrowDropDownIcon className="iconSize"></ArrowDropDownIcon>}
                </Box>
            </MoreBtn>
        </FontPanel>
    </StylePanel>
    
  );
};

export default Home;
