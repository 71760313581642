 const   setupNetwork = async () => {
    const provider = window.ethereum;
    if (provider) {
      // bch chainId 10000 转 16进制
      const chainId = (10000).toString(16);
      try {
          await provider.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: `0x${chainId}` }],
          });
          return true;
      }catch (switchError:any) {

        if (switchError.code === 4902) {
          try {
            await provider.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: `0x${chainId}`,
                  chainName: 'Smart Bitcoin Cash',
                  nativeCurrency: {
                    name: 'BCH',
                    symbol: 'bch',
                    decimals: 18,
                  },
                  rpcUrls: ["https://global.uat.cash/"],
                  blockExplorerUrls: [`https://www.smartscan.cash/`],
                },
              ],
            })
            return true
          } catch (error) {
            console.error('Failed to setup the network in Metamask:', error)
            return false
          }
        }


      }

   
     
    } else {
      console.error("Can't setup the BSC network on metamask because window.ethereum is undefined")
      return false
    }
  }

export default setupNetwork;