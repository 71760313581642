import BigNumber from "bignumber.js";

const tranId=(str:any)=>{
    str ='00'+str;   
    return str.substring(str.length-3,str.length);
}
export const tranIdDraw=(str:any)=>{
    str ='0'+str;   
    return str.substring(str.length-2,str.length);
}
export const tranPrice=(priceOfBox:string)=>{
    return (new BigNumber(priceOfBox?priceOfBox:0).div(new BigNumber(10).pow(18))).toFixed();
}
export const tranTotalPrice=(a:string,b:string)=>{
    const total=new BigNumber(a?a:0).plus(new BigNumber(b?b:0));
    return (total.div(new BigNumber(10).pow(18))).toFixed();
}
export default tranId