import React, { useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Box, Divider, MenuItem, Select, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';


const StyleBox = styled(Box)`
    padding: 2px 40px 2px 20px;
    color: #fefefe;
    /* border: 1px solid #0a4ca1; */
    background: black;
`;
const StyleSelect = styled(Select)`
    color: #ffffff !important;
    border: 1px solid rgb(100 200 50);
    input{
      color: #ffffff !important;
    }
`;

const StyleBoxBtn = styled(Box)`
    @media (max-width: 750px) {
       height: 0.48rem;
       width: 1.2rem ;
       font-size: 0.21rem;
       .icon{
        font-size: 0.4rem;
       }
    }

    @media (min-width:750px){
      width:64px;
      height:28px;
    }
   
    background: #00204B;
    color: #ffffff !important;
    display:flex;
    justify-content:center;
    align-items:center ;
    cursor: pointer;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography: {
      padding: theme.spacing(2),
    },
  }),
);

const StylePopover = styled(Popover)`
  .MuiPopover-root{
   background-color: black;
  }
  .MuiPopover-paper{
    background-color: black;
  }
  :focus-visible{
    outline:none !important;
    border:0px !important;
  }
`;
const I18nPopover: React.FC =()=> {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const { t, i18n } = useTranslation();
  const [i18nVal,setI18nVal]=useState('En');
  const lngs:{
      en:{nativeName:string},
      zh:{nativeName:string}
  } = {
    en: { nativeName: "En" },
    zh: { nativeName: "Zh" },
  };
  const handleChange=()=>{

  }
  return (
    <Box pt={2} pb={2}>
       {/* <StyleSelect
          value={""}
          onChange={handleChange}
          displayEmpty
          // className={classes.selectEmpty}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>

      </StyleSelect> */}


      <StyleBoxBtn  onClick={handleClick}>
        <span>{i18nVal}</span>
        <ArrowDropDownIcon className='icon'></ArrowDropDownIcon>
      </StyleBoxBtn>

      {/* <Button 
      aria-describedby={id} 
      variant="outlined" 
      color="secondary" 
      onClick={handleClick}>
        {i18nVal}
        <ArrowDropDownIcon></ArrowDropDownIcon>
      </Button> */}
      <StylePopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
      >
       <Box flex={1}>
         {Object.keys(lngs).map((lng:string,index) => (<>
         <StyleBox 
        
         style={{'cursor':'pointer','fontSize': '18px'}}
         flex={1} 
         onClick={() => {
            setI18nVal((lngs as any)[lng].nativeName);
            i18n.changeLanguage(lng);
            handleClose()
         }}>
           {(lngs as any)[lng].nativeName}
            {/* <button
                key={lng}
                style={{
                    fontWeight: i18n.resolvedLanguage === lng ? "bold" : "normal",
                }}
                type="submit"
                onClick={() => {
                    setI18nVal(lng);
                    i18n.changeLanguage(lng);
                }}
                >
                {(lngs as any)[lng].nativeName}
                </button> */}
        </StyleBox>
        </>
    
        
       
         
        ))}
       </Box>
       
      </StylePopover>
    </Box>
  );
}
export default I18nPopover;