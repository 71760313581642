import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import TwitterIcon from "@material-ui/icons/Twitter";
import TelegramIcon from "@material-ui/icons/Telegram";
import EmailIcon from "@material-ui/icons/Email";
import useMatchBreakpoints from "../../hooks/useMatchBreakpoints";
import { useTranslation } from "react-i18next";
import Header from "./header";
import Footer from "./Footer";
import Main from "./Main";
import { Outlet } from "react-router-dom";

const GameImg = styled(Box)<any>`
  background-image: url(/image/完整.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
  border-radius: 10px;
`;

const BoxFont = styled(Box)`
  span {
    font-size: 52px;
    font-weight: bold;
  }
`;
const BoxImg = styled(Box)`
  min-width: 312px;
  min-height: 200px;
`;
const BoxTwoImg = styled(Box)<{ isMobile?: boolean }>`
  // height: 385px;
  min-width: 312px;
  margin-left: ${(props) => (props.isMobile ? "0px" : "75px")};
  margin-top: -75px;
`;
const BoxTop = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  border-bottom: 1px solid #4d4a4a;
`;
const BoxGame = styled(Box)`
  background: #2a2a2a;
  padding: 80px 0;
`;

const BoxButton = styled(Box)`
  height: 50px;
  background: #64c832;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 0px 50px 0px 50px;
  border-radius: 10px;
  margin-left: 20px;
  margin-right: 20px;
  white-space: nowrap;
  justify-content: center;
`;
const RoleBox = styled(Box)`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 200px;
  padding-bottom: 20px;
`;

const Container = styled(Box)<{ isMobile?: boolean }>`
      @media (max-width: 750px) {
        padding:0.24rem 0.4rem 0.24rem 0.4rem
      }

      @media (min-width:750px){
        padding:15px 103px 0px 103px;;
      }
     background-color: #061E3E;
     /* padding:${(props) => (props.isMobile ? "1rem 1.67rem 0px 1.67rem;" : "24px 160px 0px 160px;")}; */
`;

// const BoxTwoImg = styled(Box)<{ isMobile?: boolean }>`
//   // height: 385px;
//   min-width: 312px;
//   margin-left: ${(props) => (props.isMobile ? "0px" : "75px")};
//   margin-top: -75px;
// `;
// const Footer=styled(Box)`
//      height: 80px;
//      background-color: #001634;
// `

const Layout: React.FC = () => {
  const { isDesktop, isMobile } = useMatchBreakpoints();
  const { t, i18n } = useTranslation();
  return (
    <Box display="flex" flex={1} flexDirection="column">
     {/* <Box display="flex" style={{width:"100%"}}>
       <Header></Header>
     </Box> */}
     <Header></Header>
     {/* <Main></Main> */}
     <Container pb={2} isMobile={!isDesktop}   display="flex" flex={1} flexDirection="column">
      <Outlet />
     </Container>

    
     <Footer></Footer>
      
     
     
    
      {/* <BoxTop display="flex" flexWrap="wrap" flex={1}>
        <BoxImg display="flex" flex={1} flexDirection="column">
          <img src="./image/火.png" alt="" width="100%" height="100%" />
        </BoxImg>
        <BoxFont
          display="flex"
          flex={1}
          justifyContent="center"
          flexDirection="column"
        >
          <span>
            {t("Explore the future direction of chain GAME GECKO GAME era")}
          </span>
          <Box display="flex" mt={10}>
            <TwitterIcon />
            <Box ml={2}>
              <TelegramIcon />
            </Box>
            <Box ml={2}>
              <EmailIcon />
            </Box>
          </Box>
        </BoxFont>
      </BoxTop>

      <Box display="flex" flex={1} flexWrap="wrap">
        <Box minWidth="300px" display="flex" flex={1} flexDirection="column">
          <h1>{t("About GECKO")}</h1>
          <p>{t("DNAxCAT 1")} </p>
          <p>{t("DNAxCAT 2")}</p>
        </Box>
        <BoxTwoImg isMobile={isMobile} display="flex" flex={1}>
          <img src="./image/木.png" alt="" width="100%" height="100%" />
        </BoxTwoImg>
      </Box>

      <BoxGame
        display="flex"
        flex={1}
        flexWrap="wrap"
        alignItems="center"
        flexDirection="column"
      >
        <h1>{t("Game characters")}</h1>

        <Box display="flex" flex={1} flexWrap="wrap">
          <RoleBox>
            <BoxButton alignItems="center">{t("Game characters 1")}</BoxButton>
            <img src="./image/雷.png" width="100%" alt="" />
            <Box display="flex" justifyContent="center">
              {t("Game characters 2")}
            </Box>
            <Box display="flex" justifyContent="center">
              {t("Game characters 3")}
            </Box>
          </RoleBox>

          <RoleBox>
            <BoxButton alignItems="center">{t("Fire single attack")}</BoxButton>
            <img src="./image/火.png" width="100%" alt="" />
            <Box display="flex" justifyContent="center">
              {t("Fire single attack 1")}
            </Box>
            <Box display="flex" justifyContent="center">
              {t("Fire single attack 2")}
            </Box>
          </RoleBox>

          <RoleBox>
            <BoxButton alignItems="center">{t("Woody treatment")}</BoxButton>
            <img src="./image/木.png" width="100%" alt="" />
            <Box display="flex" justifyContent="center">
              {t("Woody treatment 1")}
            </Box>
            <Box display="flex" justifyContent="center">
              {t("Woody treatment 2")}
            </Box>
          </RoleBox>

          <RoleBox>
            <BoxButton alignItems="center">
              {t("Water system assistance")}
            </BoxButton>
            <img src="./image/水.png" width="100%" alt="" />
            <Box display="flex" justifyContent="center">
              {t("Water system assistance 1")}
            </Box>
            <Box display="flex" justifyContent="center">
              {t("Water system assistance 2")}
            </Box>
          </RoleBox>
        </Box>
      </BoxGame> */}
    </Box>
  );
};

export default Layout;
