import { Box, Button, createStyles, Fade, makeStyles, Modal, Theme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
// import useMatchBreakpoints from "../hooks/useMatchBreakpoints";
import { useTranslation } from "react-i18next";
import CloseIcon from '@material-ui/icons/Close';
import useMatchBreakpoints from "../hooks/useMatchBreakpoints";

const Center = styled(Box)<any>`
  background-image: url(/image/新建项目.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
  border-radius: 10px;
`;

const StylePanel=styled(Box)`
     /* height: 80px; */
     background-color: #001634;
     background-image: url('/image/myFuzzle/myback.png');
     background-size:100% 100%;
     padding:37px 40px 37px 40px;
`

const StyleModal=styled(Box)`
  padding:0px 16px 0px 38px;
`
const Flex=styled(Box)`
  display: flex;
`

const RightLog=styled(Flex)`
  height: 20px;
  width: 6px;
  background: #0058cd;
`
const RightLogText=styled(Flex)`
    font-size: 24px;
    font-family: SourceHanSansCN, SourceHanSansCN-Heavy;
    font-weight: 800;
    color: #fff;
`
const ModalConent=styled(Flex)<{isMobile:boolean}>`
      position: absolute;
      width: ${(props) => (props.isMobile?"89%":"50%")};
      /* height:480px; */
      border: '2px solid #000';
      box-shadow: 5px;
      outline:0;
      // padding: theme.spacing(2, 4, 3),
      background-color:#061E3E;
      font-size: ${(props) => (props.isMobile?"12px":"")};
      .fontSizeMedal{
        font-size: ${(props) => (props.isMobile?"0.18rem":"18px")};
      }
      .font24SizeMedal{
        font-size: ${(props) => (props.isMobile?"0.24rem":"24px")};
      }
      .closeImg{
        width:${(props) => (props.isMobile?"0.24rem":"24px")};
        height:${(props) => (props.isMobile?"0.24rem":"24px")};
      }
`

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
     
    },
    paper: {
      position: 'absolute',
      minWidth:'375px',
      // width: "50%",
      // height:"480px",
      // backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      outline:0,
      padding: theme.spacing(2, 4, 8),
      backgroundColor: "#061E3E"
    },
  }),
);
interface ImgInfoModel{
  erro: string
  isopen:boolean
  setOpens:(e: any)=>void
  successDes?:string
}


const TipModael: React.FC<ImgInfoModel> = ({erro,isopen,setOpens,successDes}) => {
  const { isDesktop, isMobile } = useMatchBreakpoints();
  const { t } = useTranslation();
  const classes = useStyles();
  // const handImg=()={}
  const [open,setOpen]=useState(isopen);



  const handleOpen=()=>{
       setOpen(!open)
  }
  const handleClose = () => {
    setOpen(false);
  };
  
  return (<Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isopen}
        onClose={handleClose}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isopen}>
          <ModalConent pb={5}  isMobile={!isDesktop}>
            <Box display="flex" flex={1} flexDirection="column">
              <Box style={{cursor: 'pointer'}} onClick={()=>setOpens(false)} mt={2} mr={2} display='flex' alignItems='center' justifyContent='right'>
                <img  className="closeImg" src="./image/close.png"/>
                <Box ml={1} className="font24SizeMedal" component='span' color="#fff"  flex={'display'}>{t("CLOSE")}</Box>
              </Box>
              <StyleModal pt={5}  display='flex' flex={1} alignItems='center' justifyContent="center">
                <Box   display='flex' >
                  <Box fontSize="18px" fontWeight='bold' color={!erro?"#3fdb3f":"red"} display="flex" >
                     {successDes&&!erro?t(`${successDes}`):erro}
                     {!successDes&&!erro?t('shop success'):erro}
                  </Box>
                </Box>
              </StyleModal>
          </Box>
            
          </ModalConent>
        </Fade>
      </Modal>
  
  );
};

export default TipModael;
