import React from 'react'
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core'
import { Provider } from 'react-redux'
import store from './state'


const Providers: React.FC = ({ children }) => {
  return (
        <Provider store={store}>
          {children}
        </Provider>
  )
}

export default Providers
