
import BigNumber from 'bignumber.js';
import { useEffect, useState } from 'react'
import { useIceContract} from '../../../hooks/useContract'


export  function useIcehistoryList():{IcehistoryList:Array<any>}{
    const [IcehistoryList, setIceHistoryList] = useState<any[]>([])
    const IceContract=useIceContract();
    
    useEffect(() => {
      const fetch = async () => {
        try {
            const res=await IceContract.methods.getBoxCount().call();
            let DepositList= await IceContract.getPastEvents('Deposit', {
                fromBlock: 0,
                toBlock: 'latest',
                filter:{id:parseInt(res)-1}
            })
            console.log(DepositList)
            DepositList=DepositList.sort((a,b)=>{ 
              return (new BigNumber(b.returnValues.amount).minus(new BigNumber(a.returnValues.amount))).toNumber()
            })
            setIceHistoryList(DepositList.map(t=>({...t.returnValues,blockNumber:t.blockNumber})))
        } catch (error) {
          
        }

      }
  
      if (!IcehistoryList || !IcehistoryList.length) fetch()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setIceHistoryList])

    return {IcehistoryList}
}
