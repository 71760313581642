import { useWallet } from '@binance-chain/bsc-use-wallet';
import { BottomNavigation, BottomNavigationAction, Box, Button, CircularProgress, Paper, Tab, Tabs, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils'
import BeeTokenAbi from '../../../config/abi/BeeToken.json';
import BeeTokenByteCode from '../../../config/bytecode/BeeTokenByteCode.json';

import nftAbi from '../../../config/abi/nft.json';
import addresses from '../../../config/contract/nft';
import Header from '../header';
import DoneAllSharpIcon from '@material-ui/icons/DoneAllSharp';
import useWeb3 from '../../../hooks/useWeb3';
import BigNumber from 'bignumber.js';



const FlexPanel = styled(Box)`
  display:flex;
`
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}


const MintPanel: React.FC =()=>{
    const {account} = useWallet();
    const web3 = useWeb3();
    
    const [pedding,setPedding] = useState(false);
    const [contractAddr,setContractAddr] = useState("");
    const [targetAddress,setAddress] = useState("");
    const [targetNum,setNum] = useState("");
    const [hash,setHash] = useState("");
 
  
    const mintHand=async ()=>{
          setPedding(true)
          try {
            const targetAmount=new BigNumber(targetNum).times(new BigNumber(10).pow(18)).toFixed(0)
            const myContract=new web3.eth.Contract((BeeTokenAbi as unknown) as AbiItem,contractAddr);
            const res=await myContract.methods.mint(targetAddress,targetAmount).send({from:account||""});
            console.log("结果",res)
            setHash(res.blockHash)
            setPedding(false)
          }catch(e){}
          
    }

  return <Box display="flex" flex={1} flexDirection="column">
                 <FlexPanel flexDirection="column" style={{width:'50%'}}>
                    <TextField 
                    id="standard-basic" 
                    label="BEENTOKEN 合约地址"
                    onChange={(e)=>{setContractAddr(e.target.value)}}
                    />

                   <TextField 
                    id="standard-basic" 
                    label="目标地址"
                    onChange={(e)=>{setAddress(e.target.value)}}
                    />

                    <TextField 
                    id="standard-basic" 
                    label="数量"
                    onChange={(e)=>{setNum(e.target.value)}}
                    />

                    <FlexPanel mt={2} alignItems="center">
                        <Button variant="contained" color="primary" onClick={mintHand}>
                                铸造
                        </Button>
                        <Box ml={2} mt={2}></Box>
                        {pedding?<CircularProgress />:""}
                        {hash!==""?<DoneAllSharpIcon  style={{color:'#14b714'}}></DoneAllSharpIcon>:""}
                    </FlexPanel>
                    
                    <FlexPanel flexDirection="column">
                        <h3 style={{"marginBlockEnd":0}}>交易hash:</h3>
                        <h4 style={{"marginBlockStart":0}}>{hash}</h4>
                    </FlexPanel>
                  </FlexPanel>
          </Box>

}

export default MintPanel;