import { Box, Button, Collapse, Popover } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useMatchBreakpoints from "../../hooks/useMatchBreakpoints";
import { useTranslation } from "react-i18next";
import { Link,useNavigate  } from "react-router-dom";

// import ownerList from "../../../public/owner.json";
import { useWallet } from "@binance-chain/bsc-use-wallet";
const StyleBox = styled(Box)`
    padding: 10px 140px 10px 20px;
    color: #fefefe; 
    border: 1px solid #061528 !important;
    background: black;
    cursor: pointer;
    :hover{
        background-color: #0a2547;
    }
    :focus-visible{
        outline:none !important;
    }
`;

const MenuLink = styled(Link)`
     @media (max-width: 750px) {
        font-size: 14px;
     }

     @media (min-width:750px){
      font-size: 18px;
     }

  display: flex;
  margin-left: 20px;

  font-weight: 600;
  color: #fff;
  text-decoration: none;
  margin-right: 20px;


  list-style:none ;
  outline:none !important;
  border:0px !important;
  :hover{
    border:0px !important;
  }
  :focus-visible{
    outline:none !important;
    border:0px !important;
  }
`;

const StylePopover = styled(Popover)`
  .MuiPopover-root{
   background-color: black;
  }
  .MuiPopover-paper{
    background-color: black;
  }
  :focus-visible{
    outline:none !important;
    border:0px !important;
  }
`;
const MnueBox=styled(Box)<{ isMobile?: boolean }>`
     cursor: pointer;
     @media (max-width: 750px) {
       img{
        height: 0.34rem;
        width: 0.32rem;
       
       }
       padding:0rem 0.31rem 0rem 0.28rem;
     }

     @media (min-width:750px){
      img{
        width: 24px;
        height: 24px;
       }
       padding:18px 24px 18px 24px;
     }
`

const Menu: React.FC = () => {
  const { isDesktop, isMobile } = useMatchBreakpoints();
  const { t } = useTranslation();

  const menu=[{
   label:t("home"),
   route:"/"
  },{
    label:t("puzzle"),
    route:"/puzzle"
  }
  ,
  {
    label:t("IceWater"),
    route:"/IceWater"
  },
  {
    label:t("Swap"),
    route:"/Swap"
  },
  {
    label:t("DrawLots"),
    route:"/DrawLots"
  }
]

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const [ownerObj,setOwnerObj]=useState<any>({address:""});
  const {account} = useWallet();
  useEffect(()=>{
    fetch('./owner.json')
    .then((response) => response.json())
    .then((data) => {console.log(data);setOwnerObj(data)})
  },[setOwnerObj,account])


  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
 
  return (
    <Box display="flex">
           <MnueBox display="flex" onClick={handleClick}>
             <img  src="./image/menu.png" alt=""   />
           </MnueBox>
           
          
           <StylePopover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
              >
              <Box flex={1}>
                {menu.map((item:{label:string,route:string},index) => (<>
             
                <StyleBox 
                  onClick={()=>{
                    navigate(item.route);
                    handleClose();
                  }}
                  style={{'cursor':'pointer','fontSize': isDesktop?'18px':"12px"}} flex={1}>
                  <MenuLink to={item.route}>{item.label}</MenuLink>
                </StyleBox>
               
                
                </>
                ))}
                 {
                  account===ownerObj.address||
                  account===ownerObj.address1||
                  account===ownerObj.liquidityAddress
                  ? <StyleBox 
                  onClick={()=>{navigate("/owner");handleClose();}}
                  style={{'cursor':'pointer','fontSize': '18px'}} flex={1}>
                   <MenuLink to="/owner">{t("owner Management")}</MenuLink>
                  </StyleBox>:""
                }
              </Box>
       
           </StylePopover>
            
          
    </Box>
    
  );
};

export default Menu;
