import { useWallet } from '@binance-chain/bsc-use-wallet';
import { BottomNavigation, BottomNavigationAction, Box, Button, CircularProgress, Paper, Switch, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils'
import BeeTokenAbi from '../../../config/abi/BeeToken.json';
import DoneAllSharpIcon from '@material-ui/icons/DoneAllSharp';
import useWeb3 from '../../../hooks/useWeb3';
import BigNumber from 'bignumber.js';



const FlexPanel = styled(Box)`
  display:flex;
`
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}


const CertificationHistory: React.FC =()=>{
  const {account} = useWallet();
  const web3 = useWeb3();
 //   const [isOpen,setIsOpen] = useState(false);
 //   const [pedding,setPedding] = useState(false);
 
 const [contractAddr,setContractAddr] = useState("");
 const [list,setList]=useState<Array<any>>([]);

 
  

  const mintHand=async ()=>{
        const myContract=new web3.eth.Contract((BeeTokenAbi as unknown) as AbiItem,contractAddr);
        const SetQualified= await myContract.getPastEvents('SetQualified', {
             fromBlock: 0,
             toBlock: 'latest'
        })
        const res:Array<any>= SetQualified.map(t=>({blockNumber:t.blockNumber,spender:t?.returnValues?.spender,note:t?.returnValues?.note,state:"是"}));
        const UnsetQualified= await myContract.getPastEvents('UnsetQualified', {
            fromBlock: 0,
            toBlock: 'latest'
        })
        
        const rest:Array<any>= UnsetQualified.map(t=>({blockNumber:t.blockNumber,spender:t?.returnValues?.spender,note:t?.returnValues?.note,state:"否"}));
        let  arrt=res.concat(rest);
        arrt.sort(function(a,b){ // 这是比较函数
            return b.blockNumber - a.blockNumber;    // 降序
        })
        setList(arrt);
      
   }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // setIsOpen(event.target.checked);
  };
  return <Box display="flex" flex={1} flexDirection="column">
                 <FlexPanel flexDirection="column" style={{width:'50%'}}>

                    <TextField 
                    id="standard-basic" 
                    label="BEENTOKEN 合约地址"
                    onChange={(e)=>{setContractAddr(e.target.value)}}
                    />

   
                    <FlexPanel mt={2} alignItems="center">
                        <Button variant="contained" color="primary" onClick={mintHand}>
                                查询
                        </Button>
                    </FlexPanel>





                    <TableContainer component={Paper}>
                    <Table  aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell>地址</TableCell>
                            <TableCell >认证状态</TableCell>
                            <TableCell >备注</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {list.map((row,idx) => (
                            <TableRow key={idx}>
                            <TableCell component="th" scope="row">{row.spender}</TableCell>
                            <TableCell component="th" scope="row">{row.state}</TableCell>
                            <TableCell component="th" scope="row">{row.note}</TableCell>
                          
                            {/* <TableCell align="right">{row.fat}</TableCell>
                            <TableCell align="right">{row.carbs}</TableCell>
                            <TableCell align="right">{row.protein}</TableCell> */}
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                    </TableContainer>

                  </FlexPanel>
          </Box>

}

export default CertificationHistory;