export default {
  bchAddress:{
    10000: "0x0000000000000000000000000000000000002711"
  },
  // 部署地址发布
  beeToken:{
      10000: "0x4f979fEdC9053E61928E1f14c6Ec42414DB472dd"
  },
  factoryAddress:{
    10000: "0xbF1da084F6EAf8DF8B89279FDc21a6cb1Ee9C7Fb"
  },
  imptAddress:{
    10000: "0xA79606B91C0D83Bf512c423cDfde878EB956829A"
  },
  JigsawPuzzle:{
    10000: "0x475601c969562E1aF9073e576e2a0159FF4485eC"
  },
  IceOrWater:{
    10000: "0x9fB9FDe7956A0B9Cf51F9098E20fA7E851cd208c"
  },
  drawAddress:{
    10000: "0xa3aBBbEF9ab2B2671314018EddF3206f934E86cd"
  }


  //测试地址
  // beeToken:{
  //     10000: "0x0AAa14C40E2caFC8729DdC292536086c9c2eb244"
  // },
  // JigsawPuzzle:{
  //   10000: "0x475601c969562E1aF9073e576e2a0159FF4485eC"
  // },
  // IceOrWater:{
  //   10000: "0x0e94e5093a2A731F105385feD6fDbebb62753b14"
  // },
  // factoryAddress:{
  //   10000: "0x26049Fa70b1CA31f95c050D737A9a91f68F86106"
  // },
  // imptAddress:{
  //   10000: "0x3656c28278FDB154081531C89A17FBcC18F64c73"
  // },
  // drawAddress:{
  //   10000: "0x2bD04349feb61022e17b908A60e75A2A466dB43a"
  // }
}
