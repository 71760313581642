import { Box, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import LoginButton from "../Login/Login";
import WhatshotIcon from "@material-ui/icons/Whatshot";
import useMatchBreakpoints from "../../hooks/useMatchBreakpoints";
import { useTranslation } from "react-i18next";
import I18nPopover from "../home/i18nPopover";
import { useWallet } from "@binance-chain/bsc-use-wallet";


const HeaderContainer = styled(Box)`
  height: 100px;
  color: #fff;
  background: black;
  border-bottom: 1px solid #3c3c3c;
  margin-bottom: 2px;
  position: fixed;
  width: 100%;
`;
const BoxMenu = styled(Box)``;
const MenuLink = styled(Link)`
  display: flex;
  margin-left: 20px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  text-decoration: none;
  margin-right: 20px;
`;
const Fireimg = styled(WhatshotIcon)`
  color: #ff3618;
  margin-top: -3px;
  margin-left: 5px;
`;

const Header: React.FC = () => {
  const { isDesktop, isMobile } = useMatchBreakpoints();
  const { t } = useTranslation();
  const {account} = useWallet();
  const [owner,setOwner] = useState();
  const [liquidityAddress,setLiquidity] = useState();
  useEffect(()=>{
    fetch('./owner.json')
    .then((response) => response.json())
    .then((data) => {
      setLiquidity(data.liquidityAddress);
      setOwner(data.address1)
    })
  },[])
  
  return (
    <HeaderContainer
      display="flex"
      flex={1}
      alignItems="center"
      justifyContent="space-between"
    >
     
        <>
          <Box display="flex" alignItems="center">
            <BoxMenu display="flex" ml={5}>
              {
                   (owner&&owner==account) || (liquidityAddress && account==liquidityAddress)?<>
                   <MenuLink to="/owner/">BeenToken管理</MenuLink>
                   <MenuLink to="/owner/PuzzleMangent">拼图盲盒</MenuLink>
                   <MenuLink to="/owner/Liquidity">添加流动性</MenuLink>
                </>:""
              }
              <MenuLink to="/owner/BlindBoxManagement">冰水盲盒/Ice water box</MenuLink>
              <MenuLink to="/owner/DrawMangement">抽奖盲盒/Draw blind box</MenuLink>
              {/* <MenuLink to="/owner/DrawMangement">幸运盲盒</MenuLink> */}
            </BoxMenu>
          </Box>
          <Box display="flex" pr={3}>
            <Box display="flex" pr={3}>
              <LoginButton   />
            </Box>
            {/* <I18nPopover /> */}
          </Box>
        </>
      
    </HeaderContainer>
  );
};

export default Header;
