import { useWallet } from '@binance-chain/bsc-use-wallet';
import { BottomNavigation, BottomNavigationAction, Box, Button, CircularProgress, Paper, Tab, Tabs, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils'
import JigsawPuzzleAbi from '../../../config/abi/JigsawPuzzle.json';

import DoneAllSharpIcon from '@material-ui/icons/DoneAllSharp';
import useWeb3 from '../../../hooks/useWeb3';
import {  getJigsawPuzzleAbiAddress } from '../../../utils/addressHelpers';
import { useJigsawPuzzle } from '../../../hooks/useContract';
import BigNumber from 'bignumber.js';



const FlexPanel = styled(Box)`
  display:flex;
`
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}


const Puzzle: React.FC =()=>{
    const {account} = useWallet();
    const [pedding,setPedding] = useState(false);
    const PuzzleContract=useJigsawPuzzle();

    const [data,setData] = useState({
        priceOfBox:"",
        dueTime:"",
        totalBoxCount:"",
        jigsawPieceCountInEachBox:""
    });
    const [intro,setIntro] = useState("");

    const [hash,setHash] = useState("");
 
  
    const createHand=async ()=>{
          setPedding(true)
          try {
            const res=await PuzzleContract.methods.createLot(data,intro).send({from:account||""});
            console.log("结果",res)
            setHash(res.blockHash)
            setPedding(false)
          }catch(e){}
          
    }

   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        var stime = new Date(event.target.value).getTime()
        const second=stime/1000;
        setData(pre=>({...pre,dueTime:`${second}`}))
   };

   const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const priceAmount=new BigNumber(event.target.value).times(new BigNumber(10).pow(18)).toFixed(0)   
    setData(pre=>({...pre,priceOfBox:priceAmount}))
   };

   return <Box display="flex" flex={1} flexDirection="column">
                 <FlexPanel flexDirection="column" style={{width:'50%'}}>

                   <TextField 
                    id="standard-basic1" 
                    label="盲盒价格(BEE)"
                    onChange={handlePriceChange}
                    />

                     <TextField
                        id="datetime-local"
                        label="终止销售时间"
                        type="datetime-local"
                        defaultValue=""
                        onChange={handleChange}
                        InputLabelProps={{
                        shrink: true,
                        }}
                    />


                   <TextField 
                    id="standard-basic2" 
                    label="盲盒总量"
                    onChange={(e)=>{setData(pre=>({...pre,totalBoxCount:e.target.value}))}}
                    />

                    <TextField 
                    id="standard-basic3" 
                    label="单个盲盒内碎片数量"
                    onChange={(e)=>{setData(pre=>({...pre,jigsawPieceCountInEachBox:e.target.value}))}}
                    />

                   <TextField 
                    id="standard-basic4" 
                    label="介绍"
                    multiline
                    rows={4}
                    onChange={(e)=>{setIntro(e.target.value)}}
                    />



                    <FlexPanel mt={2} alignItems="center">
                        <Button variant="contained" color="primary" onClick={createHand}>
                                创建新批次盲盒
                        </Button>
                        <Box ml={2} mt={2}></Box>
                        {pedding?<CircularProgress />:""}
                        {hash!==""?<DoneAllSharpIcon  style={{color:'#14b714'}}></DoneAllSharpIcon>:""}
                    </FlexPanel>
                    
                    <FlexPanel flexDirection="column">
                        <h3 style={{"marginBlockEnd":0}}>交易hash:</h3>
                        <h4 style={{"marginBlockStart":0}}>{hash}</h4>
                    </FlexPanel>
                  </FlexPanel>
          </Box>

}

export default Puzzle;