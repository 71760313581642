import { useWallet } from '@binance-chain/bsc-use-wallet';
import BigNumber from 'bignumber.js';
import { useCallback, useEffect, useRef, useState } from 'react'
import { useBeeContract, useDrawContract, useDrawContract_static, useIceContract, useIceContract_static, useJigsawPuzzle } from '../../../hooks/useContract'
import useWeb3 from '../../../hooks/useWeb3';
import { useIceState } from '../../../state/Ice/hooks';
import { getDrawAddress, getIceOrWaterAddress, getJigsawPuzzleAbiAddress } from '../../../utils/addressHelpers';
import Web3 from 'web3';
import { ethers } from 'ethers';
import { AbiItem } from 'web3-utils';
import { useDrawBoxState } from '../../../state/DrawBox/hooks';
import { latest } from 'immer/dist/internal';

export  function  useGetBetaAndPI():{getBetaAndPI:any}{
  const {account} = useWallet();
  // const [Servers, setServers] = useState<any>(["enclave0.vrf.cash:8081", "enclave1.vrf.cash:8081"]);
  // const [Servers, setServers] = useState<any>([]);
  const Servers = useRef<Array<any>>([]);
  const fetchWithTimeout=async(resource: string, options:any = {})=>{
    const { timeout = 3000 } = options;
    
    const abortController = new AbortController();
    const id = setTimeout(() => abortController.abort(), timeout);
    const response = await fetch(resource, {
      ...options,
      signal: abortController.signal  
    });
    clearTimeout(id);
    return response;
  }
  const  getBetaAndPI=useCallback(async(hashHex: string)=>{
    var idx = (Math.random() > 0.5) ? 1 : 0;
    for(var i=idx; i<3; i++) {
      var j = i%Servers.current.length;
      try {
        const url = "https://"+Servers.current[j]+"/vrf?b="+hashHex //hashHex 是区块哈希，且不包含开头的0x
        const response = await fetchWithTimeout(url)
        var result = await response.json();
        // console.log(result);
        if(result.PI) {
          return result;
        }
      } catch(e) {
        console.log("continue", e);
        continue
      }
    }
  },[Servers]) 

  useEffect(() => {
    const fetchs = async () => {
        const VrfGovAddress = "0x18C51aa3d1F018814716eC2c7C41A20d4FAf023C";

        const VrfGovABI = [
        "function pubKey() public view returns (bytes)",
        ]
        // 页面载入后先检查服务器的pubkey是否正确， 正确则加入到全局变量Servers当中
        // const vrfgovContract=new web3.eth.Contract((VrfGovABI as unknown) as AbiItem,VrfGovAddress);
        // const onchainKey=await vrfgovContract.methods.pubKey().call();
        // const MarketAddress = await factoryContract.methods.getAddress(getBeeTokenAddress(), getBchAddress(), getimptAddress()).call();
        const provider = new ethers.providers.Web3Provider((window as any).ethereum);
        const vrfgovContract = new ethers.Contract(VrfGovAddress, VrfGovABI, provider);
        const onchainKey = await vrfgovContract.pubKey();
        // const resp0 = await fetchWithTimeout("https://enclave0.vrf.cash:8081/pubkey");
        try {
          const resp0 = await fetch("https://enclave0.vrf.cash:8081/pubkey");
          const key0 = await resp0.text();
          if(onchainKey == "0x"+key0) {
            Servers.current=["enclave0.vrf.cash:8081"];
            // setServers(["enclave0.vrf.cash:8081"])
            // Servers = ["enclave0.vrf.cash:8081", ...Servers]
          }
        } catch (error) {}
    }
    if (Servers.current.length==0){fetchs()};
    // 如果都正确的话，那么： Servers = ["enclave0.vrf.cash:8081/", "enclave1.vrf.cash:8081/"]
  }, [Servers.current])

  return {getBetaAndPI}
}
//购买响应
export  function  useHandFuc(){
  const {account} = useWallet();
  const DrawContract=useDrawContract();
  const DrawContractStatic=useDrawContract_static();
   const handleBuy = useCallback(
    async (lotId:string) => {
        try {
        
            const cont=await DrawContract.methods.buyBox(lotId).send({from:account});
            return {state:true,res:cont}
        } catch (e) {
            return {state:false,res:e}
        }
    },
        [account, DrawContract],
    )

    const handleRecaption = useCallback(
      async (lotId:string,msgenderIdx:string,rdm:string,pi:string) => {
          try {
              const cont=await DrawContract.methods.getPrize(lotId,msgenderIdx,rdm,pi).send({from:account});
              return {state:true,res:cont}
          } catch (e) {
              return {state:false,res:e}
          }
      },
          [account, DrawContract],
      )

  return {handleBuy,handleRecaption}
}
//获取批次信息
export  function useDrawAndBuyersHeights(lotId:any):{
  drawData:any,
  listMoney:Array<any>,
  buyerMap:Array<{msgsender:number,blockNumber:string}>|undefined
 }{

  const {pageRefreshState}=useDrawBoxState();
  const {getBetaAndPI}=useGetBetaAndPI();
  const web3=useWeb3();
  const {account}=useWallet();
  const [drawData, setDraw] = useState<any>({});
  const [buyerMap, setBuyerMap] = useState<Array<{
    msgsender:number,
    blockNumber:string
  }>>();

  const [listMoney, setListMoney] = useState<any>([]);
  const DrawContract=useDrawContract();
  const DrawContractStatic=useDrawContract_static();
  
  useEffect(() => {
    const fetch = async () => {
      setDraw({})
      // 获取批次信息
      const resDraw=await DrawContract.methods.luckyDrawMap(lotId).call();
      // 获取用户购买盲盒 和区块号
      let ret=await DrawContract.methods.getBuyersAndHeights(lotId).call();
      ret=ret.map((g:any,idx:number)=>({idx:idx,value:g}))
   

      //查询跟当前用户相关购买信息
      const retData=ret.filter((y:any)=>y.value!=0);

      const retObj=retData.map((t:any,idx:number)=>{
        const sender=new BigNumber(t.value).div(new BigNumber(2).pow(96)).toFixed();
        const block=new BigNumber(t.value).mod(new BigNumber(2).pow(96)).toFixed();
        // (uint(uint160(msg.sender))<<96)|block.number
        return {index:t.idx,msgsender:sender,blockNumber:block}
      })
      const senderInfo=retObj.filter((t:any)=>t.msgsender==Web3.utils.hexToNumberString(account||""));
      setBuyerMap(retObj);
     

      // 获取购买人的区块号
      const list = await Promise.all(
        senderInfo.map((k:any)=> web3.eth.getBlock(k.blockNumber).then((data:any)=>data.hash.split("0x")[1]))
      )
      // 获取区块号对应的 betaAndpi
      let listBetaAndPI = await Promise.all(
        list.map(async(B:any)=> getBetaAndPI(B).then((data:any)=>data).catch((err:any)=>null))
      )
       // 获取购买用户对应的金额
       // 区块哈希过期
       listBetaAndPI=listBetaAndPI.map((t,idx)=>({index:senderInfo[idx].index,BetaAndPI:t}));
       listBetaAndPI=listBetaAndPI.filter((t,idx)=>t.BetaAndPI);

       if(listBetaAndPI.length>0){
        const listMoney_s = await Promise.all(
          // uint id, uint96 n, uint rdm, bytes calldata pi
          listBetaAndPI.map((value:any,index)=>{
            // 处理提取奖励空位的问题
            const resItem=senderInfo.find((p:any)=>p.index==value?.index)
            const finditem=resItem?resItem:{};
            //  返回奖励盒子，奖励，区块
            return DrawContractStatic.methods.getPrize(lotId,value.index,"0x"+value?.BetaAndPI?.Beta,"0x"+value?.BetaAndPI?.PI).call()
            .then((data:any)=>({idx:value.index, money:data, block:finditem.blockNumber, ...finditem}))
            .catch((err:any)=>0)
            } 
          )
        )
      

        // const listMoney_Attr=senderInfo.map((t:any,idx:number)=>({idx:t.index,money:listMoney_s[idx],block:t.blockNumber}));
        setListMoney(listMoney_s);
        console.log("listMoney",listMoney_s)
       }else{
        setListMoney([])
       }
       
       setDraw(resDraw);
      // await getBetaAndPI();
    }

    if (pageRefreshState||(Object.keys(drawData).length==0  && lotId!="-1" && account)) fetch();
  }, [setDraw,setBuyerMap,account,pageRefreshState,lotId])
  return {drawData,buyerMap,listMoney}
}




export  function useDrawId(parameterId:string):{lotId:string,deadline:string}{
    const [lotId, setLotId] = useState<string>("-1");
    const [deadline, setDeadline] = useState<string>("");
    const DrawContract=useDrawContract();
    const web3 = useWeb3();
    useEffect(() => {
      const fetch = async () => {
        const bolck=await web3.eth.getBlockNumber();
        const historyBlock=bolck-parseInt(`${(parseInt(30 as any)*86400)/5}`)
        const resStartLuckyDraw= await DrawContract.getPastEvents('StartLuckyDraw', {
            fromBlock: historyBlock,
            toBlock: 'latest',
            filter:{}
        })
        console.log("resStartLuckyDraw",resStartLuckyDraw)
        const LotIdObj=resStartLuckyDraw[resStartLuckyDraw.length-1];
        let lotIdValue=new BigNumber("-1");
        let lotIdTime="";
        if(resStartLuckyDraw.length>0){
           lotIdValue=new BigNumber(Web3.utils.hexToNumberString(LotIdObj.returnValues.creator)).times(new BigNumber(2).pow(96)).plus(new BigNumber(LotIdObj.returnValues.deadline));
           lotIdTime=LotIdObj.returnValues.deadline;
        }
        console.log(lotIdValue.toFixed())
        setLotId(lotIdValue.toFixed())
        setDeadline(lotIdTime);
      }
  
      if (lotId=="-1" && !parameterId){
        fetch();
      }else{
        setLotId(parameterId);
        setDeadline(new BigNumber(parameterId).mod(new BigNumber(2).pow(96)).toFixed());
      }
    }, [setLotId,setDeadline,parameterId])
    return {lotId,deadline}
}

export  function useDrawApprove():{isApprove:boolean}{
    const [isApprove, setAllowanceState] = useState<boolean>(false);
    const beeContract=useBeeContract();
    const {account} = useWallet();
    const {pageRefreshState}=useDrawBoxState();
    useEffect(() => {
      const fetch = async () => {
        try {
            const allowance= await beeContract.methods.allowance(account,getDrawAddress()).call();
            const tokenAllowedStatus = allowance && !new BigNumber(allowance).isLessThan(new BigNumber(10).pow(77))
            setAllowanceState(tokenAllowedStatus)
        } catch (error) {}
      }
  
      if(account||pageRefreshState){
         fetch()
      } 
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setAllowanceState,account,pageRefreshState])

    return {isApprove}
}

export  function useWithdraw():{IcehistoryList:Array<any>}{
    const [IcehistoryList, setIceHistoryList] = useState<any[]>([])
    const iceContract=useIceContract_static();
    const {account} = useWallet();
    useEffect(() => {
        if(!account){return}
      const fetch = async () => {
        const resAmount=await iceContract.methods.withdraw(0,0,true).call();
        console.log(resAmount)
      }
      
      if (!IcehistoryList || !IcehistoryList.length) fetch()
    }, [setIceHistoryList,account])

    return {IcehistoryList}
}


function from(from: any, arg1: string) {
  throw new Error('Function not implemented.');
}

