import { Box, Button, CircularProgress, createStyles, Fade, makeStyles, Modal, TextField, Theme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useMatchBreakpoints from "../../hooks/useMatchBreakpoints";
import { useTranslation } from "react-i18next";
import CloseIcon from '@material-ui/icons/Close';
import { useJigsawPuzzle } from "../../hooks/useContract";
import { useWallet } from "@binance-chain/bsc-use-wallet";
import DoneAllSharpIcon from '@material-ui/icons/DoneAllSharp';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { usePuzzleHandlers } from "../../state/Puzzles/hooks";


const SendBtn=styled(Box)`
    padding: 8px 30px 8px 30px;
    background-color: #6DB90E;
    border-radius: 20px;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
    :hover{
        background-color: #436e0f;
    }
`

const StyleModal=styled(Box)`
  padding:0px 30px 0px 30px;
`
const Flex=styled(Box)`
  display: flex;
`


const StyleTextField=styled.input`
        padding: 10px;
        border: 1px solid #b1b1b1;
        border-radius: 10px;
        margin-top: 20px;
        :focus-visible{
            border: 0;
            outline:0;
        }
`

const ModalConent=styled(Flex)<{isMobile:boolean}>`
      position: absolute;
      min-width:300px;
      width: ${(props) => (props.isMobile?"89%":"")};
      border: '2px solid #000';
      box-shadow: 5px;
      outline:0;
      background-color:#061E3E;
      font-size: ${(props) => (props.isMobile?"12px":"")};
      .closeImg{
        width:${(props) => (props.isMobile?"0.24rem":"24px")};
        height:${(props) => (props.isMobile?"0.24rem":"24px")};
      }
      .fontClose{
        font-size:${(props) => (props.isMobile?"0.24rem":"24px")};
      }


`

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
     
    },
    paper: {
      position: 'absolute',
      minWidth:'375px',
      // width: "50%",
      // height:"480px",
      // backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      outline:0,
      padding: theme.spacing(0, 0, 8),
      // padding: theme.spacing(2, 4, 3),
      backgroundColor: "#061E3E"
    },
  }),
);
interface ImgInfoModel{
  url?: number
  isopen:boolean
  setOpens:(e: any)=>void
  lotIdAndIdx:{
    lotId:number,
    spId:number
  }
}


const SendModal: React.FC<ImgInfoModel> = ({url,isopen,setOpens,lotIdAndIdx}) => {
  const { isDesktop, isMobile } = useMatchBreakpoints();
  const { t } = useTranslation();
  const PuzzleContract=useJigsawPuzzle();
  const {account} = useWallet();


  const {onIsRefresh}=usePuzzleHandlers();
  const classes = useStyles();
  const [targetAddr,setTargetAddr]=useState("");
  const [pedding,setPedding] = useState(false);
  const [hash,setHash] = useState("");
  const [erro,setErro] = useState<any>("");
  
  const roladList=()=>{
    onIsRefresh(true)
    setTimeout(()=>{
        onIsRefresh(false)
    },1000)
  }

  const hangSend=async ()=>{
    try {
        setErro("");
        setPedding(true)
        const res=await PuzzleContract.methods.transfer(lotIdAndIdx.lotId,lotIdAndIdx.spId,targetAddr).send({from:account});
        console.log("res:",res)
        setHash(res.blockHash)
        roladList();
    } catch (e:any) {
        console.log("success:",e)
        setErro(e.message?e.message:e.toString())
    }
    setPedding(false)
  };
  
  const statesIcon=()=>{
    if(pedding===true){
       return <CircularProgress />
    }
    if(hash!==""){
      return <DoneAllSharpIcon  style={{color:'#14b714'}}></DoneAllSharpIcon>
    }

    if(erro!=""){
        return <Box display='flex' justifyContent='center' color='#fff'><ErrorOutlineIcon  style={{color:'red'}}/>Tranfer Erro</Box>
    }
  }

  return (<Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isopen}
        onClose={()=>{setOpens(false)}}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isopen}>
        <ModalConent  isMobile={!isDesktop}>
            <Box display='flex' flex={1} flexDirection="column">
              <Box style={{cursor: 'pointer'}} onClick={()=>setOpens(false)} mt={2} mr={2} display='flex' alignItems='center' justifyContent='right'>
                {/* <CloseIcon style={{ color: '#fff' }}></CloseIcon> */}
                <img  className="closeImg" src="./image/close.png"/>
                <Box ml={1} className="fontClose" component='span' color="#fff"  flex={'display'}>{t("CLOSE")}</Box>
              </Box>
              <StyleModal pt={4} display='flex' alignItems="center" flexDirection="column">
                  <StyleTextField
                   value={targetAddr} 
                   onChange={(e)=>{setTargetAddr(e.target.value)}} 
                   placeholder={t("target address")}/>
                  <Flex mt={3} justifyContent="center" alignItems='center' flexDirection="column">
                        <SendBtn onClick={hangSend}>{t("confirm")}</SendBtn>
                        <Box mt={2}>{statesIcon()}</Box>
                        
                  </Flex>

              </StyleModal>
            </Box>
         

          </ModalConent>
        </Fade>
      </Modal>
  
  );
};

export default SendModal;
