import { useWallet } from '@binance-chain/bsc-use-wallet';
import { BottomNavigation, BottomNavigationAction, Box, Button, CircularProgress, MenuItem, Paper, Select, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import Web3 from 'web3';
import DoneAllSharpIcon from '@material-ui/icons/DoneAllSharp';
import useWeb3 from '../../../hooks/useWeb3';
import BigNumber from 'bignumber.js';
import { useDrawContract } from '../../../hooks/useContract';
import moment from "moment";


const FlexPanel = styled(Box)`
  display:flex;
`
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}


const SearchPage: React.FC =()=>{
    const {account} = useWallet();
    const web3 = useWeb3();
    
    const [pedding,setPedding] = useState(false);
    const [createAddres,setCreateAddres] = useState("");
    const [hrefList,sethrefList] = useState<any>([]);

    const [LuckyDraw,setLuckyDraw] = useState({
        prizes:"1",
        decimals:18,
        price:"",
        totalCount:"",
        soldCount:0
    });


    const [hash,setHash] = useState("");
    const [urlId,setUrlId] = useState("");
    const DrawContract= useDrawContract();
  
    const createHand=async ()=>{
          setPedding(true)
          try {
    
            // const LuckyDraws={...LuckyDraw,prizes:prizesRes.toFixed()};
            // 1,100|1,100|1,100|1,100|1,100|1,100|1,100|1,100|1,100|1,100
            const bolck=await web3.eth.getBlockNumber();
            const historyBlock=bolck-parseInt(`${(parseInt(times as any)*86400)/5}`)
            

            const resStartLuckyDraw= await DrawContract.getPastEvents('StartLuckyDraw', {
                fromBlock: historyBlock,
                toBlock: 'latest',
                filter:{creator:createAddres}
            })

            // (uint(uint160(msg.sender))<<96)|block.number
            let tranId=resStartLuckyDraw.map(t=>{
                // creator, uint deadline
                const lotIdValue=new BigNumber(Web3.utils.hexToNumberString(t.returnValues.creator)).times(new BigNumber(2).pow(96)).plus(new BigNumber(t.returnValues.deadline));
                return {...t,lotId:lotIdValue.toFixed()}
            })
            

            sethrefList(tranId);
            console.log("结果",tranId)
            // setHash(res.blockHash)
            setPedding(false)



          }catch(e){}


    }


    const [times,setTimes] = useState("3");
    const handleTimeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
          setTimes(event.target.value as string);
    };

    const addZero = (num:any)=> {
        if (parseInt(num) < 10) {
            num = "0" + num
        }
        return num
    }
    return <Box display="flex" flex={1} flexDirection="column">
                 <FlexPanel flexDirection="column" style={{width:'50%'}}>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={times}
                        placeholder="日期范围/Time range"
                        onChange={handleTimeChange}
                        >
                        <MenuItem value={3}>近3天/Nearly 3 days</MenuItem>
                        <MenuItem value={7}>近7天/Nearly 7 days</MenuItem>
                        <MenuItem value={30}>近30天/Nearly 30 days</MenuItem>
                    </Select>
                    <TextField
                        id="createAddres"
                        label="创建者地址/Address of Creator"
                        type="prizes"
                        defaultValue=""
                        onChange={(e)=>{setCreateAddres(e.target.value)}}
                    />
          

                    <FlexPanel mt={2} alignItems="center">
                        <Button variant="contained" color="primary" onClick={createHand}>
                                查询/Query
                        </Button>
                        <Box ml={2} mt={2}></Box>
                        {pedding?<CircularProgress />:""}
                        {hash!==""?<DoneAllSharpIcon  style={{color:'#14b714'}}></DoneAllSharpIcon>:""}
                    </FlexPanel>
                    

                      <Table  aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell>截止时间/deadline</TableCell>
                            <TableCell >url路径/url path</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {hrefList.map((row:any,idx:any) => (
                            <TableRow key={idx}>
                               
                                <TableCell style={{whiteSpace: "nowrap"}} component="th" scope="row">{moment((row.returnValues.deadline)*1000).format('yyyy-MM-DD HH:mm:ss')}</TableCell>
                                <TableCell component="th" scope="row">http://blindbox.cash/#/DrawLots/{row.lotId}</TableCell>
                                {/* <TableCell component="th" scope="row">{row.note}</TableCell> */}
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                   

                    </FlexPanel>
          </Box>

}

export default SearchPage;