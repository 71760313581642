import { useEffect, useState } from 'react'
import { AbiItem } from 'web3-utils'
import { ContractOptions } from 'web3-eth-contract'
import useWeb3 from './useWeb3'
import BeeTokenAbi from '../config/abi/BeeToken.json';
import JigsawPuzzleAbi from '../config/abi/JigsawPuzzle.json';
import IceOrWaterAbi from '../config/abi/IceOrWater.json';
import IceOrWatersStaticAbi from '../config/abi/IceOrWaters.json';
import LucyDrawAbi from '../config/abi/LucyDraw.json';
import LucyDrawstaticAbi from '../config/abi/LucyDraw_static.json';
import RobotAbi from '../config/abi/Robot.json';

import { getJigsawPuzzleAbiAddress,getBeeTokenAddress, getIceOrWaterAddress, getDrawAddress } from '../utils/addressHelpers';

const useContract = (abi: AbiItem, address: string, contractOptions?: ContractOptions) => {
  const web3 = useWeb3()
  const [contract, setContract] = useState(new web3.eth.Contract(abi, address, contractOptions))

  useEffect(() => {
    setContract(new web3.eth.Contract(abi, address, contractOptions))
  }, [abi, address, contractOptions, web3])

  return contract
}

export const useBeeContract = () => {
  const abi = (BeeTokenAbi as unknown) as AbiItem
  return useContract(abi, getBeeTokenAddress())
}

export const useJigsawPuzzle = () => {
  const abi = (JigsawPuzzleAbi as unknown) as AbiItem
  return useContract(abi, getJigsawPuzzleAbiAddress())
}
export const useIceContract = () => {
  const abi = (IceOrWaterAbi as unknown) as AbiItem
  return useContract(abi, getIceOrWaterAddress())
}

export const useIceContract_static = () => {
  const abi = (IceOrWatersStaticAbi as unknown) as AbiItem
  return useContract(abi, getIceOrWaterAddress())
}

export const useDrawContract = () => {
  const abi = (LucyDrawAbi as unknown) as AbiItem
  return useContract(abi, getDrawAddress())
}

export const useDrawContract_static = () => {
  const abi = (LucyDrawstaticAbi as unknown) as AbiItem
  return useContract(abi, getDrawAddress())
}
// export const useMarkContract = () => {
//   const abi = (RobotAbi as unknown) as AbiItem
//   return useContract(abi, getMarkAddress())
// }

export default useContract
