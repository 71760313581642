import { useWallet } from '@binance-chain/bsc-use-wallet';
import { BottomNavigation, BottomNavigationAction, Box, Button, CircularProgress, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import NewBox from './NewBox';
import SearchPage from './SearchPage';
import SetBoxres from './SetBoxres';

const FlexPanel = styled(Box)`
  display:flex;
`
const Container = styled(Box)`
  background: black;
  padding: 20px;
  color: #fff;
`;




interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`wrapped-tabpanel-${index}`}
        aria-labelledby={`wrapped-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3} display='flex'>
            {children}
          </Box>
        )}
      </div>
    );
  }

const BlindBoxManagement: React.FC =()=>{
    const [value,setValue] = useState(0)

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
            setValue(newValue);
    };

  return <Box display="flex" flex={1} overflow="auto"  flexDirection="column">
        <Paper square >
              <Tabs
                value={value}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChange}
                aria-label="disabled tabs example"
            >
                <Tab label="创建新的盲盒/A new blind box" />
                <Tab label="设置盲盒的结果/box results" />
                <Tab label="查询盲盒地址/Query  box address"/>
                
              </Tabs>
        </Paper>
      
        <TabPanel value={value} index={0}>
              {value==0?<NewBox></NewBox>:""}
        </TabPanel>
        <TabPanel value={value} index={1}>
              {value==1?<SetBoxres></SetBoxres>:""}
        </TabPanel>
        <TabPanel value={value} index={2}>
              {value==2?<SearchPage></SearchPage>:""}
        </TabPanel>

            


            {/* <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                 This is a success message!
                </Alert>
            </Snackbar> */}
          </Box>

}

export default BlindBoxManagement;