import { Box, Button } from "@material-ui/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import useMatchBreakpoints from "../../hooks/useMatchBreakpoints";
import { useTranslation } from "react-i18next";
import { useWallet } from "@binance-chain/bsc-use-wallet";
import BigNumber from "bignumber.js";
import TipModael from "../../component/TipModael";
import { getBeeTokenAddress, getIceOrWaterAddress } from "../../utils/addressHelpers";
import useTokenApprove from "../../hooks/useApprove";
import { useBeeContract } from "../../hooks/useContract";
import { useIceHandlers, useIceState } from "../../state/Ice/hooks";
import ConnectWallt from "../home/ConnectWall";
import { useHandFuc, useIceApprove, useOwnerExists } from "../IceWater/hooks/useIce";
import { useAllRobots, useBeeApprove } from "./hooks/useSwap";
import { toFormatPrice } from "../../utils/getPrecision";
import { ethers } from "ethers";
import RobotAbi from '../../config/abi/Robot.json';
import useWeb3 from "../../hooks/useWeb3";
import { AbiItem } from 'web3-utils';
import { useExchangeHandlers } from "../../state/Exchange/hooks";

const StylePanel=styled(Box)<{state: number }>`
     align-items: center;
     @media (max-width: 750px) {
       font-size: 0.16rem;
     }
     opacity: ${(props) => (props.state==1?1:0.2)};
     pointer-events: ${(props) => (props.state==1 ? "initial" : "none")};
   

`

const RightPanel=styled(Box)<{ isMobile?: boolean }>`
    /* margin-left:243px; */
    /* padding-right:${(props) => (props.isMobile?'30px':'0px')}; */
    position:${(props) => (props.isMobile?'none':'absolute')};
    right: ${(props) => (props.isMobile?'24px':'3rem')};
`
const Flex=styled(Box)`
     display: flex;
`

const IceCenter=styled(Flex)<{ isMobile?: number }>`
        /* width: 1600px; */
      /* height: 406px; */
      background-image: ${(props) => (props.isMobile==1?"url('/image/750/backExchange.png')":"url('/image/backExchange.png')")};
      background-size: 100% 100%;
      /* background: linear-gradient(143deg,rgba(53,248,254,0.48) 5%, rgba(63,164,255,0.34) 99%); */
      /* border: 2px dashed #ffffff; */
      border-radius: 8px;
      @media (max-width: 750px) {
        margin-top: 0.22rem;
        padding-bottom:0.38rem;
      }
      @media (min-width:750px){
        margin-top: 30px;
        padding-bottom:20px;
      }
`


const StyleIcon=styled.img`
      @media (max-width: 750px) {
        width: 0.18rem;
        height: 0.18rem;
      }
      @media (min-width:750px){
        width: 40px;
        height: 40px;
      }
`
const BoxImg=styled.img`
      @media (max-width: 750px) {
        width: 1.85rem;
        height: 1.6rem;
        margin-top:0.28rem;
      }
      @media (min-width:750px){
        width: 417px;
        height: 350px;
        margin-top:48px;
      }
`

const DesPanel=styled(Flex)`
      @media (max-width: 750px) {
        padding:0.18rem 0px 0px 0.18rem;
        font-size: 0.18rem;
      }
      @media (min-width:750px){
        padding:40px 0px 0px 40px;
        font-size: 20px;
      }
`
const DesVale=styled(Flex)`
      color: #74DD54;
      @media (max-width: 750px) {
        font-size: 0.13rem;
        
      }
      @media (min-width:750px){
        font-size: 40px;
      }
`
const StyleTextField=styled.input`
        border: 1px solid #b1b1b1;
        /* border-radius: 10px; */
        
        :focus-visible{
            border: 0;
            outline:0;
        }
        width: 100%;
        @media (max-width: 750px) {
          padding: 0.2rem;
          margin-top: 0.78rem;
        }
        @media (min-width:750px){
          padding: 18px;
          margin-top: 20px;
        }
`



const STyleTitle=styled(Flex)`
      @media (max-width: 750px) {
         padding: 0px 0.12rem 0.05rem 0.12rem;
         font-size: 0.32rem;
         margin-top:-0.15rem;
         border: 1px dashed #ffffff;
         border-radius: 3px;
      }
      @media (min-width:750px){
         padding: 0px 24px 0px 24px;
         height: 48px;
         font-size: 32px;
         margin-top:-15px;
         border: 2px dashed #ffffff;
         border-radius: 8px;
      }
      white-space: nowrap;
      color:#fff;
      background: #2688a5;
   
     
      justify-content: 'center';
      align-items: center;
      
`

const StyleBtnEnaring=styled(Flex)`
      @media (max-width: 750px) {
        padding:0.05rem 0.2rem 0.05rem 0.2rem;
        font-size: 0.18rem;
      }
      @media (min-width:750px){
        padding:5px 20px 5px 20px ;
        font-size: 20px;
      }
      color:#fff;
      border-radius: 4px;
      background: linear-gradient(358deg,#FF720A 5%,#FFD040 99%);  
      cursor: pointer;
      :hover{
        background: linear-gradient(358deg,#FFD040 5%,#FF720A 99%);
      }
`
const StyleBtn=styled(Flex)`
      @media (max-width: 750px) {
        padding:0.1rem 0.46rem 0.1rem 0.46rem;
        font-size: 0.35rem;
      }
      @media (min-width:750px){
        padding:10px 65px 10px 65px ;
        font-size: 20px;
      }
      color:#fff;
      border-radius: 4px;
      background: linear-gradient(358deg,#FF720A 5%,#FFD040 99%);  
      cursor: pointer;
      :hover{
        background: linear-gradient(358deg,#FFD040 5%,#FF720A 99%);
      }
`

const TimeBox=styled(Box)<{isMobile:boolean}>`
        @media (max-width: 750px) {
            padding:0.05rem 0.06rem 0.05rem 0.06rem;
        }
        @media (min-width:750px){
           padding:6px 12px 6px 12px;
        }
    background-color:black;
    /* padding: ${(props) => (props.isMobile?'0.05rem 0.06rem 0.05rem 0.06rem':'6px 12px 6px 12px')} ; */
    font-size:${(props) => (props.isMobile?'0.18rem':'32px')};
    color: #6FBA0F;
    border-radius: 5px;
    /* border:1px dashed #fff; */
`



const StyleSpan=styled(Flex)`
      @media (max-width: 750px) {
        padding-top: 0.46rem;
        .meoney{
            font-size: 0.64rem;
            color:#00E945;
        }
        .unit{
            font-size: 0.32rem;
            color:#F6FFF9;
            margin-left: 0.21rem;
        }
      }
      @media (min-width:750px){
        padding-top: 16px;
        .meoney{
            font-size: 64px;
            color:#00E945;
        }
        .unit{
            font-size: 32px;
            color:#F6FFF9;
            margin-left: 21px;
        }
      }
     
`
const StyleExchange=styled(Flex)`
      @media (max-width: 750px) {
        width: 0.4rem;
        height: 0.4rem;
      }
      @media (min-width:750px){
        width: 40px;
        height: 40px;
      }
     
`


const Swap: React.FC = () => {
  const {account} = useWallet();
  const { isDesktop, isMobile } = useMatchBreakpoints();
  const { t } = useTranslation();

  // stock 精度
  const stockDecimals=18;
  const {list,markAddress}=useAllRobots();

  const {onIsRefresh}=useExchangeHandlers();
//   const {pageRefreshState}=useExchangeState();
  // 授权
  const tokenContract=useBeeContract();
  const {isApprove}=useBeeApprove(markAddress);
  const onApprove=useTokenApprove(tokenContract,markAddress);

  //提交操作
  const [amount,setAmount]=useState("");
  const [bchAmount,setBchAmount]=useState("0");
  //hand state
  const [shopOpen,setShopOpen]=useState(false);
  const [shopErro,setShopErro]=useState<any>("");
  const [pedding,setPedding]=useState<any>(false);
  const [successDes,setSuccessDes]=useState<any>("");
  const web3 = useWeb3();


 

  const roladList=()=>{
    onIsRefresh(true)
    setTimeout(()=>{onIsRefresh(false)},1000)
  }
 
  
  // 授权
  const handleApprove = useCallback(async () => {
      try {
          const txHash = await onApprove();
          roladList();
      } catch (e) {
          console.error(e)
      }
  }, [onApprove])





  const getBtn=()=>{

    if(pedding){
       return  <StyleBtn style={{background:"rgb(197 188 188 / 67%)"}} >{t("EXchange pedding")}</StyleBtn>
    }
    if(isApprove){
      return  <StyleBtn onClick={Sell}>{t("Confirm EXchange")}</StyleBtn>
    }else{
      return  <StyleBtn onClick={handleApprove}>{t("approve")}</StyleBtn>
    }
  }


  const Sell=async ()=>{
    
    const robotId=list[0].fullId;
    const provider = new ethers.providers.Web3Provider((window as any).ethereum);
    var stockDelta = amount;
    var stockDeltaBN = ethers.utils.parseUnits(stockDelta, stockDecimals)
    var robot = (window as any).RobotsMap[robotId]
   
    let val = null;
    if ((window as any).stockAddr == '0x0000000000000000000000000000000000002711') {
        val = {value: stockDeltaBN};
    }
    console.log('val:', val);

 
    const marketContract=new web3.eth.Contract((RobotAbi as unknown) as AbiItem,markAddress);
    try {
        setSuccessDes("For successful");
        setPedding(true);
        setShopErro("")
        const res=await marketContract.methods.sellToRobot(robotId, stockDeltaBN).send({from:account});
        console.log(res)
        setShopOpen(true);
    } catch (error:any) {
        console.log(error)
        setShopErro(error.message?error.message:error.toString())
        setShopOpen(true);
    }
    setPedding(false);
    
}

  return (<>
  
  {!account?<Box display="flex"  flex={1}>
          <Flex flex={1} alignItems="center" justifyContent="center">
            <Flex >
                <ConnectWallt></ConnectWallt>
            </Flex>
          </Flex>
         </Box>
      :
      <StylePanel state={list.length>0?1:0}  display="flex" flex={1}  alignItems="center">
    
      <Flex display="flex" flexDirection="column" flex={1} >
         

          <IceCenter isMobile={isDesktop?0:1} alignItems="center" flexDirection="column">
           <Flex >
               <STyleTitle>{t("Currency Exchange")}</STyleTitle>
          
           </Flex>
           <Flex width={isDesktop?"50%":"5.4rem"}>
               <StyleTextField value={amount} onChange={(e)=>{
                 setAmount(e.target.value);
                 setBchAmount(new BigNumber(e.target.value||0).times(new BigNumber(list[0].lowPrice)).toFixed());
                 //  setAmount(e.target.value);
               }} 
               placeholder={t("Input the number of BEE coin...")}/>
           </Flex>

           <StyleExchange mt={isDesktop?"22px":"0.69rem"}>
             <img src="./image/exchange.png"  alt="" width='100%'/>
           </StyleExchange>
         
           <StyleSpan alignItems="baseline">
            <Flex className="meoney">{toFormatPrice(new BigNumber(bchAmount),2)=="0"?'0.00':toFormatPrice(new BigNumber(bchAmount),2)}</Flex>
            <Flex className="unit" component='span'>BCH</Flex>
           </StyleSpan>
          
           <Flex mt={isDesktop?5:'0.8rem'}>
            {getBtn()}
           </Flex>

          </IceCenter>

      </Flex>

      {/* //modal */}
        <TipModael
        successDes={successDes} 
        erro={shopErro} 
        isopen={shopOpen}
        setOpens={setShopOpen}>
        </TipModael>
      
    </StylePanel>


      }
    
  </>
          
    
  );
};

export default Swap;


