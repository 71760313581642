import { useWallet } from '@binance-chain/bsc-use-wallet';
import { BottomNavigation, BottomNavigationAction, Box, Button } from '@material-ui/core';
import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';
import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils'
import nftAbi from './../../config/abi/nft.json';
import addresses from './../../config/contract/nft';
import Header from './header';

const ImgCenter = styled.img`
    width:130px;
    height:130px;
`
const ImgMint = styled.img`
    padding:15px;
`
const LoginBtn = styled(Box)`
    /* background: #64c832; */
    background: #d30052;
    padding:0 18px;
    height: 38px;
    border-radius: 20px;
    font-weight: bold;
    cursor: pointer;
`
const Container = styled(Box)`
  /* background: black; */
  padding: 20px;
  /* color: #fff; */
`;

const OwerPage: React.FC =()=>{
//   useEffect(()=>{
//     //地址类型address 16进制=》10进制整数类型 转换
//     address==uint    address 16进制hex => 到uint  就是10进制整数
//     let test1=new BigNumber("41260920106969412157321674113669319076297763250177730552014715551905833025592");
//     console.log("取模,取出低位区块号",new BigNumber("31600036898245031257345518938338575071610785427121447961177338488262487965729")
//     .mod(new BigNumber(2).pow(96)).toFixed())

//     const address=test1.div((new BigNumber(2).pow(96))).toFixed();
//     console.log("address",address)
//     console.log("16进制unit地址==》 adress类型toHex",Web3.utils.hexToNumberString("0x5B38Da6a701c568545dCfcB03FcB875f56beddC4"))
//     console.log("16进制unit地址==》 adress类型",Web3.utils.numberToHex(address))



//     //数据源 (uint(uint160(0x5B38Da6a701c568545dCfcB03FcB875f56beddC4))<<160)|56;
//     const res=ethers.BigNumber.from("41260920106969412157321674113669319076297763250177730552014715551905833025592").div(ethers.BigNumber.from(2).pow(96))
//     console.log("ethers=======",res.toHexString())
    
  
//     // console.log("右移160位",test1.div((new BigNumber(2).pow(160))).toFixed())
//     // console.log("adress类型==》unit16进制",Web3.utils.hexToNumberString('0x5B38Da6a701c568545dCfcB03FcB875f56beddC4'))
//     // console.log(account)
//     // console.log("16进制unit地址==》 adress类型toHex",Web3.utils.toHex(address))
//     //地址转成16进制字符串，对应者，合约里面 address类型转成 unit类型
//   },[])

  return  <Box display="flex" flex={1} flexDirection="column">
            <Box display="flex" height="100px">
                <Header />
            </Box>
            {/* <Container pt="100px" display="flex" flex={1} flexDirection="column"> */}
            <Container display="flex" flex={1} flexDirection="column">
                <Outlet />
            </Container>
          </Box>

}

export default OwerPage;