import { useWallet } from '@binance-chain/bsc-use-wallet';
import { BottomNavigation, BottomNavigationAction, Box, Button, CircularProgress, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils'
import BeeTokenAbi from '../../../config/abi/BeeToken.json';
import BeeTokenByteCode from '../../../config/bytecode/BeeTokenByteCode.json';

import nftAbi from '../../../config/abi/nft.json';
import addresses from '../../../config/contract/nft';
import Header from '../header';
import DoneAllSharpIcon from '@material-ui/icons/DoneAllSharp';
import useWeb3 from '../../../hooks/useWeb3';



const FlexPanel = styled(Box)`
  display:flex;
`
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}


const BeenDeploy: React.FC =()=>{
    // const nftAddress = addresses.nftAddress
    // const web3 = new Web3(new Web3.providers.HttpProvider('https://moeing.tech:9545'));
    const {account} = useWallet();
    const web3 = useWeb3();
    
    const [pedding,setPedding] = useState(false);
    const [address,setAddress] = useState("");
    // useEffect(()=>{
    //     web3.eth.net.isListening().
    //      then(async (res)=>{
    //       const myContract=new web3.eth.Contract((nftAbi as unknown) as AbiItem,nftAddress)
    //       console.log('myContract',myContract) 
    //       const nftName=await myContract.methods.name().call()
    //       console.log('nftName:',nftName) 
    //      })
    //     console.log('web3============',web3)
    // },[])
 
  
    const deploy=async ()=>{
          setPedding(true)
          try {
            const myContract=new web3.eth.Contract((BeeTokenAbi as unknown) as AbiItem);
            const res=await myContract.deploy({data:BeeTokenByteCode.object}).send({
              from:account||"",
              gas: 2000000});
            console.log("结果",res?.options?.address)
            setAddress(res?.options?.address)
            setPedding(false)
          } catch (error) {
          }
       
         
    }

  return <Box display="flex" flex={1} flexDirection="column">
                 <FlexPanel>
                    <Button variant="contained" color="primary" onClick={deploy}>
                            部署BeenToken
                    </Button>
                    <FlexPanel ml={2} alignItems="center">
                        {pedding?<CircularProgress />:""}
                        {address!==""?<DoneAllSharpIcon  style={{color:'#14b714'}}></DoneAllSharpIcon>:""}
                    </FlexPanel>

                  </FlexPanel>
                  <FlexPanel flexDirection="column">
                    <h3 style={{"marginBlockEnd":0}}>合约地址:</h3>
                    <h4 style={{"marginBlockStart":0}}>{address}</h4>
                  </FlexPanel>
          </Box>

}

export default BeenDeploy;