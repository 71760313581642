import { useWallet } from '@binance-chain/bsc-use-wallet';
import BigNumber from 'bignumber.js';
import { useCallback, useEffect, useState } from 'react'
import { useBeeContract, useIceContract, useIceContract_static, useJigsawPuzzle } from '../../../hooks/useContract'
import useWeb3 from '../../../hooks/useWeb3';
import { useIceState } from '../../../state/Ice/hooks';
import { getIceOrWaterAddress, getJigsawPuzzleAbiAddress } from '../../../utils/addressHelpers';
import Web3 from 'web3';

export  function  useOwnerExists(selectBox:number):{
    ownerState:{iceOwner:{owner:string,amount:string,idx:number},waterOwner:{owner:string,amount:string,idx:number}},
    boxId:number,
    boxInfo:{iceAmount:string,waterAmount:string,dueTime:string,result:string}
    totalAddr:number
    }{
  const {account} = useWallet();
  const [ownerState, setOwnerState] = useState<any>({});
  const [boxId, setBoxId] = useState<number>(-1);
  const [boxInfo, setBoxInfo] = useState<any>({});
  const [totalAddr, setTotalAddr] = useState<number>(-1);

  const {pageRefreshState}=useIceState();
  const iceContract=useIceContract();

  useEffect(() => {
    const fetch = async () => {
        console.log("进来一次")
        try {
          // let index=null;
          // if(selectBox!=undefined && selectBox!=null){
          //   index=selectBox;
          //   // const res=await iceContract.methods.getBoxCount().call();
          //   // setBoxId(parseInt(res)-1);
          // }else{}
          setTotalAddr(-1);
          const boxitem=await iceContract.methods.boxList(selectBox).call();
          setBoxId(selectBox);
          setBoxInfo(boxitem);
          //获取地址是否参加押注
          const cont=await iceContract.methods.getEntryLists(selectBox).call();
          const iceIdx= cont.iceList.findIndex((t:any)=>t.depositor==account);
          const waterIdx= cont.waterList.findIndex((t:any)=>t.depositor==account);
  
          let resItem:any={};
          let waterItem:any={};
          if(iceIdx!=-1){
            resItem=JSON.parse(JSON.stringify(cont.iceList[iceIdx]));
            resItem['idx']=iceIdx;
            resItem['amount']=cont.iceList[iceIdx].amount;
            resItem['owner']=cont.iceList[iceIdx].owner;
          }
          if(waterIdx!=-1){
            waterItem=JSON.parse(JSON.stringify(cont.waterList[waterIdx]));
            waterItem['idx']=waterIdx;
            waterItem['amount']=cont.waterList[waterIdx].amount;
            waterItem['owner']=cont.waterList[waterIdx].owner;
          }

          setOwnerState({
              iceOwner:resItem,
              waterOwner:waterItem
          })
          // 计算所有地址
          const totalArray=cont.iceList.concat(cont.waterList);
          const address=totalArray.map((t:any)=>t.owner);
          let addrs:Array<any>=Array.from(new Set(address));
          setTotalAddr(addrs.length);
        } catch (error) {
          
        }
        
    }

    if (pageRefreshState || (selectBox!=null && selectBox!=undefined &&account)){fetch()} ;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectBox,pageRefreshState,setOwnerState,setBoxId,setBoxInfo,setTotalAddr,account])

  return {ownerState,boxId,boxInfo,totalAddr}
}


export  function  useHandFuc(){
  const {account} = useWallet();
  const iceContract=useIceContract();
 
  const handlePour = useCallback(
    async (boxId: number, Ifadditional:string,isWater:boolean,amount:string) => {
        try {
            console.log(Web3.utils.toHex("-1"))
            const cont=await iceContract.methods.deposit(boxId,Ifadditional,isWater,amount).send({from:account});
            return {state:true,res:cont}
        } catch (e) {
            return {state:false,res:e}
        }
    },[account, iceContract])


    const handleRecaption = useCallback(
      async (boxId: number, ownerIdex:number,isWater:boolean) => {
          try {
              const res=await iceContract.methods.withdraw(boxId,ownerIdex,isWater).send({from:account});
              return {state:true,res:res}
          } catch (e) {
              return {state:false,res:e}
          }
      },[account, iceContract])
     
    return {handlePour,handleRecaption}
}




export  function useIcehistoryList():{IcehistoryList:Array<any>}{
    const [IcehistoryList, setIceHistoryList] = useState<any[]>([])
    const IceContract=useIceContract();
    
    useEffect(() => {
      const fetch = async () => {
        const DepositList= await IceContract.getPastEvents('Deposit', {
            fromBlock: 0,
            toBlock: 'latest',
            filter:{}
        })
        console.log(DepositList)

        setIceHistoryList(DepositList.map(t=>({...t.returnValues,blockNumber:t.blockNumber})))
      }
  
      if (!IcehistoryList || !IcehistoryList.length) fetch()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setIceHistoryList])

    return {IcehistoryList}
}


export  function useIceApprove():{isApprove:boolean}{
    const [isApprove, setAllowanceState] = useState<boolean>(false);
    const beeContract=useBeeContract();
    const {account} = useWallet();
    const {pageRefreshState}=useIceState();
    useEffect(() => {
      const fetch = async () => {
        try {
            const allowance= await beeContract.methods.allowance(account,getIceOrWaterAddress()).call();
            const tokenAllowedStatus = allowance && !new BigNumber(allowance).isLessThan(new BigNumber(10).pow(77))
            setAllowanceState(tokenAllowedStatus)
        } catch (error) {}

      }
  
      if(account||pageRefreshState){
         fetch()
      } 
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setAllowanceState,account,pageRefreshState])

    return {isApprove}
}


export  function useWithdraw(totalAddr:number,boxId:number,ownerWaterIdx:number,ownerIceIdx:number):{enaring:{water:string,ice:string}}{
    const [enaring, setEnaring] = useState<{water:string,ice:string}>({
      water:"",
      ice:""
    })
    const iceContract=useIceContract_static();
    const {account} = useWallet();
    const {pageRefreshState}=useIceState();

    useEffect(() => {
      setEnaring({water:"",ice:""});
      const fetch = async () => {
        if(totalAddr!=-1 && account && (ownerWaterIdx!=undefined || ownerIceIdx!=undefined)){
          try {
            if(ownerWaterIdx!=undefined){
              const resAmount=await iceContract.methods.withdraw(boxId,ownerWaterIdx,true).call();
              setEnaring(pre=>({...pre,water:resAmount}))
            }
          } catch (error) {}
          try {
            if(ownerIceIdx!=undefined){
              const resIce=await iceContract.methods.withdraw(boxId,ownerIceIdx,false).call();
              setEnaring(pre=>({...pre,ice:resIce}))
            }
          } catch (error) {}
        }
      }
      
      if (ownerIceIdx ||ownerWaterIdx || (!enaring.water&&!enaring.ice)) fetch()
    }, [totalAddr,setEnaring,pageRefreshState,account,ownerIceIdx,ownerWaterIdx])

    return {enaring}
}


export  function  useBoxList():{
  IceBoxlist:Array<any>
  }{
const {account} = useWallet();
const [IceBoxlist, setIceBoxlist] = useState<any>([]);
const iceContract=useIceContract();
const web3 = useWeb3();
useEffect(() => {
  const fetchs = async () => {
      try {
        const res=await iceContract.methods.getBoxCount().call();
        const nums=[...Array(parseInt(res))].map((x,idx) => idx);
        
        const bolck=await web3.eth.getBlockNumber();
        const historyBlock=bolck-parseInt(`${(parseInt(10 as any)*86400)/5}`)
        const CreateBoxList=await iceContract.getPastEvents('CreateBox', {
          fromBlock: historyBlock,
          toBlock: 'latest',
          filter:{id:nums}
        })
          let newArr=CreateBoxList.map(p=>({...p.returnValues}));

          const boxShielding=await fetch('./boxShielding.json').then((response) => response.json())
          let boxNum = newArr.filter(opt => 
            !boxShielding.boxIds.find((t:any)=>t==parseInt(opt.id))
          )

          // boxNum=boxNum.filter(B=>parseInt(B.id)!=15);
          const data=await fetch('./owner.json').then((response) => response.json())
          // 区分owner盒子
          // boxNum=boxNum.reverse();
          let owenrArray=boxNum.filter(k=>k.creator==data.address1);
          owenrArray=owenrArray.map(t=>({...t,type:"owner"}))
          owenrArray=owenrArray.sort((a:any,b:any)=>{return parseInt(b.id)-parseInt(a.id)});
          // 其他盒子
          let elseArray=boxNum.filter(k=>k.creator!=data.address1);
          elseArray=elseArray.map(t=>({...t,type:"else"}))
          elseArray=elseArray.sort((a:any,b:any)=>{return parseInt(b.id)-parseInt(a.id)});

          const newArray=owenrArray.concat(elseArray);
  
          const listObj=newArray.map((t:any)=>{
            const attrs=t.intro.split("||");
            const zhTitle_s=attrs[0]?attrs[0]:"";
            const zhConet_s=attrs[1]?attrs[1]:"";
            // const enTitle_s=attrs[2]?attrs[2]:"";
            // const enConet_s=attrs[3]?attrs[3]:"";
            return {...t,zhTitle:zhTitle_s,zhConent:zhConet_s}
         })
         setIceBoxlist(listObj);

        
       
      } catch (error) {
        console.log(error)
      }
      
  }

  if ((IceBoxlist.length==0 && account)){fetchs()} ;

}, [setIceBoxlist,account])

return {IceBoxlist}
}
