import { Box, Button } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import useMatchBreakpoints from "../../hooks/useMatchBreakpoints";
import I18nPopover from "./i18nPopover";
import { useTranslation } from "react-i18next";
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList';
import { useWallet } from "@binance-chain/bsc-use-wallet";
import setupNetwork from "../../utils/wallt";
import { debounce } from "lodash";

const Center = styled(Box)<any>`
  background-image: url(/image/新建项目.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
  border-radius: 10px;
`;

const StylePanel=styled(Box)`
     height: 80px;
     background-color: #001634;
`

const ConnectBtn=styled(Box)`

    @media (max-width: 750px) {
       height: 0.48rem;
       font-size: 0.21rem;
       padding:0.03rem 0.13rem 0.03rem 0.13rem;
    }

    @media (min-width:750px){
       padding:0px 8px 0px 8px;
       height: 32px;
    }

    background: #235ba6;
    border-radius: 4px;
    display: flex;
    justify-content:center;
    align-items:center ;
    color:#fff ;
    cursor: pointer;
    :hover{
      background-color: #1c4aa3;
    }
`



const ConnectWallt: React.FC = () => {
  const { isDesktop, isMobile } = useMatchBreakpoints();
  const { t } = useTranslation();
  const wallet = useWallet();
  const account = useRef<any>();

  const connWall=async ()=>{
      if(wallet.status === 'connected'){
          wallet.reset();
          account.current="";
      }else{
         try {
           await wallet.connect('injected');
           // 检测是否连接成功
           setTimeout(async()=>{
            // console.log("account",account.current)
            if(!account.current){
              const res=await setupNetwork();
              if(res){wallet.connect('injected')};
            }
           },700)
         } catch (error) {
           await wallet.connect('injected');
         }
      }
  }



  const addrTran= (str:string|null):string=>{
    return `${str?.slice(0,6)}****${str?.slice(str.length-4,str.length)}`
  } 

  useEffect(()=>{
    if(wallet.account){
       account.current=wallet.account;
    }
  },[wallet.account])

  return (
     <ConnectBtn onClick={connWall}>
        <img src="./image/qianbao.png" alt="" width={'14px'} height={'14px'}></img>
        <Box ml={1}>{wallet.status === 'connected'?addrTran(wallet.account):t("Wallet")}</Box>
     </ConnectBtn>
  );
};

export default ConnectWallt;
