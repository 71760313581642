import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
// import { save, load } from 'redux-localstorage-simple'
import puzzles from './Puzzles/reducer'
import Ice from './Ice/reducer'
import Exchange from './Exchange/reducer'
import DrawBox from './DrawBox/reducer'
// type MergedState = {
//   user: {
//     [key: string]: any
//   }
//   transactions: {
//     [key: string]: any
//   }
// }
const PERSISTED_KEYS: string[] = ['user', 'transactions']
// const loadedState = load({ states: PERSISTED_KEYS }) as MergedState
// if (loadedState.user) {
//   loadedState.user.userDarkMode = getThemeCache()
// }

const store = configureStore({
  reducer: {
    puzzles,
    Ice,
    Exchange,
    DrawBox
  }
  // middleware: [...getDefaultMiddleware({ thunk: false }), save({ states: PERSISTED_KEYS }) as any],
  // preloadedState: loadedState as any,
})

// store.dispatch(updateVersion())

export default store
export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
