// import { UseWalletProvider } from '@binance-chain/bsc-use-wallet';
import * as bsc from '@binance-chain/bsc-use-wallet'
import ReactDOM from 'react-dom';
import App from './App';
import { HashRouter} from 'react-router-dom';
import Providers from './Providers'

// import  './style/flex.css'

ReactDOM.render(
  <Providers>
  <bsc.UseWalletProvider 
    chainId={10000}
    connectors={{ bsc , walletconnect: { rpcUrl:'https://global.uat.cash/' }}} 
    >
    <HashRouter>   
      <App />
    </HashRouter>
  </bsc.UseWalletProvider>
  </Providers>
  , document.getElementById('root')
);
