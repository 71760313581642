import { useCallback } from 'react'
import { Contract } from 'web3-eth-contract'
import { ethers } from 'ethers'
import { useWeb3React } from '@web3-react/core';
import { useWallet } from '@binance-chain/bsc-use-wallet';

// const gasPriceInObj = GAS_PRICE?{gasPrice:GAS_PRICE}:{};
const gasPriceInObj = {};
// Approve token in general
export  const useTokenApprove = (tokenContract: Contract, spenderAddress: string) => {
  const {account} = useWallet();
  const onApprove = useCallback(async () => {
    if (!(account && tokenContract && tokenContract.options.address && spenderAddress)) return false;

    try {
      const tx = await tokenContract.methods
        .approve(spenderAddress, ethers.constants.MaxUint256)
        .send({ ...gasPriceInObj, from: account })
      return tx
    } catch {
      return false;
    }
  }, [account, spenderAddress, tokenContract])

  return onApprove
}
export default useTokenApprove