import { useWallet } from '@binance-chain/bsc-use-wallet';
import { BottomNavigation, BottomNavigationAction, Box, Button, CircularProgress, Paper, Tab, Tabs, TextField, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils'
import LucyDrawAbi from '../../../config/abi/LucyDraw.json';
import BeeTokenByteCode from '../../../config/bytecode/BeeTokenByteCode.json';

import nftAbi from '../../../config/abi/nft.json';
import addresses from '../../../config/contract/nft';
import Header from '../header';
import DoneAllSharpIcon from '@material-ui/icons/DoneAllSharp';
import useWeb3 from '../../../hooks/useWeb3';
import BigNumber from 'bignumber.js';
import { getDrawAddress, getIceOrWaterAddress } from '../../../utils/addressHelpers';
import { ethers } from 'ethers';
import { useDrawApprove } from './hooks/useDraw';
import { useBeeContract, useDrawContract } from '../../../hooks/useContract';
import useTokenApprove from '../../../hooks/useApprove';
import { useDrawBoxHandlers } from '../../../state/DrawBox/hooks';



const FlexPanel = styled(Box)`
  display:flex;
`
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}


const GetBack: React.FC =()=>{
    const {account} = useWallet();
    const [pedding,setPedding] = useState(false);
    const [lotId,setLotId] = useState("");

    const [times,setTimes] = useState("");
    const [hash,setHash] = useState("");
    const [urlId,setUrlId] = useState("");
    const DrawContract= useDrawContract();

    const createHand=async ()=>{
          setPedding(true)
          try {
            const res=await DrawContract.methods.withdrawl(lotId).send({from:account||""});
            setHash(res.blockHash)
            setPedding(false)
          }catch(e){}
    }
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLotId(event.target.value);
    };
 
    
    return <Box display="flex" flex={1} flexDirection="column">
                 <FlexPanel flexDirection="column" style={{width:'50%'}}>
                   <TextField
                        id="prizes"
                        label="盲盒ID/Blind box ID"
                        type="prizes"
                        rows={4}
                        multiline
                        defaultValue=""
                        onChange={handleChange}
                    />


          

                    <FlexPanel mt={2} alignItems="center">
                        <Button variant="contained" color="primary" onClick={createHand}>
                               取回押金/Get back the deposit
                        </Button>
                       
                        <Box ml={2} mt={2}></Box>
                        {pedding?<CircularProgress />:""}
                        {hash!==""?<DoneAllSharpIcon  style={{color:'#14b714'}}></DoneAllSharpIcon>:""}
                    </FlexPanel>
                    
                    <FlexPanel flexDirection="column">
                        <h3 style={{"marginBlockEnd":0}}>transaction hash:</h3>
                        <h4 style={{"marginBlockStart":0}}>{hash}</h4>
                    </FlexPanel>

                    </FlexPanel>
          </Box>

}

export default GetBack;