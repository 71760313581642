import { Box, Button, createStyles, makeStyles, Modal, Theme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useMatchBreakpoints from "../../hooks/useMatchBreakpoints";
import { useTranslation } from "react-i18next";
import formatDate from "../../utils/timeTran";
import SendModal from "./SendModal";
import { useWallet } from "@binance-chain/bsc-use-wallet";
import { useBoxTime } from "./hooks/useBox";
import tranId from '../../utils/tran'

const SendBtn=styled(Box)`
    padding: 8px 30px 8px 30px;
    background-color: #6DB90E;
    border-radius: 20px;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
    :hover{
        background-color: #436e0f;
    }
`

const StyleModal=styled(Box)<{isMobile:boolean}>`
  padding:${(props) => (props.isMobile?"0px 0px 0px 0.31rem":"0px 16px 0px 38px")};
`
const Flex=styled(Box)`
  display: flex;
`

const RightLog=styled(Flex)`
  height: 20px;
  width: 6px;
  background: #0058cd;
`
const RightLogText=styled(Flex)`
    font-size: 24px;
    font-family: SourceHanSansCN, SourceHanSansCN-Heavy;
    font-weight: 800;
    color: #fff;
`

const ModalConent=styled(Flex)<{isMobile:boolean}>`
      position: absolute;
      width: ${(props) => (props.isMobile?"89%":"")};
      /* height:480px; */
      border: '2px solid #000';
      box-shadow: 5px;
      outline:0;
      // padding: theme.spacing(2, 4, 3),
      background-color:#061E3E;
      font-size: ${(props) => (props.isMobile?"12px":"")};
      .fontSizeMedal{
        font-size: ${(props) => (props.isMobile?"0.18rem":"18px")};
      }
      .font24SizeMedal{
        font-size: ${(props) => (props.isMobile?"0.24rem":"24px")};
      }
      .closeImg{
        width:${(props) => (props.isMobile?"0.24rem":"24px")};
        height:${(props) => (props.isMobile?"0.24rem":"24px")};
      }
`

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    paper: {
      position: 'absolute',
      width: "50%",
      height:"480px",
      // backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      outline:0,
      // padding: theme.spacing(2, 4, 3),
      backgroundColor: "#061E3E"
    },
  }),
);
interface ImgInfoModel{
  url?: number
  isopen:boolean
  setOpens:(e: any)=>void
  lotTimeHistory:Array<any>
  lotIdAndIdx:{
    address:string,
    lotId:number,
    spId:number,
    dueTime:number
  }
}


const ImgInfoModel: React.FC<ImgInfoModel> = ({url,isopen,setOpens,lotIdAndIdx,lotTimeHistory}) => {
  const { isDesktop, isMobile } = useMatchBreakpoints();
  const { t } = useTranslation();
  const classes = useStyles();
  const {account} = useWallet();
  const {historyList}=useBoxTime(lotIdAndIdx.lotId,lotIdAndIdx.spId);
  // const handImg=()={}
  const [open,setOpen]=useState(isopen);

  

  
  return (<Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isopen}
        onClose={()=>{setOpens(false)}}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        {/* <Fade in={open}> */}
          <ModalConent pb={5}  isMobile={!isDesktop}>
            <Box display="flex" flex={1} flexDirection="column">
              <Box style={{cursor: 'pointer'}} onClick={()=>setOpens(false)} mt={2} mr={2} display='flex' alignItems='center' justifyContent='right'>
                <img  className="closeImg" src="./image/close.png"/>
                <Box ml={1} className="font24SizeMedal" component='span' color="#fff"  flex={'display'}>{t("CLOSE")}</Box>
              </Box>
              <StyleModal mt={1} isMobile={!isDesktop} display='flex'>
                   <Flex 
                    width={!isDesktop?'2.92rem':'404px'}
                    height={!isDesktop?'2.92rem':'404px'}>
                      {lotIdAndIdx.dueTime>new Date().getTime()?
                       <img src="./image/unkomn.png" width="100%" height="100%" alt="" />
                       :
                       <img src={`https://blindbox.cash/jigsaw/${lotIdAndIdx.lotId}/piece${tranId(lotIdAndIdx.spId)}.jpg`} width="100%" height="100%" alt="" />
                      }
                    </Flex>
                   
                   <Flex 
                   ml={!isDesktop?1:4} 
                   mt={!isDesktop?"":'46px'} 
                   display='flex' 
                   flex={1} 
                   alignItems={!isDesktop?"":'baseline'} >
                    <RightLog></RightLog>
                    <Flex ml='10px' flex={1} justifyContent="space-between" height="100%"  flexDirection="column">
                      <Box>
                        <RightLogText  fontSize={!isDesktop?"0.24rem":'24px'}  component='span'>{t("DETAILS OF THE BLOCK")}</RightLogText>
                        <Flex mt={5}   flexDirection="column" >
                            <Flex  component='span'>
                              <Flex className="fontSizeMedal" fontWeight='bold' color="#fff"   component='span'>{t("PIECE ID:")}</Flex>
                              <Flex ml={2} color="#006DFF" component='span' flexDirection="column">{lotIdAndIdx.lotId+"-"+lotIdAndIdx.spId}</Flex>
                            </Flex>
                            <Flex  mt={4} component='span' flexDirection="column">
                              <Flex className="fontSizeMedal" fontWeight='bold' color="#fff"  component='span'>{t("GOT TIME:")}</Flex>
                              <Flex  color="#006DFF" component='span' flexDirection="column">
                                {historyList.map((t:any)=>(
                                  <Box key={t.timestamp} component="span">
                                    {formatDate(parseInt(t.timestamp)*1000)}
                                  </Box>
                                  
                                  ))}
                                </Flex>
                            </Flex>
                      
                        </Flex>
                      </Box>
                    
                      
                      <Flex justifyContent="end" pt={1} pr={1}>
                        {
                         lotIdAndIdx.address==account?<SendBtn onClick={()=>{setOpen(true)}}>{t("Send")}</SendBtn>:""
                        }
                      </Flex>
                      
                      <SendModal 
                       isopen={open} 
                       setOpens={setOpen}
                       lotIdAndIdx={lotIdAndIdx}></SendModal>
                    </Flex>
                   </Flex>
              </StyleModal>
            </Box>
          </ModalConent>
        {/* </Fade> */}
      </Modal>
  
  );
};

export default ImgInfoModel;
