import { useWallet } from '@binance-chain/bsc-use-wallet';
import BigNumber from 'bignumber.js';
import { useCallback, useEffect, useState } from 'react'
import { useBeeContract, useIceContract, useIceContract_static, useJigsawPuzzle } from '../../../hooks/useContract'
import useWeb3 from '../../../hooks/useWeb3';
import { useIceState } from '../../../state/Ice/hooks';
import { getBchAddress, getBeeTokenAddress, getfactorytAddress, getIceOrWaterAddress, getimptAddress, getJigsawPuzzleAbiAddress } from '../../../utils/addressHelpers';
import Web3 from 'web3';
import { ethers } from 'ethers';
import { unpackPrice } from '../../../utils/robo';
import factoryContractAbi from '../../../config/abi/factoryContract.json';
import RobotAbi from '../../../config/abi/Robot.json';

import { AbiItem } from 'web3-utils';
import { useExchangeState } from '../../../state/Exchange/hooks';

export  function  useOwnerExists():{
    ownerState:{iceOwner:{owner:string,amount:string,idx:number},waterOwner:{owner:string,amount:string,idx:number}},
    boxId:number,
    boxInfo:{iceAmount:string,waterAmount:string,dueTime:string,result:string}
    totalAddr:number
    }{
  const {account} = useWallet();
  const [ownerState, setOwnerState] = useState<any>({});
  const [boxId, setBoxId] = useState<number>(-1);
  const [boxInfo, setBoxInfo] = useState<any>({});
  const [totalAddr, setTotalAddr] = useState<number>(-1);

  const {pageRefreshState}=useIceState();
  const iceContract=useIceContract();

  useEffect(() => {
    const fetch = async () => {
        console.log("进来一次")
        try {
          const res=await iceContract.methods.getBoxCount().call();
          setBoxId(parseInt(res)-1);
          const boxitem=await iceContract.methods.boxList(parseInt(res)-1).call();
          setBoxInfo(boxitem);
          //获取地址是否参加押注
          const cont=await iceContract.methods.getEntryLists(parseInt(res)-1).call();
          const iceIdx= cont.iceList.findIndex((t:any)=>t.owner==account);
          const waterIdx= cont.waterList.findIndex((t:any)=>t.owner==account);
  
          let resItem:any={};
          let waterItem:any={};
          if(iceIdx!=-1){
            resItem=JSON.parse(JSON.stringify(cont.iceList[iceIdx]));
            resItem['idx']=iceIdx;
            resItem['amount']=cont.iceList[iceIdx].amount;
            resItem['owner']=cont.iceList[iceIdx].owner;
          }
          if(waterIdx!=-1){
            waterItem=JSON.parse(JSON.stringify(cont.waterList[waterIdx]));
            waterItem['idx']=waterIdx;
            waterItem['amount']=cont.waterList[waterIdx].amount;
            waterItem['owner']=cont.waterList[waterIdx].owner;
          }

          setOwnerState({
              iceOwner:resItem,
              waterOwner:waterItem
          })
          // 计算所有地址
          const totalArray=cont.iceList.concat(cont.waterList);
          const address=totalArray.map((t:any)=>t.owner);
          let addrs:Array<any>=Array.from(new Set(address));
          setTotalAddr(addrs.length);
        } catch (error) {
          
        }
        
    }

    if (pageRefreshState || (boxId==-1&&account)){fetch()} ;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageRefreshState,setOwnerState,setBoxId,setBoxInfo,setTotalAddr,boxId,account])

  return {ownerState,boxId,boxInfo,totalAddr}
}





export  function useBeeApprove(markAddress:string):{isApprove:boolean}{
  const [isApprove, setAllowanceState] = useState<boolean>(false);
  const beeContract=useBeeContract();
  const {account} = useWallet();
  const {pageRefreshState}=useExchangeState();
  useEffect(() => {
    const fetch = async () => {
      try {
          const allowance= await beeContract.methods.allowance(account,markAddress).call();
          const tokenAllowedStatus = allowance && !new BigNumber(allowance).isLessThan(new BigNumber(10).pow(77))
          setAllowanceState(tokenAllowedStatus)
      } catch (error) {}

    }

    if((account||pageRefreshState) && markAddress!=""){
       fetch()
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAllowanceState,account,markAddress,pageRefreshState])

  return {isApprove}
}




export  function useAllRobots():{list:Array<any>,markAddress:string} {
  const [list, setList] = useState<any>([]);
  const [markAddress, setMarkAddress] = useState<any>("");
  const {account} = useWallet();
  const web3 = useWeb3();
  useEffect(()=>{
     const feth=async ()=>{
      // var factoryContract = new ethers.Contract(FactoryAddr, FactoryABI, provider).connect(signer)
      // window.MarketAddress = await factoryContract.getAddress(stockAddr, moneyAddr, ImplAddr)
      try {
        const factoryContract=new web3.eth.Contract((factoryContractAbi as unknown) as AbiItem,getfactorytAddress());
        const MarketAddress = await factoryContract.methods.getAddress(getBeeTokenAddress(), getBchAddress(), getimptAddress()).call();
        const CCABI = [
          "function getAllRobots() view public returns (uint[] memory robotsIdAndInfo)",
          "function createRobot(uint robotInfo) external payable",
          "function deleteRobot(uint index, uint robotId) external",
          "function sellToRobot(uint robotId, uint stockDelta) external payable",
          "function buyFromRobot(uint robotId, uint moneyDelta) external payable",
        ]
        const provider = new ethers.providers.Web3Provider((window as any).ethereum)
        const marketContract = new ethers.Contract(MarketAddress, CCABI, provider)
        var allRobotsArr = await marketContract.getAllRobots()
        // const marketContract=new web3.eth.Contract((RobotAbi as unknown) as AbiItem,MarketAddress);
        // var allRobotsArr = await marketContract.methods.getAllRobots().call();
        // console.log('allRobotsArr:', allRobotsArr);
        var allRobots = []
        var twoPow32 = ethers.BigNumber.from(2).pow(32);
        var twoPow96 = ethers.BigNumber.from(2).pow(96);
        (window as any).RobotsMap = {}
        for(var i=0; i<allRobotsArr.length; i+=2) {
            var fullId = allRobotsArr[i]
            // console.log('i:', i, 'fullId:', fullId);
            var robot:any = {fullId: fullId.toHexString(), index: i/2}
            robot.shortId = fullId.mod(twoPow96).toNumber()
            robot.ownerAddr = ethers.utils.getAddress(fullId.div(twoPow96).toHexString())
            // if(onlyForAddr && onlyForAddr != robot.ownerAddr) {continue}
            var info = allRobotsArr[i+1]
            robot.lowPrice = ethers.utils.formatUnits(unpackPrice(info.mod(twoPow32))) as any *1.0
            info = info.div(twoPow32)
            robot.highPrice = ethers.utils.formatUnits(unpackPrice(info.mod(twoPow32))) as any*1.0
            info = info.div(twoPow32)
            robot.moneyAmountBN = info.mod(twoPow96)
            robot.stockAmountBN = info.div(twoPow96)
            robot.moneyAmount = ethers.utils.formatUnits(robot.moneyAmountBN, (window as any).moneyDecimals) as any*1.0;
            robot.stockAmount = ethers.utils.formatUnits(robot.stockAmountBN, (window as any).stockDecimals) as any*1.0;
            allRobots.push(robot);
            (window as any).RobotsMap[robot.fullId] = robot
        }
        setList(allRobots);
        setMarkAddress(MarketAddress);
      } catch (error) {
        console.log(error)
      }
      
     }
     if(list.length==0 && account!=""){
        feth();
     }
  },[list,setList,setMarkAddress,account])
  return {list,markAddress}
}