import { Box, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useMatchBreakpoints from "../../hooks/useMatchBreakpoints";
import I18nPopover from "./i18nPopover";
import { useTranslation } from "react-i18next";
import { useIcehistoryList } from "./hooks/useHome";
import BigNumber from "bignumber.js";

const Center = styled(Box)<any>`
  background-image: url(/image/新建项目.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
  border-radius: 10px;
`;


const ListTableBox=styled(Box)`
    @media (max-width: 750px) {
       font-size: 0.18rem;
    }
    @media (min-width:750px){
       /* padding:0px 8px 0px 8px;
       height: 32px; */
    }
`

const StyleTotalText=styled(Box)`
     font-size:18px;
     color:#fff;
`
const StyleTotalTips=styled(Box)`
     font-size:18px;
     color:#9CA5B2;
`

const StyleBtn=styled(Box)<{ isMobile?: boolean }>`
    width: ${(props) => (props.isMobile?"1.4rem":"140px")};
    height:${(props) => (props.isMobile?"0.48rem":"48px")};
    font-size: ${(props) => (props.isMobile?"0.18rem":"16px")};
    background-color: #194c95;
    color:#fff;
    justify-content:center ;
    align-items: center;
`

const StyeleTable=styled(Box)`
     /* height: 560px; */
    
     @media (max-width: 750px) {
      max-height:3.36rem;
     }
     @media (min-width:750px){
      max-height:560px;
     }
     
     word-break: break-all;
     overflow-y:scroll;
     ::-webkit-scrollbar {
      width: 2px;
      height: 10px;
     /* scrollbar-arrow-color:#f0f0f0; */
    }
  /*滚动条里面小方块*/
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    box-shadow: inset 0 0 2px #f0f0f0;
    background: rgba(0,0,0,0.2);
    /* scrollbar-arrow-color:#898989; */
  }
  /*滚动条里面轨道*/
  ::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2); */
    border-radius: 0;
    background: rgba(0,0,0,0.1);
  }
`
const StyleTh=styled(Box)`
  font-weight:bold;
  width:33%;
  color: #fff;

  text-align:left;
  background-color:#00265C ;
    @media (max-width: 750px) {
      padding:0.15rem 0px 0.15rem  0px;
    }
    @media (min-width:750px){
      padding:14px 0px 14px 0px;
    }
`
const StyleTr=styled(Box)`
  @media (max-width: 750px) {
      padding:0.15rem 0px 0.15rem  0px;
   }
  @media (min-width:750px){
      padding:10px 0px 10px 0px;
  }
  color: #fff;

  background-color:#011532;
  text-align:left;
  display:flex;
  align-items:center ;
  overflow: auto;
  word-break: normal;

  ::-webkit-scrollbar {
    width: 2px;
    height: 5px;
    /* scrollbar-arrow-color:#f0f0f0; */
  }
  /*滚动条里面小方块*/
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    box-shadow: inset 0 0 2px #f0f0f0;
    background: rgba(0,0,0,0.2);
    /* scrollbar-arrow-color:#898989; */
  }
  /*滚动条里面轨道*/
  ::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2); */
    border-radius: 0;
    background: rgba(0,0,0,0.1);
  }

`


const ListTable: React.FC = () => {
  const { isDesktop, isMobile } = useMatchBreakpoints();
  const { t } = useTranslation();
  const { IcehistoryList } = useIcehistoryList();
  const tranPrice=(priceOfBox:string)=>{
    return (new BigNumber(priceOfBox).div(new BigNumber(10).pow(18))).toFixed();
  }
  
  const btnData=[t("Highestbet")];
  return (
    <ListTableBox  display="flex"   flexDirection='column'>
            <Box 
             flexDirection={isDesktop?"row":"column"}
             display="flex"
             flex={1}  
          
             justifyContent="space-between">
                <Box display="flex">
                    {
                    btnData.map(t=>(
                        <StyleBtn isMobile={!isDesktop} display="flex">
                        {t}
                        </StyleBtn>
                    ))
                }
                </Box>
                <Box  
                 mt={isDesktop?0:"0.24rem"}
                 display="flex"   
                 alignItems="center">
                    <StyleTotalText fontSize={isDesktop?"18px":"0.18rem"}>
                      {(IcehistoryList||[]).length}
                    </StyleTotalText>
                    <StyleTotalTips fontSize={isDesktop?"18px":"0.18rem"} ml={1}>
                       {t("TotalBetting")}
                    </StyleTotalTips>
                </Box>
            </Box>
         

           <Box mt={3}>
               <Box display='flex'>
                        <StyleTh flex={1} pl={!isDesktop?1:5}>{t("address")}</StyleTh>
                        <StyleTh flex={1}>{t("blockNumber")}</StyleTh>
                        <StyleTh flex={1}>{t("token number")}</StyleTh>
                    </Box>
                    <StyeleTable  display='flex' flexDirection="column">

                    {/* event Deposit(uint indexed id, address indexed depositor, bool isWater, uint amount); */}
                        {(IcehistoryList||[]).map((m)=>(
                            <Box key={m.address} display='flex' flex={1}>
                                    <StyleTr flex={1} pl={!isDesktop?1:5} style={{'width':'0'}}>
                                        <a title={m.depositor}> {m.depositor}</a>
                                       
                                    </StyleTr>
                                    <StyleTr flex={1} pl={1}>{m.blockNumber}</StyleTr>
                                    <StyleTr flex={1} >{tranPrice(m?.amount)}</StyleTr>
                           </Box>
                        ))}
                    </StyeleTable>
           </Box>
          
    </ListTableBox>
    
  );
};

export default ListTable;
