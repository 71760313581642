import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, AppState } from '../index'
import { roladPage } from './actions'

export function usePuzzleState(): AppState['puzzles'] {
  return useSelector<AppState, AppState['puzzles']>((state) => state.puzzles)
}

export function usePuzzleHandlers(): {
  onIsRefresh:(isRefresh:boolean)=>void
} {
  const dispatch = useDispatch<AppDispatch>()
  const onIsRefresh = useCallback((isRefresh:boolean) => {
    dispatch(roladPage({isRefresh}))
  }, [dispatch])

  return { onIsRefresh }
}
