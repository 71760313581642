import { useWallet } from '@binance-chain/bsc-use-wallet';
import BigNumber from 'bignumber.js';
import { useEffect, useState } from 'react'
import { useBeeContract, useJigsawPuzzle } from '../../../hooks/useContract'
import useWeb3 from '../../../hooks/useWeb3';
import { usePuzzleState } from '../../../state/Puzzles/hooks';
import { getJigsawPuzzleAbiAddress } from '../../../utils/addressHelpers';

export  function  useBox():{boxList:any[]}{
  const [boxList, setBoxList] = useState<any[]>([])
  const PuzzleContract=useJigsawPuzzle();
  const {pageRefreshState}=usePuzzleState();
  const {account}=useWallet();
  useEffect(() => {
    const fetch = async () => {
        let list=[];
        const cont=await PuzzleContract.methods.getLotCount().call();
        if(cont!=0){
            const nums=[...Array(parseInt(cont))].map((x,idx) => idx);

            list = await Promise.all(
                nums.map(i => PuzzleContract.methods.lotInfoList(i).call().then((data:any)=>data))
            )
            const ownerList = await Promise.all(
                nums.map(i => PuzzleContract.methods.getOwnerList(i).call().then((data:any)=>data))
            )

            for(let [index,value] of  (list as any).entries()){
                const  shenyu=(value.totalBoxCount*value.jigsawPieceCountInEachBox)-ownerList[index].length;
                value['residue']=shenyu/value.jigsawPieceCountInEachBox;
            }

        }
        setBoxList(list)
    }

    if ((pageRefreshState|| !boxList || !boxList.length) && (account!=undefined && account!=null)) fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setBoxList,pageRefreshState,account])

  return {boxList}
}

export  function  useOwenerList():{ownerList:any[]}{
    const [ownerList, setOwnerList] = useState<any[]>([])
    const PuzzleContract=useJigsawPuzzle();
    const {account} = useWallet();
    const {pageRefreshState}=usePuzzleState();
    useEffect(() => {
      const fetch = async () => {
          let restus=[];
          let list=[];
          let originals=[];
          try {
            const cont=await PuzzleContract.methods.getLotCount().call();
            if(cont!=0){
              // const nums=[...Array(parseInt(cont))].map((x,idx) => idx);
              const nums=[parseInt(cont)-1];
              const lots = await Promise.all(
                  nums.map(i => PuzzleContract.methods.lotInfoList(i).call().then((data:any)=>data))
              )
              const ownerList = await Promise.all(
                  nums.map(i => PuzzleContract.methods.getOwnerList(i).call().then((data:any)=>data))
              )
            
              for(let [index,value] of  (lots as any).entries()){
                  // const  shenyu=(value.totalBoxCount*value.jigsawPieceCountInEachBox)-ownerList[index].length;
                  // value['residue']=shenyu/value.jigsawPieceCountInEachBox;
  
                    originals.push(ownerList[index]);
                    list.push(ownerList[index].map((t:any,idx:number)=>({
                      dueTime:parseInt(value.dueTime)*1000,
                      id:nums[0],
                      index:idx,
                      value:t
                    })));
              }
  
  
                let addrs:Array<any>=Array.from(new Set(originals.flat()))
                let addrObj:any={}
                for(let s of addrs){
                   addrObj[s]=[];
                }
                
                for(let p of addrs){
                   
                   for(let [index,value] of (list as any).entries()){
                       const items=value.filter((t:any)=>t.value==p);
                       const attr=items.map((b:any)=>({
                        lotId:b.id,
                        spId:b.index,
                        dueTime:b.dueTime
                       }));
                       addrObj[p]=addrObj[p].concat(attr)
                   }
                   
                }
                
  
               
                for(let a of Object.keys(addrObj)){
                    if(a==account){
                      restus.unshift({address:a,lots:addrObj[a]})
                      // restus.unshift({address:a,lots:addrObj[a].concat(addrObj[a])})
                    }else{
                      restus.push({address:a,lots:addrObj[a]})
                    }
                    
                }
  
  
                console.log("restus",restus)
               
                  
            }
            setOwnerList(restus)
          } catch (error) {}
      }
  
      if (account || pageRefreshState || !ownerList || !ownerList.length) fetch()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setOwnerList,pageRefreshState,account])
  
    return {ownerList}
}


export  function useBoxTime(lotId:number,spId:number):{historyList:Array<any>}{
    const [historyList, setHistoryList] = useState<any[]>([])
    const PuzzleContract=useJigsawPuzzle();
    const web3 = useWeb3();
    
    useEffect(() => {
      const fetch = async () => {
        // const UnsetQualified= await 
        console.log("lotIdAndPieceIndex",(new BigNumber(lotId).
              times(new BigNumber(2).pow(64)).
              plus(new BigNumber(spId)).toFixed()))
        PuzzleContract.getPastEvents('Transfer', {
            fromBlock: 0,
            toBlock: 'latest',
            filter:{lotIdAndPieceIndex:[(new BigNumber(lotId).times(new BigNumber(2).pow(64)).plus(new BigNumber(spId)).toFixed())]}
        }).then(async (UnsetQualified)=>{
          console.log(UnsetQualified)

          // console.log("testst",new BigNumber("18446744073709551616").div(new BigNumber(2).pow(64)).toFixed())
          

          const history = await Promise.all(
              UnsetQualified.map(p => web3.eth.getBlock(p.blockNumber).then((data:any)=>data))
          )
          setHistoryList(history)
        })
   
      }
  
      if (lotId!==undefined&&lotId!==null && spId!=undefined && spId!=null) fetch()
    }, [setHistoryList,lotId,spId])

    return {historyList}
}


export  function useOwnerApprove():{isApprove:boolean}{
    const [isApprove, setAllowanceState] = useState<boolean>(false);
    const beeContract=useBeeContract();
    const {account} = useWallet();
    const {pageRefreshState}=usePuzzleState();
    useEffect(() => {
      const fetch = async () => {
        try {
            const allowance= await beeContract.methods.allowance(account,getJigsawPuzzleAbiAddress()).call();
            const tokenAllowedStatus = allowance && !new BigNumber(allowance).isLessThan(new BigNumber(10).pow(77))
            setAllowanceState(tokenAllowedStatus)
        } catch (error) {}

      }
  
      if(account||pageRefreshState){
         fetch()
      } 
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setAllowanceState,account,pageRefreshState])

    return {isApprove}
}