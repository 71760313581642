import { Box, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import LoginButton from "../Login/Login";
import WhatshotIcon from "@material-ui/icons/Whatshot";
import MenuIcon from "@material-ui/icons/Menu";
import ButtonAppBar from "./ButtonAppBar";
import useMatchBreakpoints from "../../hooks/useMatchBreakpoints";
import I18nPopover from "./i18nPopover";
import { useTranslation } from "react-i18next";
import ConnectWallt from "./ConnectWall";
import Menu from "./Menu";

const Center = styled(Box)<any>`
  background-image: url(/image/新建项目.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
  border-radius: 10px;
`;
const ImgCenter = styled.img`
  width: 130px;
  height: 130px;
`;
const ImgMint = styled.img`
  padding: 15px;
`;
const HeaderContainer = styled(Box)`
  height: 100px;
  color: #fff;
  background: black;
  border-bottom: 1px solid #3c3c3c;
  margin-bottom: 2px;
  position: fixed;
  width: 100%;
`;
const BoxMenu = styled(Box)``;
const MenuLink = styled(Link)`
  display: flex;
  margin-left: 20px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  text-decoration: none;
  margin-right: 20px;
`;

const StyleHeader=styled(Box)<{ isMobile?: boolean }>`
     height:${(props) => (props.isMobile ? "60px" : "60px")} ;
     background-color: #001634;
`

const BoxImage=styled(Box)<{ isMobile?: boolean }>`
  /* width: ${(props) => (props.isMobile?"0.32rem":"24px")};
  height: ${(props) => (props.isMobile?"0.27rem":"24px")};
  padding:${(props) => (props.isMobile ? "0rem 0.31rem 0rem 0.28rem" : "18px 24px 18px 24px")} ; */
`
const StyleWalltBtn=styled(Box)`
   /* padding:14px 28px 14px 36px; */
   /* margin-right: 28px; */
`

const LogBox=styled(Box)`
  @media (max-width: 750px) {
       height: 0.54rem;
       width: 1.62rem;
  }

  @media (min-width:750px){
      height: 36px;
      width:  108px;
  }
`
const StyleBox = styled.img`


  /* width: 32px;
  height: 32px; */
`;

const Header: React.FC = () => {
  const { isDesktop, isMobile } = useMatchBreakpoints();
  const { t } = useTranslation();
  return (
    <StyleHeader alignItems='center' isMobile={!isDesktop} pr={3} display="flex">
         <BoxImage isMobile={!isDesktop} display="flex">
           <Menu></Menu>
         </BoxImage>
         <Box display="flex" flex={1} alignItems="center" justifyContent="space-between">
             <LogBox display="flex" >
                <img src="./image/log.png" alt="" width='100%' />
             </LogBox>
             <Box display="flex" alignItems='center'>
                 <I18nPopover></I18nPopover>
             </Box>
         </Box>
         <StyleWalltBtn ml={2} display="flex"  alignItems='center'>
            <ConnectWallt></ConnectWallt>
         </StyleWalltBtn>
    </StyleHeader>
  );
};

export default Header;
