import { Box, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useMatchBreakpoints from "../../hooks/useMatchBreakpoints";
import I18nPopover from "./i18nPopover";
import { useTranslation } from "react-i18next";


const StylePanel=styled(Box)<{ isDesktop?: boolean }>`
     background-color:${(props) => (props.isDesktop?'#021734':'#061E3E ')};
     /* height:${(props) => (props.isDesktop?'80px':'auto')};
     flex-shrink:0;
     align-items: center;
     padding-bottom:0.5rem ; */

     @media (max-width: 750px) {
        padding:0.2rem 0 0.2rem 0;
      }

      @media (min-width:750px){
         padding:28px 0 28px 0;
      }
`

const RightPanel=styled(Box)<{ isMobile?: boolean }>`
    /* margin-left:243px; */
    /* padding-right:${(props) => (props.isMobile?'30px':'0px')}; */
    /* position:${(props) => (props.isMobile?'none':'absolute')};
    right: ${(props) => (props.isMobile?'24px':'3rem')}; */
`
const Flex=styled(Box)`
     display: flex;
`
const RelationText=styled(Flex)`
     display: flex;
     font-size: 12px;
     color:#fff;
     margin-top: 2px;
`
const RelationImg=styled.img`
     width: 29px;
     height: 29px;
     cursor: pointer;
`
const VersionTitle=styled(Flex)`
      @media (max-width: 750px) {
          width:  2.72rem;
          /* height: 0.62rem; */
      }

      @media (min-width:750px){
          width:  326px;
          /* height: 74px; */
      }
`
const FootPanel=styled(Flex)`
      @media (max-width: 750px) {
          img{
            width:  0.53rem;
            height: 0.62rem;
          }
      }

      @media (min-width:750px){
          img{
            width:  63px;
            height: 74px;
          }
      }
`



const Footer: React.FC = () => {
  const { isDesktop, isMobile } = useMatchBreakpoints();
  const { t } = useTranslation();

  return (
    <>
      {
        !isDesktop?<>
            <Flex pt="0.4rem" pb="0.12rem" justifyContent="center" style={{backgroundColor:"#021734"}}>
    

              <Flex  alignItems="center" flexDirection="column" justifyContent="center">
                <Flex>
                  <Flex >
                    <img src="./image/home/bchSwarm.png" alt="" style={{width:"1.88rem",height:"0.36rem"}} />
                  </Flex>
                  <Flex ml={'14px'} mt={"-0.11rem"}>
                    <img src="./image/home/bchHome.png" alt="" style={{width:"1.41rem",height:"0.56rem"}}/>
                  </Flex>
                </Flex>

                <Flex mt='0.24rem'>
                  <img src="./image/home/smartbch.png" alt="" style={{width:"1.28rem",height:"0.34rem"}}/>
                  <Flex ml={'21px'}  pt={'0.04rem'}>
                    <img src="./image/home/greenlog.png" alt="" style={{width:"1.55rem",height:"0.18rem"}}/>
                  </Flex>
                </Flex>
                <Flex component="span" fontSize="0.16rem" color="#bcbcbc" mt={"0.15rem"}>
                  <img src="./image/home/18+.png" alt="" style={{width:"0.2rem",height:"0.2rem",marginRight:"0.16rem"}}/>
                  {t("Warning: Gambling can be addictive")}
              
                </Flex>

                <Flex mt='0.18rem' justifyContent="space-between">

                  {/* <a style={{"display":"flex"}} >
                    <img src="./image/home/wx.png" alt=""   style={{marginLeft:"0.29rem",width:"0.36rem",height:"0.36rem"}}/>
                  </a> */}
                  <a style={{"display":"flex"}}   href="https://discord.gg/Y6JhcDVdeg"  >
                    <img src="./image/home/dis.png" alt=""  style={{marginLeft:"0.29rem",width:"0.36rem",height:"0.36rem"}} />
                  </a>
                  <a style={{"display":"flex"}}  href="https://twitter.com/bch_sun?t=fJv3tHeJfQ7e2MDJOFC1nw&s=09">
                   <img src="./image/home/tuite.png" alt="" style={{marginLeft:"0.29rem",width:"0.36rem",height:"0.36rem"}} />

                  </a>
                  <a style={{"display":"flex"}}  href="https://t.me/bchchinese" >
                   <img src="./image/home/tg.png" alt=""  style={{marginLeft:"0.29rem",width:"0.36rem",height:"0.36rem"}} />
                   </a>
                </Flex>
              </Flex>
            </Flex>
             <StylePanel isDesktop={isDesktop}  display="flex" alignItems="center"  justifyContent={"center"} >
     
             <FootPanel
                     
                     ml={isDesktop?0:2}
                     mr={isDesktop?0:2}
                     flexDirection={"row"} 
                     alignItems={"center"}>
                 <img src="./image/bouttumLog.png" alt="" width="108px"  height="36px"/>
                 <VersionTitle
                   mt={"0.2rem"} 
                   style={{width:"auto"}}
                   pl="25px"
                   fontSize={"0.14rem"}
                   component={'span'} 
                   fontFamily="SourceHanSansCN, SourceHanSansCN-Light"
                   color="#bcbcbc" >
                     <Flex component={"span"}>
                     {t("Allcopyright")} &#169 2016-2022 Smein Hosting N.V. Abraham de Veerstraat 9
                     </Flex>
                   </VersionTitle>
             </FootPanel>
             
             </StylePanel>
        </>
      
        :
        <StylePanel isDesktop={isDesktop}  display="flex" alignItems="center"  justifyContent={!isDesktop?"center":"space-evenly"} >
        
        <FootPanel
                ml={isDesktop?0:4}
                flexDirection={isDesktop?"row":"column"} 
                alignItems={isDesktop?"center":"left"}>
            <img src="./image/bouttumLog.png" alt="" width="108px"  height="36px"/>
            <VersionTitle 
              pl="25px"
              component={'span'} 
              fontFamily="SourceHanSansCN, SourceHanSansCN-Light"
              color="#bcbcbc" >
                <Flex component={"span"}>
                {t("Allcopyright")} &#169 2016-2022 Smein Hosting N.V. Abraham de Veerstraat 9
                </Flex>
              </VersionTitle>
        </FootPanel>
        
        {
          isDesktop?
          <>
                     <Flex>
            <Flex flexDirection="column">
              <img src="./image/home/bchSwarm.png" alt="" width="188px"  height="36px"/>
              <Flex fontSize="12px" color="#bcbcbc" mt={"28px"}>
                <Flex mr={2}>
                  <img src="./image/home/18+.png" alt=""  width="20px"  height="20px"/>

                </Flex>
                {t("Warning: Gambling can be addictive")}
              </Flex>
            </Flex>
            <Flex ml={'14px'} mt={"-13px"}>
             <img src="./image/home/bchHome.png" alt="" width="140px"  height="56px"/>
            </Flex>
         </Flex>

         <Flex flexDirection="column">
            <Flex >
              <img src="./image/home/smartbch.png" alt="" width="128px"  height="34px"/>
              <Flex ml={'21px'}  pt={'5px'}>
               <img src="./image/home/greenlog.png" alt="" width="155px"  height="17px"/>
              </Flex>
            </Flex>
            <Flex mt={'22px'} justifyContent="space-evenly">
            
             {/* <img src="./image/home/wx.png" alt="" width="36px"  height="36px"/> */}
             <a href="https://discord.gg/Y6JhcDVdeg">
               <img src="./image/home/dis.png" alt="" width="36px"  height="36px"/>
             </a>
             <a href="https://twitter.com/bch_sun?t=fJv3tHeJfQ7e2MDJOFC1nw&s=09">
              <img src="./image/home/tuite.png" alt="" width="36px"  height="36px"/>
             </a>
             <a href="https://t.me/bchchinese">
               <img src="./image/home/tg.png" alt="" width="36px"  height="36px"/>
             </a>
            

            </Flex>
         </Flex>
          </>
          :""
        }

        </StylePanel>
      }
     
       
   
    </>
  
    
  );
};

export default Footer;
