import React,  { useState, useRef, useEffect, useCallback } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Box } from '@material-ui/core'

const Flex=styled(Box)`
     display: flex;
`
const DropDownHeader = styled.div`
  /* width: 100%; */
  height: 100%;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  /* padding: 16px; */
  @media (max-width: 750px) {
       padding: 0.08rem;
  }
  @media (min-width:750px){
        padding: 16px;
  }
  
  box-shadow: #dfd6d3;
  border: 1px solid #dfd6d3;
  /* border-radius: 10px; */
  background: #fff;
  transition: border-radius 0.15s;
`

const DropDownListContainer = styled.div`
  min-width: 136px;
  height: 0;
  position: absolute;
  overflow: hidden;
  background: #f3f3f3;
  z-index: 10000;
  transition: transform 0.15s, opacity 0.15s;
  transform: scaleY(0);
  transform-origin: top;
  opacity: 0;
  width: 100%;
  

  box-shadow: 1px 10px 10px -12px;
`
const Boxs= styled.div`
`
const DropDownContainer = styled(Boxs)<{ isOpen: boolean }>`
  cursor: pointer;
  width: 100%;
  position: relative;
  background: #dfd6d3;
  /* border-radius: 16px; */
  height: 100%;
  min-width: 136px;
  user-select: none;
  // z-index: 20;



  ${(props) =>
    props.isOpen &&
    css`
      ${DropDownHeader} {
        border-bottom: 1px solid #dfd6d3;
        box-shadow: #dfd6d3;
        /* border-radius: 16px 16px 0 0; */
      }

      ${DropDownListContainer} {
        height: auto;
        transform: scaleY(1);
        opacity: 1;
        border-radius: 0 0 16px 16px;
        box-shadow: #dfd6d3;
        border-top:1px solid #dfd6d3;
        /* border-style: dashed; */
      }
    `}

  svg {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
`

const DropDownList = styled.ul`
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  z-index: 100;
`

const ListItem = styled.li`
  display:flex;
  align-items: center;
  list-style: none;
  padding: 8px 16px;
  &:hover {
    background: '#dfd6d3';
  }
`
const Image = styled.img`
    width: 24px;
    height: 24px;
    display: inline-block;
    margin-right: 15px;
    vertical-align: middle;
`

export interface SelectProps {
  options: OptionProps[]
  onOptionChange?: (option: OptionProps) => void
  placeHolderText?: string
  selectIndex?: number
}

export interface OptionProps{
  img?:{
    url:string,
    name:string
  }
  label: string
  value: any
}

const Select: React.FunctionComponent<SelectProps> = ({
  options,
  onOptionChange,
  selectIndex,
  placeHolderText,
  ...props
}) => {
  const { t,i18n } = useTranslation();
  const dropdownRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [optionSelected, setOptionSelected] = useState(false)
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(selectIndex||0)

  
  const toggling = (event: React.MouseEvent<HTMLDivElement>) => {
    setIsOpen(!isOpen)
    event.stopPropagation()
  }

  const onOptionClicked = (selectedIndex: number) => () => {
    setSelectedOptionIndex(selectedIndex)
    setIsOpen(false)
    setOptionSelected(true)

    if (onOptionChange) {
      onOptionChange(options[selectedIndex])
    }
  }

  useEffect(() => {
    const handleClickOutside = () => {
      setIsOpen(false)
    }

    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])


  useEffect(()=>{
    if(selectIndex!==undefined && selectIndex!==selectedOptionIndex){
      setSelectedOptionIndex(selectIndex)
      setIsOpen(false)
      setOptionSelected(true)
      // console.log('options',options)

      if (onOptionChange) {
          onOptionChange(options[selectIndex])
      }
    }

  },
  // eslint-disable-next-line
  [selectIndex])

  return (<>{options.length>0&&options[0].label?
    <DropDownContainer isOpen={isOpen} {...props}>
      <DropDownHeader onClick={toggling}>
        {options[selectedOptionIndex] && options[selectedOptionIndex].img?<Image src={options[selectedOptionIndex]?.img?.url} alt={options[selectedOptionIndex]?.img?.name} />:''}
        <Flex component="span" color={!optionSelected && placeHolderText ? 'text' : undefined}>
          {!optionSelected && placeHolderText ? placeHolderText :(options[selectedOptionIndex] && options[selectedOptionIndex].label)}
        </Flex>
      </DropDownHeader>
      {/* <ArrowDropDownIcon color="text" onClick={toggling} /> */}
      <DropDownListContainer>
        <DropDownList ref={dropdownRef}>
          {options.map((option, index) =>
            placeHolderText || index !== selectedOptionIndex ? (
              <ListItem style={{textAlign:'left'}} onClick={onOptionClicked(index)} key={option.label}>
                {option.img?<Image src={option.img.url} alt={option.img.name} />:''}
                <Flex component="span">{option.label}</Flex>
              </ListItem>
            ) : null,
          )}
        </DropDownList>
      </DropDownListContainer>
    </DropDownContainer>:''}
    </>
  )
}

export default Select
