import { Box, Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import useMatchBreakpoints from "../../../hooks/useMatchBreakpoints";
import { ethers } from "ethers";
import { useWallet } from "@binance-chain/bsc-use-wallet";
import { getBchAddress, getBeeTokenAddress, getfactorytAddress, getimptAddress } from "../../../utils/addressHelpers";
import { AbiItem } from 'web3-utils';
import useWeb3 from "../../../hooks/useWeb3";
import BeeTokentAbi from '../../../config/abi/BeeToken.json';

const Center = styled(Box)<any>`
  background-image: url(/image/新建项目.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
  border-radius: 10px;
`;

const StylePanel=styled(Box)`
     /* height: 80px; */
     /* background-color: #fafafa;
     background-image: url('/image/myFuzzle/myback.png'); */
     background-size:100% 100%;
     padding:10px 10px 10px 40px;
`

const STyleLeftBox=styled(Box)`
    width:486px;
    height:486px;
    background-color:gray;
    border:5px solid #fff;
`
const STyleRigthBox=styled(Box)`
    background: #052800;
    border: 1px solid #00ff43;
    margin-left:75px;
`

const Liquidity: React.FC = () => {
  const { isDesktop, isMobile } = useMatchBreakpoints();
  const { t } = useTranslation();
  const [dellist,setDelList]=useState<any>([]);
  const [list,setList]=useState([]);
  const [task,setTask]=useState("");
  const [exists,setExists]=useState(true);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTask((event.target as HTMLInputElement).value);
  };

        const SEP20ABI = [
            "function symbol() view returns (string)",
            "function decimals() view returns (uint8)",
            "function balanceOf(address account) external view returns (uint256)",
            "function allowance(address owner, address spender) external view returns (uint256)",
            "function approve(address spender, uint256 amount) external returns (bool)",
        ]

        const ImplAddr = getimptAddress();
        const FactoryAddr = getfactorytAddress()

        const CCABI = [
        "function getAllRobots() view public returns (uint[] memory robotsIdAndInfo)",
        "function createRobot(uint robotInfo) external payable",
        "function deleteRobot(uint index, uint robotId) external",
        "function sellToRobot(uint robotId, uint stockDelta) external payable",
        "function buyFromRobot(uint robotId, uint moneyDelta) external payable",
        ]

        const FactoryABI = [
        "function create(address stock, address money, address impl) external",
        "function getAddress(address stock, address money, address impl) public view returns (address)",
        ]

      

        function packPrice(price:ethers.BigNumber) {
            var effBits = 1
            while(!price.mask(effBits).eq(price)) {
                effBits += 1
            }
            var twoPow24 = ethers.BigNumber.from(2).pow(24)
            if(effBits <= 25) {
                return price
            }
            var shift = effBits-25
            var shiftBN = ethers.BigNumber.from(2).pow(shift)
            var low24 = price.div(shiftBN).sub(twoPow24)
            var high8 = ethers.BigNumber.from(shift).add(1).mul(twoPow24)
            return high8.add(low24)
        }

        function unpackPrice(packed:any) {
            var twoPow24 = ethers.BigNumber.from(2).pow(24)
            var low24 = packed.mod(twoPow24)
            var shift = packed.div(twoPow24)
            if(shift.isZero()) {
                return low24
            }
            var shiftBN = ethers.BigNumber.from(2).pow(shift.sub(1))
            return low24.add(twoPow24).mul(shiftBN)
        }


        // async function connect() {
        //     if (typeof (window as any).ethereum === 'undefined') {
        //         if (typeof (window as any).web3 !== 'undefined') {
        //             (window as any).ethereum = (window as any).web3;
        //         } else if (typeof (window as any).TPJSBrigeClient !== 'undefined') {
        //             (window as any).ethereum = (window as any).TPJSBrigeClient;
        //         } else if (typeof (window as any).imToken !== 'undefined') {
        //             (window as any).ethereum = (window as any).imToken;
        //         } else {
        //             const provider = await detectEthereumProvider();
        //             if (provider) {
        //                 (window as any).ethereum = provider;
        //             } else if(IsPC()) {
        //                 alert("Your browser has not installed a wallet extension (like MetaMask).");
        //             } else {
        //                 alert("Please open this page inside a mobile wallet App.");
        //             }
        //         }
        //     }
        //     window.accounts = await window.ethereum.request({method: 'eth_requestAccounts'});
        //     if (window.accounts.length == 0) {
        //             window.AlertDlg = new Attention.Alert({title: "Warning",
        //               content: "Cannot connect to wallet"});
        //         return false;
        //     }
        //     return true;
        // }

        const init=async()=>{
            // if(!await connect()) {return;}
            const provider = new ethers.providers.Web3Provider((window as any).ethereum);
            const signer = provider.getSigner();
            const myAddr = await signer.getAddress();
            (document.getElementById("signerAddress") as any).innerText = myAddr;

            const url = new URL((window as any).location.href);
            try {
                (window as any).moneyAddr = getBchAddress();
                (window as any).moneyContract = new ethers.Contract((window as any).moneyAddr, SEP20ABI, provider).connect(signer);
                (window as any).moneySymbol = await (window as any).moneyContract.symbol();
                (window as any).moneyDecimals = await (window as any).moneyContract.decimals();
                var moneyURL = `<a target="_blank" href="https://smartscan.cash/address/${(window as any).moneyAddr}">${(window as any).moneySymbol}</a>`;
                (document.getElementById("moneyURL") as any).innerHTML = moneyURL

                const moneyAmt = await (window as any).moneyContract.balanceOf(myAddr);
                (document.getElementById("moneyBal") as any).innerHTML = ethers.utils.formatUnits(moneyAmt, (window as any).moneyDecimals);
            } catch(e) {
                    // window.AlertDlg = new Attention.Alert({title: "Incorrect Address",
                    // content: "Incorrect Address for Money: "+moneyAddr});
                    alert("Incorrect Address for Money");
                return
            }

            try {
                //BEE 地址
                (window as any).stockAddr = getBeeTokenAddress();
                (window as any).stockContract = new ethers.Contract((window as any).stockAddr, SEP20ABI, provider).connect(signer);
                (window as any).stockSymbol = await (window as any).stockContract.symbol();
                (window as any).stockDecimals = await (window as any).stockContract.decimals();
                var stockURL = `<a target="_blank" href="https://smartscan.cash/address/${(window as any).stockAddr}">${(window as any).stockSymbol}</a>`;
                (document.getElementById("stockURL") as any).innerHTML = stockURL

                const stockAmt = await (window as any).stockContract.balanceOf(myAddr);
                (document.getElementById("stockBal") as any).innerHTML = ethers.utils.formatUnits(stockAmt,(window as any).stockDecimals);
            } catch(e) {
                    // window.AlertDlg = new Attention.Alert({title: "Incorrect Address",
                    // content: "Incorrect Address for Stock: "+stockAddr});
                    alert("Incorrect Address for Stock")
                return
            }

            var factoryContract = new ethers.Contract(FactoryAddr, FactoryABI, provider).connect(signer);
            (window as any).MarketAddress = await factoryContract.getAddress((window as any).stockAddr, (window as any).moneyAddr, ImplAddr);
            // console.log('MarketAddress:', window.MarketAddress);
            var code = await provider.getCode( (window as any).MarketAddress);
            if(code == "0x") {
                // new Attention.Confirm({title: "Market Not Exist",
                //     content: `The market ${stockSymbol}/${moneySymbol} has not been created, do you want to create it?`,
                //     onConfirm(component) {
                //         factoryContract.create(stockAddr, moneyAddr, ImplAddr)
                //     },
                //     onCancel(component) {}});
                setExists(false);
                return;
            }

            (document.getElementById("marketAddress") as any).innerText = (window as any).MarketAddress;


            // 初始化授权
            const allowanceBN = await (window as any).stockContract.allowance(myAddr, (window as any).MarketAddress);
            const allowance = ethers.utils.formatUnits(allowanceBN, 18)as any*(1.0 as any);
            if(allowance>0){ setIsApprove_1(true);}



            // const task = url.searchParams.get("task");
            if(task == "create") {
                (document.getElementById("createDiv")as any).style.display = "block"
                document.title = "Create a robot"
            } else if(task == "delete") {
                (document.getElementById("deleteDiv")as any).style.display = "block"
                document.title = "Delete a robot"
                listMyRobots()
            } else if(task == "buy") {
                (document.getElementById("buyDiv")as any).style.display = "block"
                document.title = `Buy ${(window as any).stockSymbol} from a robot`
                listRobotsForBuying()
            } else if(task == "sell") {
                (document.getElementById("sellDiv")as any).style.display = "block"
                document.title = `Sell ${(window as any).stockSymbol} to a robot`
                listRobotsForSelling()
            } else {
                    // window.AlertDlg = new Attention.Alert({title: "Incorrect Task",
                    // content: "Incorrect Task: "+task});
                    alert("Incorrect Task");
                return
            } 
        }
      
        const [isApprove_1,setIsApprove_1]=useState(false);
        const [isApprove_2,setIsApprove_2]=useState(true);
        async function checkAllowanceAndBalance(contract:any, symbol:any, myAddr:any, amount:any, decimals:any) {
            const allowanceBN = await contract.allowance(myAddr, (window as any).MarketAddress)
            const allowance = ethers.utils.formatUnits(allowanceBN, decimals)as any*(1.0 as any)
            // console.log(symbol, ', allowance:', allowance);
            if(allowance < amount) {
                setIsApprove_1(false);
                return
            }
            const balanceBN = await contract.balanceOf(myAddr)
            const balance:any = ethers.utils.formatUnits(balanceBN, decimals) as any*1.0
            if(balance < amount) {
                // window.AlertDlg = new Attention.Alert({title: "Not Enough ${symbol}",
                //     content: `${amount} ${symbol} is needed, but you only have ${balance}`});
                alert(`${amount} ${symbol} is needed, but you only have ${balance}`)    
            }
        }
        async function checkAllowanceAndBalance2(contract:any, symbol:any, myAddr:any, amount:any, decimals:any) {
            const allowanceBN = await contract.allowance(myAddr, (window as any).MarketAddress)
            const allowance = ethers.utils.formatUnits(allowanceBN, decimals)as any*(1.0 as any)
            // console.log(symbol, ', allowance:', allowance);
            if(allowance < amount) {
                setIsApprove_2(false);
                return
            }
            const balanceBN = await contract.balanceOf(myAddr)
            const balance:any = ethers.utils.formatUnits(balanceBN, decimals) as any*1.0
            if(balance < amount) {
                // window.AlertDlg = new Attention.Alert({title: "Not Enough ${symbol}",
                //     content: `${amount} ${symbol} is needed, but you only have ${balance}`});
                alert(`${amount} ${symbol} is needed, but you only have ${balance}`)    
            }
        }

        const web3 = useWeb3();
        const approve1Fnc=async ()=>{
               const MaxAmount = ethers.utils.parseUnits('999999999');
               console.log((window as any).MarketAddress);
               try {

                 const stockAddrContract=new web3.eth.Contract((BeeTokentAbi as unknown) as AbiItem,(window as any).stockAddr);
                 const tx = await stockAddrContract.methods.approve((window as any).MarketAddress, ethers.constants.MaxUint256).send({ from: account })
                
                //  await (window as any).stockContract.approve((window as any).MarketAddress, ethers.constants.MaxUint256);
                 setIsApprove_1(true)
               } catch (error) {
                 console.log(error)
                 alert("失败")
               }
               
        };
       

        const {account} = useWallet();
        const CreateRobot=async()=>{
       
            const myAddr = account;

            var stockAmount = (document.getElementById("stockAmount") as any).value //* 1.0
            var moneyAmount = (document.getElementById("moneyAmount") as any).value //* 1.0

            checkAllowanceAndBalance((window as any).stockContract, (window as any).stockSymbol, myAddr, stockAmount, (window as any).stockDecimals)
            checkAllowanceAndBalance2((window as any).moneyContract, (window as any).moneySymbol, myAddr, moneyAmount, (window as any).moneyDecimals)

            var stockAmountBN = ethers.utils.parseUnits(stockAmount, (window as any).stockDecimals)
            var moneyAmountBN = ethers.utils.parseUnits(moneyAmount, (window as any).moneyDecimals)
            // console.log(ethers.utils.parseUnits("1.0"))
            var highPrice = packPrice(ethers.utils.parseUnits((document.getElementById("highPrice") as any).value))
            var lowPrice = packPrice(ethers.utils.parseUnits((document.getElementById("lowPrice") as any).value))
            var robotInfo = stockAmountBN.mul(ethers.BigNumber.from(2).pow(96)).add(moneyAmountBN)
            robotInfo = robotInfo.mul(ethers.BigNumber.from(2).pow(32)).add(highPrice)
            robotInfo = robotInfo.mul(ethers.BigNumber.from(2).pow(32)).add(lowPrice)

            const provider = new ethers.providers.Web3Provider((window as any).ethereum);
            const signer = provider.getSigner();
            const marketContract = new ethers.Contract((window as any).MarketAddress, CCABI, provider).connect(signer);

            let val = null;
            if ((window as any).stockAddr == '0x0000000000000000000000000000000000002711') {
                val = {value: stockAmountBN};
            } else if ((window as any).moneyAddr == '0x0000000000000000000000000000000000002711') {
                val = {value: moneyAmountBN};
            }
            console.log('val:', val);

            await marketContract.createRobot(robotInfo, val)
            alert("创建成功");
        }

        async function getAllRobots(onlyForAddr:any) {
            const provider = new ethers.providers.Web3Provider((window as any).ethereum)
            const marketContract = new ethers.Contract((window as any).MarketAddress, CCABI, provider)
            var allRobotsArr = await marketContract.getAllRobots()
            // console.log('allRobotsArr:', allRobotsArr);
            var allRobots = []
            var twoPow32 = ethers.BigNumber.from(2).pow(32);
            var twoPow96 = ethers.BigNumber.from(2).pow(96);
            (window as any).RobotsMap = {}
            for(var i=0; i<allRobotsArr.length; i+=2) {
                var fullId = allRobotsArr[i]
                // console.log('i:', i, 'fullId:', fullId);
                var robot:any = {fullId: fullId.toHexString(), index: i/2}
                robot.shortId = fullId.mod(twoPow96).toNumber()
                robot.ownerAddr = ethers.utils.getAddress(fullId.div(twoPow96).toHexString())
                if(onlyForAddr && onlyForAddr != robot.ownerAddr) {continue}
                var info = allRobotsArr[i+1]
                robot.lowPrice = ethers.utils.formatUnits(unpackPrice(info.mod(twoPow32))) as any *1.0
                info = info.div(twoPow32)
                robot.highPrice = ethers.utils.formatUnits(unpackPrice(info.mod(twoPow32))) as any*1.0
                info = info.div(twoPow32)
                robot.moneyAmountBN = info.mod(twoPow96)
                robot.stockAmountBN = info.div(twoPow96)
                robot.moneyAmount = ethers.utils.formatUnits(robot.moneyAmountBN, (window as any).moneyDecimals) as any*1.0;
                robot.stockAmount = ethers.utils.formatUnits(robot.stockAmountBN, (window as any).stockDecimals) as any*1.0;
                allRobots.push(robot);
                (window as any).RobotsMap[robot.fullId] = robot
            }
            return allRobots
        }

        async function listMyRobots() {
            const provider = new ethers.providers.Web3Provider((window as any).ethereum);
            const signer = provider.getSigner();
            const myAddr = await signer.getAddress();
            
            var robots = await getAllRobots(myAddr);
            if(robots.length == 0) {
                (document.getElementById("deleteDiv")as any).innerHTML = "<p>没有流动性存在</p>"
                return
            }
            var htmlStr = "<p>"
            setDelList(robots);
            // for(var i=0; i<robots.length; i++) {
            //     htmlStr += `<b>Robot#${robots[i].shortId+1}</b>&nbsp;`
            //     htmlStr += `<button onclick="DeleteRobot(${robots[i].index}, '${robots[i].fullId}')">Delete</button>&nbsp;`
            //     htmlStr += `Stock: ${robots[i].stockAmount}&nbsp;`
            //     htmlStr += `Money: ${robots[i].moneyAmount}&nbsp;`
            //     htmlStr += `HighPrice: ${robots[i].highPrice}&nbsp;`
            //     htmlStr += `LowPrice: ${robots[i].lowPrice}</p>`
            // }
            // (document.getElementById("deleteDiv")as any).innerHTML = htmlStr
        }

        async function listRobotsForBuying() {
            var robots = await getAllRobots("");
            if(robots.length == 0) {
                (document.getElementById("buyDiv") as any).innerHTML += "<p>Found no robots to buy stock from.</p>"
                return
            }
            robots.sort(function(a, b) {
                return a.highPrice - b.highPrice;
            });
            var htmlStr = "<p>"
            for(var i=0; i<robots.length; i++) {
                htmlStr += `<b>Robot#${robots[i].shortId+1}</b>&nbsp;`
                htmlStr += `<button onclick="Buy('${robots[i].fullId}')">Buy From It</button>&nbsp;`
                htmlStr += `Stock: ${robots[i].stockAmount}&nbsp;`
                htmlStr += `Price: ${robots[i].highPrice}</p>`
            }
            (document.getElementById("buyDiv") as any).innerHTML += htmlStr
        }

        const Sell=async (robotId:any)=>{
            const provider = new ethers.providers.Web3Provider((window as any).ethereum);
            const signer = provider.getSigner();
            const myAddr = await signer.getAddress();

            var stockDelta = (document.getElementById("stockDelta") as any).value//*1.0
            var stockDeltaBN = ethers.utils.parseUnits(stockDelta, (window as any).stockDecimals)
            var robot = (window as any).RobotsMap[robotId]
            var moneyDelta = stockDelta * robot.lowPrice
            if(moneyDelta > robot.moneyAmount) {
                    // (window as any).AlertDlg = new Attention.Alert({title: "Robot has not enough money",
                    //     content: `The robot has only ${robot.moneyAmount} ${(window as any).moneySymbol}, but must pay you ${moneyDelta} ${(window as any).moneySymbol}`});
                alert("The robot has only erro");
                return false;
            }
            checkAllowanceAndBalance((window as any).stockContract, (window as any).stockSymbol, myAddr, stockDelta, (window as any).stockDecimals)

            let val = null;
            if ((window as any).stockAddr == '0x0000000000000000000000000000000000002711') {
                val = {value: stockDeltaBN};
            }
            console.log('val:', val);

            const marketContract = new ethers.Contract((window as any).MarketAddress, CCABI, provider).connect(signer);
            await marketContract.sellToRobot(robotId, stockDeltaBN, val)
            
        }

        async function DeleteRobot(idx:any, id:any) {
            const provider = new ethers.providers.Web3Provider((window as any).ethereum);
            const signer = provider.getSigner();
            const marketContract = new ethers.Contract((window as any).MarketAddress, CCABI, provider).connect(signer);

            const myAddr = await signer.getAddress();
            var robots = await getAllRobots(myAddr);
            // console.log('robots:', robots);
            // console.log('DeleteRobot, idx:', idx, 'id:', id);
            await marketContract.deleteRobot(idx, id);
            alert("删除成功");
        }

        async function listRobotsForSelling() {
            var robots:any = await getAllRobots("");
            if(robots.length == 0) {
                (document.getElementById("sellDiv") as any).innerHTML += "<p>Found no robots to sell stock to.</p>"
                return
            }
            robots.sort(function(a:any, b:any) {
                return b.lowPrice - a.lowPrice;
            });
            var htmlStr = "<p>"
            setList(robots);
            // for(var i=0; i<robots.length; i++) {
            //     htmlStr += `<b>Robot#${robots[i].shortId+1}</b>&nbsp;`
            //     htmlStr += `<button onClick="()=>{Sell('${robots[i].fullId}')}">卖给他</button>&nbsp;`
            //     htmlStr += `金钱: ${robots[i].moneyAmount}&nbsp;`
            //     htmlStr += `价格: ${robots[i].lowPrice}</p>`
            // }
            // (document.getElementById("sellDiv") as any).innerHTML += htmlStr
        }

        // async function Buy(robotId:any) {
        //     const provider = new ethers.providers.Web3Provider(window.ethereum);
        //     const signer = provider.getSigner();
        //     const myAddr = await signer.getAddress();

        //     var moneyDelta = document.getElementById("moneyDelta").value //*1.0
        //     var moneyDeltaBN = ethers.utils.parseUnits(moneyDelta, window.moneyDecimals)
        //     var robot = window.RobotsMap[robotId]

        //     var stockDelta = moneyDelta / robot.highPrice
        //     if(stockDelta > robot.stockAmount) {
        //             window.AlertDlg = new Attention.Alert({title: "Robot has not enough stock",
        //                 content: `The robot has only ${robot.stockAmount} ${window.stockSymbol}, but you want ${stockDelta} ${window.stockSymbol}`});
        //         return false;
        //     }
        //     checkAllowanceAndBalance(window.moneyContract, window.moneySymbol, myAddr, moneyDelta, window.moneyDecimals)

        //     let val = null;
        //     if (window.moneyAddr == '0x0000000000000000000000000000000000002711') {
        //         val = {value: moneyDeltaBN};
        //     }
        //     console.log('val:', val);

        //     const marketContract = new ethers.Contract(window.MarketAddress, CCABI, provider).connect(signer);
        //     await marketContract.buyFromRobot(robotId, moneyDeltaBN, val)
        // }

       

        const handjiaoyiCreate=async()=>{

            const provider = new ethers.providers.Web3Provider((window as any).ethereum);
            const signer = provider.getSigner();
            var factoryContract = new ethers.Contract(FactoryAddr, FactoryABI, provider).connect(signer);
            await factoryContract.create((window as any).stockAddr, (window as any).moneyAddr, ImplAddr);
            alert("交易对创建成功");
            (window as any).MarketAddress = await factoryContract.getAddress((window as any).stockAddr, (window as any).moneyAddr, ImplAddr);
            (document.getElementById("marketAddress") as any).innerText = (window as any).MarketAddress;
         



        setExists(true);
        if(task == "create") {
            (document.getElementById("createDiv")as any).style.display = "block"
            document.title = "Create a robot"
        } else if(task == "delete") {
            (document.getElementById("deleteDiv")as any).style.display = "block"
            document.title = "Delete a robot"
            listMyRobots()
        } else if(task == "buy") {
            (document.getElementById("buyDiv")as any).style.display = "block"
            document.title = `Buy ${(window as any).stockSymbol} from a robot`
            listRobotsForBuying()
        } else if(task == "sell") {
            (document.getElementById("sellDiv")as any).style.display = "block"
            document.title = `Sell ${(window as any).stockSymbol} to a robot`
            listRobotsForSelling()
        } else {
                // window.AlertDlg = new Attention.Alert({title: "Incorrect Task",
                // content: "Incorrect Task: "+task});
                alert("Incorrect Task");
            return
        } 
        }


  return (
    <StylePanel  display="flex" flex={1}  flexDirection="column">
       	{/* <p>This DApp can help you make trading robots. You create a trading robot by depositing stock tokens and money tokens. It will try to sell the stock at a high price and buy the stock at a low price, helping you earn more tokens. You can delete the trading robot anytime and get back your tokens.</p> */}
        <Box>
            <Box display="flex" flexDirection="column">
                {/* <p>What is the stock token?<br/>
                    <input className="input is-info is-small" type="text" name="stock"
                    placeholder="Please enter an SEP20 token's address"/></p>

                    <p>What is the money token?<br/>
                    <input className="input is-info is-small" type="text" name="money"
                    placeholder="Please enter an SEP20 token's address"/></p>

                    <p>What do you want to do?</p> */}
                    {/* <label className="radio"><input type="radio" name="task" value="create" checked/>&nbsp;Create a robot</label><br/>
                    <label className="radio"><input type="radio" name="task" value="delete"/>&nbsp;Delete my robots</label><br/>
                    <label className="radio"><input type="radio" name="task" value="buy"/>&nbsp;Buy from robots created by others</label><br/>
                    <label className="radio"><input type="radio" name="task" value="sell"/>&nbsp;Sell to robots created by others</label><br/>
                    */}
                    <Box display={'flex'}>
                        <FormControl component="fieldset">
                        <FormLabel component="legend">选择</FormLabel>
                        <RadioGroup row aria-label="gender" name="gender1" value={task} onChange={handleChange}>
                            <FormControlLabel value="create" control={<Radio />} label="创建流动性" />
                            <FormControlLabel value="delete" control={<Radio />} label="删除流动性" />
                            <FormControlLabel value="sell"  control={<Radio />} label="卖给流动性" />
                            <FormControlLabel value="buy" control={<Radio />} label="买入BEE" />
                        </RadioGroup>
                        </FormControl>
                    </Box>
              
                    <Box display={'flex'} width="200px">
                      <Button variant="contained" onClick={init} color="secondary">
                        开始
                      </Button>
                      {/* <input type="submit" width={'200px'} value="GO!" onClick={init}/> */}
                    </Box>
                  

            </Box>
                    
            <Box mt={3}>
                
                    <p>你的地址:<br/><u id="signerAddress"></u></p>
                    <p>市场地址:<br/><u id="marketAddress"></u></p>
                    <p>现货地址:&nbsp;&nbsp;<u id="stockURL"></u> ( You have: <span id="stockBal"></span> )</p>
                    <p>金钱地址:&nbsp;&nbsp;<u id="moneyURL"></u> ( You have: <span id="moneyBal"></span> )</p>
                     
                     {!exists?<Button variant="contained" onClick={handjiaoyiCreate} color="secondary">
                        创建交易对
                      </Button>:""
                     }
                     


                    <div id="createDiv" style={{"display": "none"}}>
                        <p>将存入多少库存?<br/>
                        <input className="input is-info is-small" value={0} type="number" step="any" id="stockAmount"/></p>

                        <p>以高价出售股票:<br/>
                        <input className="input is-info is-small" value={99999999} type="number" step="any" id="highPrice"/></p>


                        <p>要存多少钱?<br/>
                        <input className="input is-info is-small" type="number" step="any" id="moneyAmount"/></p>

                        <p>低价买入股票(1BEE价值多少BCH,填入BCH数量):<br/>
                        <input className="input is-info is-small" type="number" step="any" id="lowPrice"/></p>

                           
                        <br/>
                     
                         {
                            !isApprove_1?
                              <Button variant="contained" onClick={approve1Fnc} color="secondary">
                               授权BEE给交易对合约
                              </Button>
                             :
                             <Button variant="contained" onClick={CreateRobot} color="secondary">创建流动性</Button>


                         }

                    </div>

                    <div id="deleteDiv" style={{"display": "none"}}>

                      {dellist.map((robots:any)=>(
                            <>
                             <Box>Robot#{robots.shortId+1}</Box>
                             <Box>Stock: {robots.stockAmount}</Box>
                             <Box>Money: {robots.moneyAmount}</Box>
                             <Box>HighPrice: {robots.highPrice}</Box>
                             <Box>LowPrice: {robots.lowPrice}</Box>
                             <Button variant="contained" color="secondary" onClick={()=>{DeleteRobot(robots.index,`${robots.fullId}`)}}>Delete</Button>

                            </>
                        ))}
                    </div>

                    <div id="buyDiv" style={{"display": "none"}}>
                        <p>你想花多少钱?<br/>
                        <input className="input is-info is-small" type="number" step="any" id="moneyDelta"/></p><br/>
                    </div>

                    <div id="sellDiv" style={{"display": "none"}}>
                        <p>你想卖多少股票?<br/>
                        <input className="input is-info is-small" type="number" step="any" id="stockDelta"/></p><br/>
                        {/* // for(var i=0; i<robots.length; i++) {
            //     htmlStr += `<b>Robot#${robots[i].shortId+1}</b>&nbsp;`
            //     htmlStr += `<button onClick="()=>{Sell('${robots[i].fullId}')}">卖给他</button>&nbsp;`
            //     htmlStr += `金钱: ${robots[i].moneyAmount}&nbsp;`
            //     htmlStr += `价格: ${robots[i].lowPrice}</p>`
            // } */}
                        {list.map((robots:any)=>(
                            <>
                             <Box>Robot#{robots.shortId+1}</Box>
                             <Box>金钱: {robots.moneyAmount}</Box>
                             <Box>价格: {robots.lowPrice}</Box>
                             <Button variant="contained" color="secondary" onClick={()=>{Sell(robots.fullId)}}>卖给他</Button>

                            </>
                        ))}
                    </div>


            </Box> 
        </Box>
           
    </StylePanel>
  );
};

export default Liquidity;
