import { useWallet } from '@binance-chain/bsc-use-wallet';
import { BottomNavigation, BottomNavigationAction, Box, Button, CircularProgress, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import CreateBox from './CreateBox';
import GetBack from './GetBack';
import SearchPage from './SearchPage';
const FlexPanel = styled(Box)`
  display:flex;
`
const Container = styled(Box)`
  background: black;
  padding: 20px;
  color: #fff;
`;




interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`wrapped-tabpanel-${index}`}
        aria-labelledby={`wrapped-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3} display='flex'>
            {children}
          </Box>
        )}
      </div>
    );
  }

const DrawMangement: React.FC =()=>{
    const [value,setValue] = useState(0);
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
          setValue(newValue);
    };

    return <Box display="flex" flex={1} flexDirection="column">
        <Paper square>
              <Tabs
                value={value}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChange}
                aria-label="disabled tabs example"
               >
                <Tab label="创建盲盒/Create a blind box"/>
                <Tab label="查询盲盒地址/Query  box address"/>
                <Tab label="取回押金/Get back the deposit"/>
                
              </Tabs>
        </Paper>
      
            <TabPanel value={value} index={0}>
               {value==0?<CreateBox></CreateBox>:""}
            </TabPanel>
            <TabPanel value={value} index={1}>
               {value==1?<SearchPage></SearchPage>:""}
            </TabPanel>
            <TabPanel value={value} index={2}>
               {value==2?<GetBack></GetBack>:""}
            </TabPanel>
            
          </Box>

}

export default DrawMangement;